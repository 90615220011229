<template>
  <div
    class="modal fade"
    id="LeadFilterModal"
    tabindex="-1"
    aria-labelledby="LeadFilterModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-custom-position">
      <div class="modal-content">
        <div class="modal-header" style="padding: 5px; margin: 5px">
          <h5 class="modal-title" id="LeadFilterModalLabel">Filter</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body d-flex">
          <!-- Left Side: Buttons for different filters -->
          <div class="filter-buttons">
            <button
              v-for="(filter, index) in filterOptions"
              :key="index"
              @click="setActiveFilter(filter)"
              :class="['filter-btn', { active: activeFilter === filter }]"
              class="d-inline-flex align-items-center justify-content-between"
            >
              <!-- Display filter name -->
              <span>
                {{ filter }}
              </span>
              <!-- Dot icon for selected filters -->
              <span
                v-if="getFilterCount(filter) > 0"
                :style="{
                  width: '10px',
                  height: '10px',
                  backgroundColor: getFilterColor(filter),
                  borderRadius: '50%',
                  display: 'inline-block',
                  marginLeft: '10px',
                }"
              ></span>
            </button>
          </div>

          <!-- Right Side: Filter Options based on active label -->
          <div class="filter-options flex-grow-1">
            <form class="custom-checkbox">
              <!-- Source Filter -->
              <div v-if="activeFilter === 'Source'" class="mb-3">
                <div v-for="(src, index) in source" :key="index">
                  <input
                    type="checkbox"
                    :value="src.id"
                    v-model="selectedSource"
                    id="checkbox1"
                  />
                  <label>{{ src.name }}</label>
                </div>
              </div>

              <!-- Status Filter -->
              <div v-if="activeFilter === 'Status'" class="mb-3">
                <div v-for="(leadstatusItem, index) in leadstatus" :key="index">
                  <input
                    type="checkbox"
                    :value="leadstatusItem"
                    v-model="selectedStatus"
                  />
                  <label>{{ leadstatusItem }}</label>
                </div>
              </div>
              <div v-if="activeFilter === 'E-Status'" class="mb-3">
                <div v-for="(EstimatestatusItem, index) in Estimatestatus" :key="index">
                  <input
                    type="checkbox"
                    :value="EstimatestatusItem"
                    v-model="selectedStatus"
                  />
                  <label>{{ EstimatestatusItem }}</label>
                </div>
              </div>

              <!-- Priority Filter -->
              <div v-if="activeFilter === 'Priority'" class="mb-3">
                <div v-for="(priorityItem, index) in priority" :key="index">
                  <input
                    type="checkbox"
                    :value="priorityItem"
                    v-model="selectedPriority"
                  />
                  <label>{{ priorityItem }}</label>
                </div>
              </div>

              <!-- Follow-up Date Filter -->
              <div v-if="activeFilter === 'Follow-up Date'" class="mb-3">
                <label for="fromDate" class="form-label">From Date</label>
                <input type="date" v-model="followupDate.fromDate" class="form-control" />
                <label for="toDate" class="form-label mt-2">To Date</label>
                <input type="date" v-model="followupDate.toDate" class="form-control" />
              </div>

              <!-- Created By Filter -->
              <div v-if="activeFilter === 'CreatedBy'" class="mb-3">
                <div v-for="(employee, index) in employees" :key="index">
                  <input
                    type="checkbox"
                    :value="employee.employeeId"
                    v-model="selectedEmployee"
                    id="checkbox2"
                  />
                  <label>
                    {{ employee["employees.firstName"] }}
                    {{ employee["employees.lastName"] }}</label
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="modal-footer justify-content-center">
          <button
            type="button"
            class="btn btn-sm btn-secondary"
            data-bs-dismiss="modal"
            @click="resetFilters"
          >
            Reset
          </button>
          &nbsp; &nbsp;
          <button
            type="button"
            class="btn btn-sm btn-primary"
            data-bs-dismiss="modal"
            @click="applyFilters"
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "LeadFilterModal",
  props: {
    filterOptions: {
      type: Array,
    },
  },
  data() {
    return {
      activeFilter: this.filterOptions[0],
      source: [],
      employees: [],
      selectedSource: [],
      Estimatestatus: [
        "All",
        "Draft",
        "Finalized",
        "Sent",
        "Approved",
        "Rejected",
        "Revised",
      ],
      leadstatus: [
        "All",
        "Claimed",
        "RNR",
        "ContactMode",
        "Meeting",
        "Estimate",
        "Negotiation",
        "Sold",
        "Lost",
        "Trash",
      ],
      selectedStatus: [],
      priority: ["All", "High", "Medium", "Low"],
      selectedPriority: [],
      followupDate: {
        fromDate: null,
        toDate: null,
      },

      selectedEmployee: [],
    };
  },
  mounted() {
    this.fetchSource();
    this.fetchEmployees();
  },

  methods: {
    getFilterColor(filter) {
      if (filter === "Priority") return "blue"; // Priority filter has blue
      return "purple"; // Default color
    },
    async fetchEmployees() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/employee/myTeam/Sales`);
        this.employees = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchSource() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/source/`);
        this.source = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    setActiveFilter(filter) {
      this.activeFilter = filter;
    },
    applyFilters() {
      const filterData = {
        source: this.selectedSource,
        status: this.selectedStatus,
        priority: this.selectedPriority,
        followupDate: {
          fromDate: this.followupDate.fromDate,
          toDate: this.followupDate.toDate,
        },
        salesPerson: this.selectedEmployee,
      };
 
      this.$emit("apply-filters", filterData);
    },
    resetFilters() {
      this.selectedSource = [];
      this.selectedStatus = [];
      this.selectedPriority = [];
      this.followupDate = { fromDate: null, toDate: null };
      this.selectedEmployee = [];
      this.applyFilters();
    },
    getFilterCount(filter) {
      if (filter === "Source") return this.selectedSource.length;
      if (filter === "Status") return this.selectedStatus.length;
      if (filter === "Priority") return this.selectedPriority.length;
      if (filter === "CreatedBy") return this.selectedEmployee.length;
      return 0;
    },
  },
};
</script>

<style scoped>
.modal-custom-position {
  position: fixed;
  right: 1.5%;
  top: 10.5%;
  min-width: 350px;
  max-width: 350px;
  min-height: 400px;
  max-height: 400px;
  overflow: auto;
  display: flex;
}

.filter-buttons {
  flex-basis: 30%;
  display: flex;
  flex-direction: column;
}

.filter-btn {
  font-size: 12px;
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #f0f0f0;
  border: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s;
}

.filter-btn:hover {
  background-color: #e0e0e0;
}

.filter-btn.active {
  background-color: #007bff;
  color: white;
}

.filter-options {
  flex-grow: 1;
  padding: 2px 10px;
}
.custom-checkbox {
  font-size: 14px;
}
.custom-checkbox input[type="checkbox"] {
  margin-right: 5px;
}
.custom-checkbox label {
  margin-bottom: 5px;
}
.modal-backdrop {
  background-color: transparent !important;
}
</style>
