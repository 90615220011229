<template>
  <div>
    <Header />
    <div class="content-container">
      <Nav />
      <div class="containerChild">
        <div class="attendance-page">
          <h6 class="text-start title p-3">Attendance</h6>

          <div class="attendance-container">
            <div class="row">
              <div
                class="col-md-2 col-sm-12 d-flex align-items-center justify-content-center"
              >
                <div class="profile-image me-3">
                  <img
                    v-if="EmployeeprofileImage"
                    :src="EmployeeprofileImage"
                    alt="Profile Photo"
                    class="uploaded-photo"
                  />
                  <img v-else src="../../assets/icons/profile.png" alt="Profile Image" />
                </div>
              </div>
              <div
                class="col-md-3 col-sm-12 d-flex align-items-start me-5 TodayAttendanceContainer"
              >
                <div class="employee-details">
                  <div class="employee-details-row mt-3">
                    <span>
                      <label class="text-muted">Name</label>
                      <p>
                        {{ employeeDetails.firstName }} {{ employeeDetails.lastName }}
                      </p>
                    </span>
                    <span>
                      <label class="text-muted">Employee ID</label>
                      <p>{{ employeeDetails.employeeNumber }}</p>
                    </span>
                    <span>
                      <label class="text-muted">Date</label>
                      <p>{{ this.$formatDateDMY(this.currentDate) }}</p>
                    </span>
                  </div>
                  <div>
                    <h6>Attendance</h6>
                  </div>
                  <div class="action-buttons mt-3">
                    <button
                      type="button"
                      class="btn btn-sm btn-outline-success me-5"
                      @click="handleCheckIn('CheckIn')"
                    >
                      Check In
                    </button>
                    <button
                      :disabled="
                        todayAttendance.length > 0 &&
                        todayAttendance[todayAttendance.length - 1].checkOut !== null
                      "
                      type="button"
                      class="btn btn-sm btn-outline-danger text-nowrap"
                      @click="handleCheckIn('CheckOut')"
                    >
                      Check Out
                    </button>
                  </div>
                </div>
              </div>

              <!-- Right Section: Today's Attendance -->
              <div class="col-md-6 col-sm-12 TodayAttendanceContainer ms-5">
                <h6 class="section-title">Today's Attendance</h6>
                <div class="attendance-table-container">
                  <table class="table table-striped">
                    <thead>
                      <tr class="sticky-top">
                        <th>Time</th>
                        <th>Status</th>
                        <th>Location</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="record in todayAttendance" :key="record.id">
                        <td>
                          {{
                            record.checkIn
                              ? this.formatTime(record.checkIn)
                              : this.formatTime(record.checkOut)
                          }}
                        </td>
                        <td :style="{ color: record.checkIn ? 'green' : 'red' }">
                          {{ record.checkIn ? "Check In" : "Check Out" }}
                        </td>
                        <td
                          class="ellipsis"
                          :title="
                            record.checkIn
                              ? record.checkInLocation
                              : record.checkOutLocation
                          "
                        >
                          {{
                            record.checkIn
                              ? record.checkInLocation
                              : record.checkOutLocation
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <!-- Filter Section -->
            <div class="row mt-4">
              <div class="col-12 d-flex justify-content-end flex-wrap">
                <select
                  v-model="selectedYear"
                  class="form-select me-2 year-select"
                  @change="handleMonthChange"
                >
                  <option v-for="year in years" :key="year" :value="year">
                    {{ year }}
                  </option>
                </select>
                <select
                  v-model="selectedMonth"
                  class="form-select month-select"
                  @change="handleMonthChange"
                >
                  <option
                    v-for="(month, index) in months"
                    :key="index"
                    :value="index + 1"
                  >
                    {{ month }}
                  </option>
                </select>
              </div>
            </div>

            <!-- Attendance History -->
            <div class="row mt-3">
              <div class="col-12">
                <div class="attendance-history-table-container">
                  <table class="table table-striped">
                    <thead class="sticky-top">
                      <tr>
                        <th>Date</th>
                        <th>Check In Time</th>
                        <th>Check Out Time</th>
                        <th>Check In Location</th>
                        <th>Check Out Location</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="record in attendanceHistory" :key="record.id">
                        <td>{{ this.$formatDateDMY(record.date) }}</td>
                        <td>{{ formatTime(record.logInTime) }}</td>
                        <td>{{ formatTime(record.logOutTime) }}</td>
                        <td :title="record.firstLogInLocation" class="ellipsis">
                          {{ record.firstLogInLocation || "-" }}
                        </td>
                        <td :title="record.lastLogOutLocation" class="ellipsis">
                          {{ record.lastLogOutLocation || "-" }}
                        </td>
                        <td>
                          <span
                            :class="{
                              badge: true,
                              'bg-success': record.status.toLowerCase() === 'present',
                              'bg-danger': record.status.toLowerCase() === 'absent',
                              'bg-info': record.status.toLowerCase() === 'halfday',
                            }"
                          >
                            {{ record.status }}
                          </span>
                        </td>
                      </tr>
                      <tr v-if="attendanceHistory.length === 0">
                        <td colspan="6" class="text-center">
                          No attendance records found
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <!-- Modal -->

    <div
      v-if="showLocationModal"
      style="
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <div
        style="
          background-color: white;
          padding: 20px;
          width: 60%;
          max-height: 60vh;
          border-radius: 8px;
          font-size: 0.75rem;
          display: flex;
          flex-direction: column;
        "
      >
        <!-- <h5 style="text-align: center; margin: 0 0 15px 0; font-size: 1rem;">Location Details</h5> -->
        <div class="modal-header">
          <h6 class="mb-0" style="font-size: 0.9rem; font-weight: 600">
            Select nearby location below :-
          </h6>
          <div v-if="exactLocation" class="location-ticker">
            <div class="ticker-content">
              <i class="fas fa-map-marker-alt me-2"></i>
              current location: {{ exactLocation }}
            </div>
          </div>
          <span class="close-btn" @click="closeModal">&times;</span>
        </div>

        <div style="flex: 1; overflow-x: auto; display: flex; flex-direction: column">
          <div style="margin: 0 auto; min-width: 400px">
            <table style="width: 100%; border-collapse: collapse; table-layout: fixed">
              <thead>
                <tr>
                  <th style="padding: 8px; text-align: left; width: 5%"></th>
                  <th style="padding: 8px; text-align: left">Name</th>
                  <th style="padding: 8px; text-align: left">Address</th>
                </tr>
              </thead>
            </table>
          </div>

          <div style="overflow-x: auto; margin: 0 auto; min-width: 400px">
            <table style="width: 100%; border-collapse: collapse; table-layout: fixed">
              <tbody class="location-table-body">
                <tr
                  v-for="location in locationData"
                  :key="location.id"
                  :style="{
                    color: location.id === selectedLocationId ? 'green' : 'black',
                    fontWeight: location.id === selectedLocationId ? 'bold' : 'normal',
                  }"
                >
                  <td style="padding: 8px; text-align: left; width: 5%">
                    <input
                      type="radio"
                      :value="location.id"
                      v-model="selectedLocationId"
                      style="margin-right: 10px; cursor: pointer"
                    />
                  </td>
                  <td style="padding: 8px; text-align: left">{{ location.name }}</td>
                  <td style="padding: 8px; text-align: left">
                    {{ location.address }}, {{ location.city }}, {{ location.state }} -
                    {{ location.pincode }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div
          style="
            margin-top: 15px;
            padding-top: 10px;
            border-top: 1px solid #eee;
            display: flex;
            justify-content: space-between;
            gap: 10px;
          "
        >
          <button
            class="btn btn-outline-secondary"
            @click="closeModal"
            style="padding: 6px 12px; font-size: 0.75rem"
          >
            Close
          </button>
          <button
            class="btn btn-outline-primary"
            @click="submitSelectedLocation"
            style="padding: 6px 12px; font-size: 0.75rem"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.exact-location-banner {
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  color: #721c24;
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  font-size: 0.9rem;
  text-align: center;
}
</style>

<script>
import Header from "../Header.vue";
import Nav from "../Nav.vue";
import axios from "axios";
export default {
  name: "AttendancePage",
  data() {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    return {
      selectedEmployeeId: this.$LocalUser.user.employeeId,
      attendanceHistory: [],
      todayAttendance: [],
      Checktype: "CheckIn",
      exactLocation: null,
      selectedLocationId: null,
      locationData: [],
      showLocationModal: false,
      currentDate: new Date().toISOString().split("T")[0],
      EmployeeprofileImage: null,
      employeeDetails: {},
      selectedYear: currentYear, // Set default to current year
      selectedMonth: currentMonth, // Set default to current month index
      years: Array.from({ length: 11 }, (_, i) => currentYear - 5 + i),
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    };
  },
  components: {
    Header,
    Nav,
  },
  mounted() {
    // this.featchEmployeeData();
    this.fetchTodayAttendance();
    this.fetchMonthlyAttendance();
  },
  methods: {
    async fetchMonthlyAttendance() {
      try {
        if (!this.selectedYear || !this.selectedMonth) return;

        const response = await axios.get(
          `${this.$apiEndPoint}/myWorkHub/${this.selectedYear}/${this.selectedMonth}/${this.selectedEmployeeId}`
        );
        this.attendanceHistory = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    formatTime(time) {
      if (!time) return "";
      const [hours, minutes] = time.split(":");
      const ampm = hours >= 12 ? "p.m" : "a.m";
      const formattedHours = hours % 12 || 12;
      return `${formattedHours}:${minutes} ${ampm}`;
    },
    async fetchTodayAttendance() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/myWorkHub/getTodayAttendance/${this.selectedEmployeeId}`
        );
        this.todayAttendance = response.data.todayAttendance;
        this.employeeDetails = response.data.employee;
        this.EmployeeprofileImage = response.data.employee.signedUrl;
      } catch (error) {
        this.$handleError(error);
      }
    },
    // featchEmployeeData() {
    //   axios
    //     .get(`${this.$apiEndPoint}/employee/${this.selectedEmployeeId}`)
    //     .then((response) => {
    //       this.employeeDetails = response.data;
    //       this.EmployeeprofileImage = response.data.signedUrl;
    //     })
    //     .catch((error) => {
    //       this.$handleError(error);
    //     });
    // },
    async submitSelectedLocation() {
      try {
        const selectedLocation = this.locationData.find(
          (location) => location.id === this.selectedLocationId
        );

        if (!selectedLocation) {
          this.$toast.error("Please select a location");
          return;
        }

        // Filter out null/undefined values and build the address string
        const addressParts = [
          selectedLocation.address,
          selectedLocation.city,
          selectedLocation.state,
          selectedLocation.pincode,
        ].filter((part) => part); // This removes any null, undefined, or empty strings

        const locationAddress = addressParts.join(", ");

        if (!locationAddress) {
          this.$toast.error("Invalid location details");
          return;
        }

        const now = new Date();
        const currentTime = `${String(now.getHours()).padStart(2, "0")}:${String(
          now.getMinutes()
        ).padStart(2, "0")}:${String(now.getSeconds()).padStart(2, "0")}`;

        const payload = {
          location: locationAddress,
          time: currentTime,
        };

        await axios.post(`${this.$apiEndPoint}/myWorkHub/${this.Checktype}`, payload);
        this.$toast(`${this.Checktype} successful`, "success");
        await this.fetchTodayAttendance();
        this.fetchMonthlyAttendance();
        this.closeModal();
      } catch (error) {
        this.$handleError(error);
      }
    },
    handleMonthChange() {
      if (this.selectedYear && this.selectedMonth) {
        this.fetchMonthlyAttendance();
      }
    },
    // ..

    handleCheckIn(Checktype) {
      this.Checktype = Checktype;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            this.fetchLocationData(latitude, longitude);
          },
          (error) => {
            console.error("Error getting location:", error.message);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    },
    async fetchLocationData(latitude, longitude) {
      this.showLocationModal = true; // Show the modal with location details
      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/myWorkHub/getGoogleLocation`,
          {
            latitude,
            longitude,
          }
        );
        this.locationData = response.data.similarAddresses || [];
        this.exactLocation = response.data.googleAddress;
      } catch (error) {
        console.error("Error fetching location:", error);
      }
    },
    closeModal() {
      this.showLocationModal = false;
    },
  },
};
</script>

<style scoped>
.attendance-page {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.title {
  font-size: 1.2rem;
  font-weight: bold;
}

.attendance-container {
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 10px;
  font-size: 0.9rem;
}

.profile-image img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #000;
}

.profile-image {
  display: flex;
  align-items: center;
  /* Align image to the start */
}

.TodayAttendanceContainer {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 10px;
  background-color: #eee;
}

.employee-details span {
  display: block;
  margin-bottom: 5px;
}

.employee-details label {
  font-size: 12px;
}

.employee-details-row {
  display: flex;
  justify-content: space-between;
  gap: 35px;
}

.action-buttons button {
  width: 80px;
  font-size: 0.8rem;
}

.section-title {
  font-size: 1rem;
  margin-bottom: 10px;
}

.attendance-table-container {
  max-height: 150px;
  overflow-y: auto;
}

.year-select,
.month-select {
  width: auto;
  font-size: 0.85rem;
}

.attendance-history-table-container {
  /* Replace percentage height with fixed height or viewport units */
  height: 40vh; /* or use vh units: 40vh */
  overflow-y: auto;
  /* Add these properties for better table behavior */
  position: relative;
}

table th {
  background-color: #ccc !important;
}

table th:first-child {
  border-radius: 5px 0 0 5px;
}

table th:last-child {
  border-radius: 0 5px 5px 0;
}

.modal-header {
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.location-ticker {
  margin-top: 10px;
  background: linear-gradient(90deg, #f8f9fa, #e9ecef);

  padding: 8px;
  overflow: hidden;
  position: relative;
}

.ticker-content {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  color: #495057;
  animation: slide 10s linear infinite;
  white-space: nowrap;
}

.ticker-content i {
  color: #dc3545;
  margin-right: 8px;
}

@keyframes slide {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

/* Optional: Pause animation on hover */
.location-ticker:hover .ticker-content {
  animation-play-state: paused;
}

.location-table-body tr:hover {
  background-color: #f0f0f0;
}

.close-btn {
  font-size: 24px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .employee-details-row {
    flex-direction: column;
    gap: 10px;
  }

  .action-buttons {
    display: flex;
    justify-content: space-between;
  }

  .TodayAttendanceContainer {
    margin-left: 0 !important;
    /* Removes ms-5 margin on mobile */
    margin-top: 15px;
    /* Adds some vertical spacing instead */
  }

  .attendance-page {
    height: 100%;
    width: 100%;
    overflow: scroll;
  }
}
</style>
