<template>
  <div>
    <!-- Share Modal -->
    <div
      class="modal fade"
      id="PibShareModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="PibShareModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="PibShareModalLabel">Share Project</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div v-if="shareLink" class="mb-4">
              <label for="shareLinkInput" class="form-label">Copy Link</label>
              <div class="input-group">
                <input
                  type="text"
                  id="shareLinkInput"
                  class="form-control"
                  :value="shareLink"
                  readonly
                />
                <button class="btn btn-outline-secondary" @click="copyLink">Copy</button>
              </div>
            </div>

            <div class="mb-4">
              <label for="emailInput" class="form-label">Send via Email</label>
              <input
                type="email"
                id="emailInput"
                class="form-control"
                v-model="emailInput"
                placeholder="Enter email addresses (comma-separated)"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" @click="sendEmail">Send Email</button>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    designProjectId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      shareLink: "",
      emailInput: "",
    };
  },
  watch: {
    // Watch for changes to designProjectId
    designProjectId(newId) {
      this.fetchShareLink(newId);
    },
  },
  mounted() {
    this.fetchShareLink(this.designProjectId);
  },
  methods: {
    // Open the Share Modal
    openModal() {
      this.fetchShareLink(this.designProjectId); // Fetch the link when modal is triggered
    },

    // Fetch shareable link based on designProjectId
    fetchShareLink(projectId) {
      const apiUrl = `${this.$apiEndPoint}/projectInfoBoxSection/generatelink`;
      axios
        .post(apiUrl, { designProjectId: projectId })
        .then((response) => {
          this.shareLink = response.data.link;
        })
        .catch((error) => {
          this.$handleError("Error generating link:", error); 
        });
    },

    // Copy the link to the clipboard
    copyLink() {
      navigator.clipboard.writeText(this.shareLink).then(() => {
        alert("Link copied to clipboard!");
      });
    },

    // Send the link via email
    sendEmail() {
      const apiUrl = `${this.$apiEndPoint}/projectInfoBoxSection/sendPib`;
      const emailList = this.emailInput.split(",").map((email) => email.trim());

      if (emailList.length > 0 && this.validateEmails(emailList)) {
        axios
          .post(apiUrl, {
            customerEmailId: emailList,
            designProjectId: this.designProjectId,
          })
          .then(() => {
            alert("Email sent successfully!");
            this.closeModal(); // Close the modal after sending the email
          })
          .catch((error) => {
            this.$handleError("Error sending email:", error); 
            alert("Failed to send email. Please try again.");
          });
      } else {
        alert("Please enter valid email addresses.");
      }
    },

    // Validate email addresses
    validateEmails(emailList) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailList.every((email) => emailRegex.test(email));
    },
  },
};
</script>

<style scoped>
input:focus,
textarea:focus,
select:focus {
  outline: none;
  /* Remove default outline */
  box-shadow: none;
  /* Add a custom focus shadow */
}
/* Custom Modal Styling */
.modal-dialog {
  max-width: 500px; /* Set the modal width */
  margin: 30px auto;
}

.modal-header {
  background-color: #f7f7f7;
  border-bottom: 1px solid #ddd;
}

.modal-footer {
  background-color: #f7f7f7;
  border-top: 1px solid #ddd;
}

.modal-title {
  font-size: 1.25rem;
  font-weight: bold;
}

.input-group .form-control {
  border-radius: 5px;
}

.input-group .btn {
  border-radius: 5px;
}

.btn-outline-secondary {
  background-color: #f8f9fa;
}

button {
  font-weight: 600;
}

.btn-close {
  font-size: 1.5rem;
}

.form-label {
  font-weight: 600;
}

.form-control {
  border-radius: 5px;
}

@media (max-width: 767px) {
  .modal-dialog {
    max-width: 90%;
  }
}
</style>
