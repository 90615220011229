<template>
  <div class="modal fade" id="tableDetailModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content custom-modal-content">
        <div class="modal-header custom-modal-header">
          <h5 class="modal-title">Details</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body custom-modal-body">
          <ul class="list-group">
            <li
              v-for="(item, index) in details"
              :key="index"
              class="list-group-item custom-list-item"
            >
              <div class="label">
                <strong>{{ item.label }}</strong>
              </div>
              <div class="colon">:</div>
              <div class="value">
                <span>{{ item.value }}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    details: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
/* Custom Modal Styles */
.custom-modal-content {
  border: 1px solid #dee2e6;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 0 5px;
}

.custom-modal-header {
  background-color: #f8f9fa;
  border-bottom: 2px solid black; /* Black underline below the header */
  padding: 15px;
  border-radius: 10px 10px 0 0;
}

.custom-modal-body {
  padding: 15px;
  max-height: 400px;
  overflow-y: auto; /* Allow vertical scrolling */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* List Item Styling */
.custom-list-item {
  display: flex;
  align-items: flex-start; /* Align items at the start */
  padding: 10px;
  border: none;
  margin-bottom: 5px;
  background-color: transparent;
}

.label {
  flex: 0 0 30%; /* Fixed width for labels */
  text-align: left;
  color: #343a40;
  font-size: 12px !important;
  word-wrap: break-word; /* Ensure text wraps */
  white-space: normal; /* Prevent text from staying on one line */
}

.colon {
  flex: 0 0 auto; /* Fixed space for colon */
  margin-right: 10px;
  text-align: center;
  font-weight: bold;
  color: #6c757d;
  font-size: 12px !important;
}

.value {
  flex: 1; /* Take up the remaining space */
  text-align: left;
  color: #495057;
  font-size: 12px !important;
  word-break: break-all;
  white-space: pre-wrap;
}

/* Modal Close Button */
.btn-close {
  background-color: transparent;
  border: none;
}

strong {
  font-weight: 500;
}

/* Mobile View Adjustments */
@media (max-width: 768px) {
  .custom-modal-header {
    padding: 10px;
  }

  .custom-modal-body {
    padding: 10px;
  }
}
</style>
