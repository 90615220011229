<template>
  <div
    class="modal fade"
    id="OnsiteEntriesModal"
    tabindex="-1"
    aria-labelledby="OnsiteEntriesModalLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      style="min-width: 60%; overflow: auto"
    >
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <div class="d-flex justify-content-between w-100 align-items-center">
            <!-- Title on Left -->
            <h6 class="modal-title">Outward Entry</h6>
            <!-- Close Button on Right -->
            <img
              src="../../assets/icons/cancel.png"
              alt="Close"
              style="width: 20px; height: 20px; cursor: pointer"
              data-bs-dismiss="modal"
            />
          </div>
        </div>

        <!-- Date and To Store Fields -->
        <div class="modal-body">
          <div>
            <!-- WEB Layout -->
            <div class="row mb-3 d-none d-md-flex">
              <!-- Date Field -->
              <div
                class="col-md-6 d-flex justify-content-between align-items-center mb-3 mb-md-0"
              >
                <label for="date" class="col-form-label">Date:</label>
                <input type="date" class="form-control w-75" v-model="form.date" />
              </div>

              <!-- To Store Field -->
              <div class="col-md-6 d-flex justify-content-between align-items-center">
                <label for="projectName" class="col-form-label">To Store: <span class="text-danger">*</span></label>
                <select
                  class="form-control form-select w-75"
                  id="category"
                  v-model="form.toStore"
                >
                  <option
                    v-for="Project in Projects"
                    :key="Project.id"
                    :value="Project.id"
                  >
                    {{ Project.storeName }}
                  </option>
                </select>
              </div>
            </div>

            <!-- MOBILE Layout -->
            <div class="row mb-3 d-flex d-md-none flex-column">
              <!-- Date Field -->
              <div class="d-flex justify-content-between align-items-center mb-3">
                <label for="date" class="col-form-label">Date:</label>
                <input type="date" class="form-control w-50" v-model="form.date" />
              </div>

              <!-- To Store Field -->
              <div class="d-flex justify-content-between align-items-center">
                <label for="projectName" class="col-form-label">To Store: <span class="text-danger">*</span></label>
                <select
                  class="form-control form-select w-50"
                  id="category"
                  v-model="form.toStore"
                >
                  <option
                    v-for="Project in Projects"
                    :key="Project.id"
                    :value="Project.id"
                  >
                    {{ Project.storeName }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <!-- Table -->
          <table class="table files-table">
            <thead>
              <tr>
                <!-- <th class="WEB">S/N</th> -->
                <th>Product Name</th>
                <th>Qty</th>
                <th>Unit Type</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in Rows" :key="index">
                <!-- <td class="WEB">{{ index + 1 }}</td> -->
                <td>
                  <input
                    class="w-100 form-control"
                    list="products"
                    v-model="row.productName"
                    @focus="activeRow = index"
                    @input="searchProduct(row.productName, index)"
                    @change="fillProductDetails(index)"
                    :ref="'productNameInput' + index"
                  />
                  <ul
                    v-if="row.filteredProducts.length > 0 && activeRow === index"
                    class="dropdown-menu show productDropDown p-1"
                  >
                    <li
                      v-for="product in row.filteredProducts"
                      :key="product.id"
                      @click="selectProduct(product, index)"
                      class="p-1"
                    >
                      {{ product["products.productName"] }} ({{
                        product.availableQuantity
                      }})
                    </li>
                  </ul>
                </td>
                <td>
                  <input
                    type="number"
                    class="form-control"
                    v-model.number="row.sentQuantity"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    v-model="row.unitType"
                    readonly
                  />
                </td>
                <td>
                  <img
                    style="width: 13px; height: 13px; cursor: pointer"
                    src="../../assets/icons/redDelete.png"
                    alt="Delete"
                    @click="removeRow(index)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <button @click="addRow" class="btn btn-sm btn-primary">Add Row</button>
        </div>

        <!-- Footer -->
        <div class="modal-footer justify-content-center">
          <button
            type="button"
            class="btn btn-sm btn-primary"
            :disabled="!form.toStore"
            @click="CreateOutWordEntry"
            data-bs-dismiss="modal"
          >
            Save
          </button>
          <button
            type="button"
            class="btn btn-sm btn-secondary"
            data-bs-dismiss="modal"
            @click="resetForm"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "OnsiteEntriesModal",
  props: {
    CompanyprojectId: {
      type: Number,
      required: true,
    },
    FromStore: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      deleteItems: false,
      Projects: [],
      Products: [],
      Rows: [
        {
          productName: "",
          sentQuantity: 0,
          unitType: "",
          productId: "",
          filteredProducts: [],
          rowProducts: [],
        },
      ],
      form: {
        date: new Date().toISOString().slice(0, 10),
        fromStore: null,
        toStore: null,
        status: "Sent",
        Rows: [],
      },
    };
  },
  mounted() {
    this.fetchProjects();
  },
  watch: {
    CompanyprojectId(newValue) {
      this.Rows.forEach((stock) => (stock.projectId = newValue));
    },
    FromStore(newValue) {
      this.form.fromStore = newValue;
    },
  },
  methods: {
    checkQuantityLimit(index) {
      const row = this.Rows[index];
    
      // Find the availableQuantity of the selected product
      const selectedProduct = row.rowProducts.find(
        (product) => product["products.id"] === row.productId
      );
  
      if (selectedProduct) {
        // If the quantity exceeds availableQuantity, set it to availableQuantity
        if (row.sentQuantity > selectedProduct.availableQuantity) {
          row.sentQuantity = selectedProduct.availableQuantity;
        }
      }
    },
    fetchProductList() {
      axios
        .get(`${this.$apiEndPoint}/productList`)
        .then((response) => {
          this.Products = response.data;
        })
        .catch((error) => {
          this.$handleError("Error fetching product list:", error);
        });
    },
    fillProductDetails(index) {
      const product = this.Products.find(
        (p) => p.productName === this.Rows[index].productName
      );
      if (product) {
        this.Rows[index].unitType = product.unitType;
        this.Rows[index].productId = product.id;
      }
    },
    selectProduct(product, index) {
      this.Rows[index].productName = product["products.productName"];
      this.Rows[index].productId = product["products.id"];
      this.Rows[index].unitType = product["products.unitType"] || "";
      this.Rows[index].filteredProducts = [];
    },
    searchProduct(searchString, index) {
      if (searchString.length > 0) {
        axios
          .post(
            `${this.$apiEndPoint}/inwardOutwardEntries/getProducts/${this.FromStore}`,
            { searchString }
          )
          .then((response) => {
            this.Rows[index].filteredProducts = response.data;
            this.Rows[index].rowProducts = response.data;
          })
          .catch((error) => {
            this.$handleError("Error searching products:", error); 
          });
      } else {
        this.Rows[index].filteredProducts = [];
      }
    },
    fetchProjects() {
      axios
        .get(`${this.$apiEndPoint}/store/getStores`)
        .then((response) => {
          this.Projects = response.data;
        })
        .catch((error) => {
          this.$handleError("Error fetching projects:", error); 
        });
    },
    addRow() {
      const lastRow = this.Rows[this.Rows.length - 1];
      if (lastRow && lastRow.productName.trim() !== "") {
        this.Rows.push({
          productName: "",
          sentQuantity: 0,
          unitType: "",
          productId: "",
          filteredProducts: [],
        });
      }
    },
    removeRow(index) {
      this.Rows.splice(index, 1);
    },
    CreateOutWordEntry() {
      this.form.Rows = [...this.Rows];
      axios
        .post(`${this.$apiEndPoint}/inwardOutwardEntries`, this.form)
        .then(() => {
          this.$toast("Entry created successfully.", "success");
          this.$emit("fetchOutwardEntries");
          this.resetForm();
        })
        .catch((error) => {
          this.$handleError("Error creating entry:", error); 
        });
    },
    resetForm() {
      this.form = {
        date: new Date().toISOString().slice(0, 10),
        fromStore: this.form.fromStore,
        toStore: null,
        status: "Sent",
        Rows: [],
      };
      this.Rows = [
        {
          productName: "",
          sentQuantity: 0,
          unitType: "",
          productId: null,
          filteredProducts: [],
        },
      ];
    },
  },
};
</script>

<style scoped>
input:focus,
textarea:focus,
select:focus {
  outline: none;
  box-shadow: none;
}
.selected-employee {
  margin-bottom: 5px;
}
.remove-employee {
  cursor: pointer;
  margin-left: 5px;
}
label {
  font-weight: 500;
  color: black;
}

.modal-body {
  max-height: 500px;
  overflow-y: auto;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table .sticky-header th {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 2;
}

.files-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.files-table th,
.files-table td {
  text-align: left;
  padding: 6px;
  border-bottom: 1px solid #ddd;
}

.files-table th {
  background-color: #bcbfc6;
}

.files-table tr:hover {
  background-color: #f5f5f5;
}

.files-table th:nth-child(1),
.files-table td:nth-child(1) {
  width: 60%;
  text-align: left;
}
@media (max-width: 768px) {
  .modal-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .modal-header .modal-title {
    margin-bottom: 10px;
  }

  .row .col-12 {
    flex: 1;
    width: 100%;
  }

  .files-table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  .files-table th,
  .files-table td {
    text-align: left;
    min-width: 100px;
  }
}
</style>
