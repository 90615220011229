<template>
  <div v-if="estimateIndex">
    <!-- Email composition modal -->
    <div class="modal" id="emailModal" aria-labelledby="emailModalLabel">
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header" style="background-color: #b1c5df">
            <h5 class="modal-title" id="emailModalLabel">
              Email To {{ routeParams.clientName }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="emailContent">
            <div class="modal-body border-2">
              <form>
                <!-- From field -->
                <div class="mb-2 row" style="border-bottom: 1px solid #000000">
                  <label for="emailFrom" class="col-sm-2 col-form-label">From:</label>
                  <div class="col-sm-10 mt-1">
                    <span
                      v-if="this.toMail"
                      style="
                        background-color: #f8f8f8;
                        border-radius: 5px;
                        padding: 5px 10px;
                      "
                      >{{ this.toMail }}@arivu.app
                    </span>
                  </div>
                </div>

                <!-- To field -->
                <div class="mb-2 row" style="border-bottom: 1px solid #000000">
                  <label for="emailTo" class="col-sm-2 col-form-label">To:</label>
                  <div class="col-sm-10 mt-1">
                    <span
                      style="
                        background-color: #f8f8f8;
                        border-radius: 5px;
                        padding: 5px 10px;
                      "
                      >{{ this.emailTo }}</span
                    >
                  </div>
                </div>

                <!-- CC field -->
                <div class="mb-2 row" style="border-bottom: 1px solid #000000">
                  <label for="emailCC" class="col-sm-2 col-form-label">CC:</label>
                  <div class="col-sm-10 mt-1">
                    <input
                      type="text"
                      class="form-control"
                      id="emailCC"
                      v-model="emailCC"
                      style="
                        background-color: #f8f8f8;
                        border-radius: 5px;
                        padding: 5px 10px;
                        border: none;
                        outline: none;
                      "
                    />
                  </div>
                </div>
                <div class="mb-2 row" style="border-bottom: 1px solid #000000">
                  <label for="emailSubject" class="col-sm-2 col-form-label"
                    >Subject:</label
                  >
                  <input
                    class="col-sm-10 mt-1 border-0"
                    style="font-weight: 700"
                    v-model="emailSubject"
                  />
                </div>

                <!-- Email body textarea -->
                <div class="mb-3 row mt-3">
                  <div class="col-sm-12">
                    Dear {{ routeParams.clientName }} , <br />
                    <textarea
                      v-model="emailBody"
                      class="form-control mt-3 p-1"
                      rows="7"
                      placeholder="Write here......"
                    >
                    </textarea>
                  </div>
                </div>
              </form>

              <!-- Signature card -->
              <div class="signature-card d-flex flex-column">
                <h6>Regards,</h6>
                <span
                  >{{ fetchedEstimateData["employees.firstName"] }}
                  {{ fetchedEstimateData["employees.lastName"] }}</span
                >
                <!-- Display last name as 'empname' -->
                <span>{{ fetchedEstimateData["employees.employeeEmailId"] }}</span>
                <!-- Display email as 'username' -->
                <span>{{ fetchedEstimateData["employees.mobileNumber"] }}</span>
                <!-- Display contact number -->
              </div>
              <div class="mb-2 row">
                <div class="col-sm-12">
                  <div class="d-flex align-items-center">
                    <input
                      type="file"
                      @change="handleFileUpload"
                      multiple
                      accept=".pdf,.doc,.docx"
                      style="display: none"
                      ref="fileInput"
                    />

                    <div class="attached-files ml-3">
                      <div class="attached-files ml-3">
                        <span
                          v-for="(file, index) in attachedFiles"
                          :key="index"
                          class="mr-2"
                        >
                          <span
                            style="
                              background-color: #f8f8f8;
                              font-weight: 500;
                              padding: 5px;
                              margin: 5px 5px;
                              display: flex;
                            "
                          >
                            <div style="display: flex; flex-direction: column">
                              <span> {{ file.name }}</span>
                              <span style="font-size: 10px">
                                ({{ formatFileSize(file.size) }})</span
                              >
                            </div>
                            <div>
                              <span
                                @click="removeFile(index)"
                                class="removeFile ms-5"
                                style="cursor: pointer"
                                >&#10005;</span
                              >
                            </div>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Display alert if total attachment size exceeds 15MB -->
              <div
                v-if="totalAttachmentSize > 15 * 1024 * 1024"
                class="alert alert-danger"
              >
                Total attachment size exceeds 15 MB.
              </div>
            </div>

            <div class="d-flex">
              <button type="button" class="saveButton" @click="$refs.fileInput.click()">
                Attach File
              </button>
              <button
                type="submit"
                class="browse"
                @click="sendEmail"
                data-bs-dismiss="modal"
              >
                Send
              </button>
              <button type="button" class="cancelButton" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="SalesOrderId">
    <!-- Email composition modal -->
    <div class="modal" id="emailModal" aria-labelledby="emailModalLabel">
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header" style="background-color: #b1c5df">
            <h5 class="modal-title" id="emailModalLabel">
              Email To {{ routeParams.clientName }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="emailContent">
            <div class="modal-body border-2">
              <form>
                <!-- From field -->
                <div class="mb-2 row" style="border-bottom: 1px solid #000000">
                  <label for="emailFrom" class="col-sm-2 col-form-label">From:</label>
                  <div class="col-sm-10 mt-1">
                    <span
                      v-if="this.toMail"
                      style="
                        background-color: #f8f8f8;
                        border-radius: 5px;
                        padding: 5px 10px;
                      "
                      >{{ this.toMail }}@arivu.app</span
                    >
                  </div>
                </div>

                <!-- To field -->
                <div class="mb-2 row" style="border-bottom: 1px solid #000000">
                  <label for="emailTo" class="col-sm-2 col-form-label">To:</label>
                  <div class="col-sm-10 mt-1">
                    <span
                      style="
                        background-color: #f8f8f8;
                        border-radius: 5px;
                        padding: 5px 10px;
                      "
                      >{{ this.emailTo }}</span
                    >
                  </div>
                </div>

                <!-- CC field -->
                <div class="mb-2 row" style="border-bottom: 1px solid #000000">
                  <label for="emailCC" class="col-sm-2 col-form-label">CC:</label>
                  <div class="col-sm-10 mt-1">
                    <input
                      type="text"
                      class="form-control"
                      id="emailCC"
                      v-model="emailCC"
                      style="
                        background-color: #f8f8f8;
                        border-radius: 5px;
                        padding: 5px 10px;
                        border: none;
                        outline: none;
                      "
                    />
                  </div>
                </div>

                <div class="mb-2 row" style="border-bottom: 1px solid #000000">
                  <label for="emailSubject" class="col-sm-2 col-form-label"
                    >Subject:</label
                  >
                  <input
                    class="col-sm-10 mt-1 border-0"
                    style="font-weight: 700"
                    v-model="emailSubject"
                  />
                </div>

                <!-- Email body textarea -->
                <div class="mb-3 row mt-3">
                  <div class="col-sm-12">
                    Dear {{ routeParams.clientName }} , <br />
                    <textarea
                      v-model="emailBody"
                      class="form-control mt-3 p-1"
                      rows="7"
                      placeholder="Write here......"
                    >
                    </textarea>
                  </div>
                </div>

                <!-- Attached files -->
              </form>

              <!-- Signature card -->
              <div class="signature-card d-flex flex-column">
                <h6>Regards,</h6>
                <span
                  >{{ fetchedSalesOrderData["employees.firstName"] }}
                  {{ fetchedSalesOrderData["employees.lastName"] }}</span
                >
                <!-- Display last name as 'empname' -->
                <span>{{ fetchedSalesOrderData["employees.employeeEmailId"] }}</span>
                <!-- Display email as 'username' -->
                <span>{{ fetchedSalesOrderData["employees.mobileNumber"] }}</span>
                <!-- Display contact number -->
              </div>
              <div class="mb-2 row">
                <div class="col-sm-12">
                  <div class="d-flex align-items-center">
                    <input
                      type="file"
                      @change="handleFileUploadSalesOrder"
                      style="display: none"
                      ref="fileInput"
                    />

                    <div class="attached-files ml-3">
                      <div class="attached-files ml-3">
                        <span
                          v-for="(file, index) in attachedFiles"
                          :key="index"
                          class="mr-2 d-flex"
                        >
                          <span
                            style="
                              background-color: #f8f8f8;
                              font-weight: 500;
                              padding: 5px;
                              margin: 5px 5px;
                              display: flex;
                            "
                          >
                            <div style="display: flex; flex-direction: column">
                              <span> {{ file.name }}</span>
                              <span style="font-size: 10px">
                                ({{ formatFileSize(file.size) }})</span
                              >
                            </div>
                            <div>
                              <span
                                @click="removeFile(index)"
                                class="removeFile ms-5"
                                style="cursor: pointer"
                                >&#10005;</span
                              >
                            </div>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex">
              <button type="button" class="saveButton" @click="$refs.fileInput.click()">
                Attach File
              </button>
              <button
                type="submit"
                class="browse"
                @click="sendSalesOrderEmail"
                data-bs-dismiss="modal"
              >
                Send
              </button>
              <button type="button" class="cancelButton" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="modal" id="emailModal" aria-labelledby="emailModalLabel">
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header" style="background-color: #b1c5df">
            <h5 class="modal-title" id="emailModalLabel">Email To</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="emailContent">
            <div class="modal-body border-2">
              <form>
                <!-- From field -->
                <div class="mb-2 row" style="border-bottom: 1px solid #000000">
                  <label for="emailFrom" class="col-sm-2 col-form-label">From:</label>
                  <div class="col-sm-10 mt-1">
                    <span
                      v-if="this.toMail"
                      style="
                        background-color: #f8f8f8;
                        border-radius: 5px;
                        padding: 5px 10px;
                      "
                      >{{ this.toMail }}@arivu.app</span
                    >
                  </div>
                </div>

                <!-- To field -->
                <div class="mb-2 row" style="border-bottom: 1px solid #000000">
                  <label for="emailTo" class="col-sm-2 col-form-label">To:</label>
                  <div class="col-sm-10 mt-1">
                    <span
                      style="
                        background-color: #f8f8f8;
                        border-radius: 5px;
                        padding: 5px 10px;
                      "
                      >{{ this.emailTo }}</span
                    >
                  </div>
                </div>

                <!-- CC field -->
                <div class="mb-2 row" style="border-bottom: 1px solid #000000">
                  <label for="emailCC" class="col-sm-2 col-form-label">CC:</label>
                  <div class="col-sm-10 mt-1">
                    <input
                      type="text"
                      class="form-control"
                      id="emailCC"
                      v-model="emailCC"
                      style="
                        background-color: #f8f8f8;
                        border-radius: 5px;
                        padding: 5px 10px;
                        border: none;
                        outline: none;
                      "
                    />
                  </div>
                </div>

                <!-- Subject field -->
                <div class="mb-2 row" style="border-bottom: 1px solid #000000">
                  <label for="emailSubject" class="col-sm-2 col-form-label"
                    >Subject:</label
                  >
                  <input
                    class="col-sm-10 mt-1 border-0"
                    style="font-weight: 700"
                    v-model="emailSubject"
                  />
                </div>

                <div class="mb-3 row mt-3">
                  <div class="col-sm-12">
                    Dear {{ projectData["designProjects.customers.name"] }} , <br />
                    <textarea
                      v-model="emailBody"
                      class="form-control mt-3 p-1"
                      rows="7"
                      placeholder="Write here......"
                    >
                    </textarea>
                  </div>
                </div>

                <!-- Attached files -->
              </form>

              <!-- Signature card -->
              <div class="signature-card d-flex flex-column">
                <h6>Regards,</h6>
                <span>{{ $LocalUser.user.fullname }}</span>
                <!-- Display fullname -->
                <span>{{ $LocalUser.user.email }}</span>
                <!-- Display email -->
                <span>{{ $LocalUser.user.mobileNumber }}</span>
              </div>
              <div class="mb-2 row">
                <div class="col-sm-12">
                  <div class="d-flex align-items-center">
                    <input
                      type="file"
                      @change="handleFileUploadsignOffDocument"
                      style="display: none"
                      ref="fileInput"
                    />

                    <div class="attached-files ml-3">
                      <div class="attached-files ml-3">
                        <!-- <span
                          v-if="projectData['files.awsFiles.fileName']"
                          class="mr-2 d-flex"
                        >
                          <span
                            style="
                              background-color: #f8f8f8;
                              font-weight: 500;
                              padding: 5px;
                              margin: 5px 5px;
                              display: flex;
                            "
                          >
                            <div style="display: flex; flex-direction: column">
                              <span> {{ projectData["files.awsFiles.fileName"] }}</span>
                              <span style="font-size: 10px">
                                ({{
                                  formatFileSize(projectData["files.awsFiles.fileSize"])
                                }})</span
                              >
                            </div>
                            <div>
                              <span
                                @click="removeFile(index)"
                                class="removeFile ms-5"
                                style="cursor: pointer"
                                >&#10005;</span
                              >
                            </div>
                          </span>
                        </span> -->

                        <span
                          v-for="(file, index) in attachedFiles"
                          :key="index"
                          class="mr-2 d-flex"
                        >
                          <span
                            style="
                              background-color: #f8f8f8;
                              font-weight: 500;
                              padding: 5px;
                              margin: 5px 5px;
                              display: flex;
                            "
                          >
                            <div style="display: flex; flex-direction: column">
                              <span> {{ file.name }}</span>
                              <span style="font-size: 10px"> ({{ file.size }})</span>
                            </div>
                            <div>
                              <span
                                @click="removeFile(index)"
                                class="removeFile ms-5"
                                style="cursor: pointer"
                                >&#10005;</span
                              >
                            </div>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex">
              <button type="button" class="saveButton" @click="$refs.fileInput.click()">
                Attach File
              </button>
              <button
                type="submit"
                class="browse"
                @click="sendEmailForSignOff"
                data-bs-dismiss="modal"
              >
                Send
              </button>
              <button type="button" class="cancelButton" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    estimateIndex: {
      type: Number,
      required: true,
    },
    signOffDocumentId: {
      type: String,
      required: true,
    },
    SalesOrderId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      toMail: null,
      projectData: {},
      fetchedSalesOrderData: {},
      fetchedEstimateData: {},
      routeParams: {
        clientName: "",
        propertyName: "",
        contactNo: "",
        salesPerson: "",
        email: "",
        estimateId: "",
        date: "",
        address: "",
        customerNote: "",
        status: "",
        widthUnitType: "",
        heightUnitType: "",
      },
      salesOrderId: this.SalesOrderId,
      EstimateId: this.estimateIndex,
      SignOffDocumentId: this.signOffDocumentId,
      emailFrom: "",
      emailTo: [],
      emailCC: [],
      emailSubject: "",
      emailBody: "",
      attachedFiles: [],
      attachmentIds: [],
    };
  },
  mounted() {
    this.featchCompanyData();
    if (this.SalesOrderId) {
      this.fetchSalesData();
    }
    if (this.estimateIndex) {
      this.fetchEstimateData();
    }
    if (this.signOffDocumentId) {
      this.fetchsignOffDocumentIdData();
    }
  },
  watch: {
    salesOrderId(newVal) {
      this.salesOrderId = newVal;
      this.fetchSalesData();
    },
    EstimateId(newVal) {
      this.EstimateId = newVal;
      this.fetchEstimateData();
    },
    signOffDocumentId(newVal) {
      this.SignOffDocumentId = newVal;
      this.fetchsignOffDocumentIdData();
    },
  },
  computed: {
    totalAttachmentSize() {
      return this.attachedFiles.reduce((sum, file) => sum + file.size, 0);
    },
  },
  methods: {
    featchCompanyData() {
      axios
        .get(`${this.$apiEndPoint}/company/${this.$LocalUser.user.companyId}`)
        .then((response) => {
          this.toMail = response.data.toMail;
        
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    async fetchsignOffDocumentIdData() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/designFiles/getById/${this.SignOffDocumentId}`
        );
        this.projectData = response.data;
        this.emailTo = response.data["designProjects.customers.email"];
        this.featchAttchmentById();
      } catch (error) {
        this.$handleError(error);
      }
    },
    featchAttchmentById() {
      const PayLoad = {
        awsFileName: this.projectData["files.awsFiles.awsFileName"],
        fileCategory: "signOffDocument",
        moduleName: "design",
        fileName: this.projectData["files.awsFiles.fileName"],
      };
      

      axios
        .post(`${this.$apiEndPoint}/company/getFileSignedURL/`, PayLoad)
        .then((response) => {
          const attachment = {
            id: response.data.id,
            name: response.data.fileName,
            Size: response.data.fileSize,
          };
          // Push the new attachment to attachedFiles array
          this.attachedFiles.push(attachment);
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    handleFileUpload(event) {
      const files = event.target.files;

      const maxFileSize = 15 * 1024 * 1024; // 15 MB
      // Calculate total size of existing files
      let totalSize = this.attachedFiles.reduce((sum, file) => sum + file.size, 0);
      for (let file of files) {
        // Check if adding this file would exceed the total size limit
        if (totalSize + file.size > maxFileSize) {
          alert("Total attachment size should not exceed 15 MB.");
          continue;
        }

        // Update total size
        totalSize += file.size;
        // Prepare payload for obtaining signed URL
        const payload = {
          fileName: file.name,
          fileSize: file.size,
          contentType: file.type,
          moduleName: "sales",
          fileCategory: "estimateAttachments",
          entityId: this.estimateIndex,
          entityName: "estimate",
        };

        axios
          .post(`${this.$apiEndPoint}/company/putFileSignedURL`, payload)
          .then((response) => {
            const signedUrl = response.data.s3URL;
            const fileId = response.data.id; // Get the ID from the response
            const fileName = response.data.fileName; // Get the filename from the response
            const fileSize = response.data.fileSize;

            // Use XMLHttpRequest to upload file to signed URL
            const xhr = new XMLHttpRequest();
            xhr.open("PUT", signedUrl, true);
            xhr.setRequestHeader("Content-Type", file.type);

            xhr.onreadystatechange = () => {
              if (xhr.readyState === 4) {
                if (xhr.status === 200) {
             
                  this.attachedFiles.push({
                    name: fileName,
                    size: fileSize,
                    id: fileId,
                  });
                }
              }
            };

            xhr.send(file);
          })
          .catch(() => {});
      }
    },

    async sendEmailForSignOff() {
      try {
        // Ensure emailCC is a string and then split it by commas
        const ccEmails =
          typeof this.emailCC === "string"
            ? this.emailCC.split(",").map((email) => email.trim())
            : [];

        // Convert emailTo to an array if it's not already an array
        const toEmails = Array.isArray(this.emailTo) ? this.emailTo : [this.emailTo];

        const requestBody = {
          customerEmailId: toEmails, // Use the toEmails array
          additionalEmailId: ccEmails, // Use the split email addresses
          signOffDocumentId: this.signOffDocumentId,
          attachmentIds: this.attachedFiles.map((file) => file.id), // Use the file IDs from the attachedFiles array
          subject: this.emailSubject,
          body: this.emailBody,
        };
        
        await axios.post(`${this.$apiEndPoint}/designFiles/mailSignOffDoc`, requestBody);
        this.$toast(`mail sent successfully.`, "success");
     
        this.emailFrom = "";
        this.emailTo = [];
        this.emailCC = [];
        this.emailSubject = "";
        this.emailBody = "";
        this.attachedFiles = [];
        if (this.projectData.status === "Verified") {
          this.changeStatusSignOffDocument();
        }
      } catch (error) {
        this.$handleError(error);
      }
    },
    async sendEmail() {
      try {
        // Ensure emailCC is a string and then split it by commas
        const ccEmails =
          typeof this.emailCC === "string"
            ? this.emailCC.split(",").map((email) => email.trim())
            : [];

        // Convert emailTo to an array if it's not already an array
        const toEmails = Array.isArray(this.emailTo) ? this.emailTo : [this.emailTo];

        const requestBody = {
          customerEmailId: toEmails, // Use the toEmails array
          additionalEmailId: ccEmails, // Use the split email addresses
          estimateId: this.estimateIndex,
          attachmentIds: this.attachedFiles.map((file) => file.id), // Use the file IDs from the attachedFiles array
          subject: this.emailSubject,
          body: this.emailBody,
        };

        await axios.post(`${this.$apiEndPoint}/estimateEmails`, requestBody);
        this.emailFrom = "";
        this.emailTo = [];
        this.emailCC = [];
        this.emailSubject = [];
        this.emailBody = "";
        this.attachedFiles = [];
        this.$toast(`mail sent successfully.`, "success");
        if (this.routeParams.status === "Finalized") {
          console.log(this.routeParams.status);
          this.changeStatusEstimate();
        }
      } catch (error) {
        this.$handleError(error);
      }
    },

    async changeStatusSignOffDocument() {
      try {
        // Call your API to change the status here
        await axios.put(`${this.$apiEndPoint}/designFiles/${this.signOffDocumentId}`, {
          status: "Sent",
        });
        this.$emit("fetchData");

        // Optionally, fetch data or perform any other actions after status change
      } catch (error) {
        this.$handleError(error);
      }
      this.$emit("fetchData");
    },
    async changeStatusEstimate() {
      try {
        // Call your API to change the status here
        await axios.put(`${this.$apiEndPoint}/estimates/${this.EstimateId}`, {
          status: "Sent",
        });
        this.$emit("fetchData");
      } catch (error) {
        this.$handleError(error);
      }
    },

    async fetchEstimateData() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/estimates/${this.estimateIndex}`
        );
        this.fetchedEstimateData = response.data;
        this.routeParams.estimateId = this.fetchedEstimateData.estimateId;
        this.routeParams.propertyName = this.fetchedEstimateData["property.name"];
        this.routeParams.propertyId = this.fetchedEstimateData["property.id"];
        this.routeParams.date = this.fetchedEstimateData.date || "";
        this.routeParams.status = this.fetchedEstimateData.status;
        if (this.fetchedEstimateData["customers.name"]) {
          this.routeParams.clientName = this.fetchedEstimateData["customers.name"];
          this.routeParams.contactNo =
            this.fetchedEstimateData["customers.contactNo"] || "";
          this.routeParams.email = this.fetchedEstimateData["customers.email"];
          this.emailTo = this.fetchedEstimateData["customers.email"];
        } else {
          this.routeParams.clientName = this.fetchedEstimateData["leads.name"];
          this.routeParams.contactNo = this.fetchedEstimateData["leads.contactNo"] || "";
          this.routeParams.email = this.fetchedEstimateData["leads.email"];
          this.emailTo = this.fetchedEstimateData["leads.email"];
        }
        if (this.fetchedEstimateData["addresses.address"]) {
          this.routeParams.address = `${this.fetchedEstimateData["addresses.address"]}, ${this.fetchedEstimateData["addresses.city"]}, ${this.fetchedEstimateData["addresses.state"]}`;
        } else {
          this.routeParams.address = `${this.fetchedEstimateData["leadAddresses.address"]} ${this.fetchedEstimateData["leadAddresses.city"]} ${this.fetchedEstimateData["leadAddresses.state"]}`;
        }
        this.routeParams.leadId = this.fetchedEstimateData.leadId;
      } catch (error) {
        this.$handleError(error);
      }
    },
    removeFile(index) {
      this.attachedFiles.splice(index, 1);
    },
    formatFileSize(size) {
      if (size === 0) return "0 Bytes";
      const k = 1024;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      const i = Math.floor(Math.log(size) / Math.log(k));
      return parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
    },

    handleFileUploadsignOffDocument(event) {
      const files = event.target.files;

      const maxFileSize = 15 * 1024 * 1024; // 15 MB
      // Calculate total size of existing files
      let totalSize = this.attachedFiles.reduce((sum, file) => sum + file.size, 0);
      for (let file of files) {
        // Check if adding this file would exceed the total size limit
        if (totalSize + file.size > maxFileSize) {
          alert("Total attachment size should not exceed 15 MB.");
          continue;
        }

        // Prepare payload for obtaining signed URL
        const payload = {
          fileName: file.name,
          fileSize: file.size,
          contentType: file.type,
          moduleName: "design",
          fileCategory: "signOffDocument",
          entityId: this.signOffDocumentId,
          entityName: "signOffDocument",
        };

        axios
          .post(`${this.$apiEndPoint}/company/putFileSignedURL`, payload)
          .then((response) => {
            const signedUrl = response.data.s3URL;
            const fileId = response.data.id; // Get the ID from the response
            const fileName = response.data.fileName; // Get the filename from the response

            // Use XMLHttpRequest to upload file to signed URL
            const xhr = new XMLHttpRequest();
            xhr.open("PUT", signedUrl, true);
            xhr.setRequestHeader("Content-Type", file.type);

            xhr.onreadystatechange = () => {
              if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                
                  this.attachedFiles.push({
                    name: fileName,
                    size: file.size,
                    id: fileId,
                  });
                }
              }
            };

            xhr.send(file);
          })
          .catch(() => {});
      }
    },
    handleFileUploadSalesOrder(event) {
      const files = event.target.files;

      const maxFileSize = 15 * 1024 * 1024; // 15 MB
      // Calculate total size of existing files
      let totalSize = this.attachedFiles.reduce((sum, file) => sum + file.size, 0);
      for (let file of files) {
        // Check if adding this file would exceed the total size limit
        if (totalSize + file.size > maxFileSize) {
          alert("Total attachment size should not exceed 15 MB.");
          continue;
        }

        // Prepare payload for obtaining signed URL
        const payload = {
          fileName: file.name,
          fileSize: file.size,
          contentType: file.type,
          moduleName: "sales",
          fileCategory: "salesOrderAttachments",
          entityId: this.salesOrderId,
          entityName: "salesOrder",
        };

        axios
          .post(`${this.$apiEndPoint}/company/putFileSignedURL`, payload)
          .then((response) => {
            const signedUrl = response.data.s3URL;
            const fileId = response.data.id; // Get the ID from the response
            const fileName = response.data.fileName; // Get the filename from the response
            // Use XMLHttpRequest to upload file to signed URL
            const xhr = new XMLHttpRequest();
            xhr.open("PUT", signedUrl, true);
            xhr.setRequestHeader("Content-Type", file.type);

            xhr.onreadystatechange = () => {
              if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                
                  this.attachedFiles.push({
                    name: fileName,
                    size: file.size,
                    id: fileId,
                  });
                }
              }
            };

            xhr.send(file);
          })
          .catch(() => {});
      }
    },
    async sendSalesOrderEmail() {
      try {
        const ccEmails =
          typeof this.emailCC === "string"
            ? this.emailCC.split(",").map((email) => email.trim())
            : [];
        const toEmails = Array.isArray(this.emailTo) ? this.emailTo : [this.emailTo];
        // Make a POST request to update email recipients
        const requestBody = {
          salesOrderId: this.salesOrderId,
          customerEmailId: toEmails,
          additionalEmailId: ccEmails,
          attachmentIds: this.attachedFiles.map((file) => file.id), // Use the file IDs from the attachedFiles arr
          subject: this.emailSubject,
          body: this.emailBody,
        };

        const response = await axios.post(
          `${this.$apiEndPoint}/salesOrder/sendSalesOrder`,
          requestBody
        );
        this.emailFrom = "";
        this.emailTo = [];
        this.emailCC = [];
        this.emailSubject = [];
        this.emailBody = "";
        this.attachedFiles = [];
        if (response.data) {
          await axios.put(`${this.$apiEndPoint}/salesOrder/status/${this.SalesOrderId}`, {
            status: "Sent",
          });
        }
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchSalesData() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/salesOrder/${this.salesOrderId} `
        );
        this.fetchedSalesOrderData = response.data;
        this.emailTo = this.fetchedSalesOrderData["billingAddress.email"];

        if (this.fetchedSalesOrderData?.lineItems) {
          this.items = this.fetchedSalesOrderData.lineItems.map((item) => ({
            header: item.header,
            rows: item.Rows.map((row) => ({
              ...row,
              GSTpercentage: row.GSTpercentage, // Assign GSTpercentage directly from response
            })),
          }));
        }
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>

<style scoped>
input:focus,
textarea:focus,
select:focus {
  outline: none;
  /* Remove default outline */
  box-shadow: none;
  /* Add a custom focus shadow */
}

label {
  font-weight: 500;
  color: black;
  font-size: 14px;
}

input,
textarea,
select {
  border: 1.5px solid #000000;
  outline: none !important;
  font-size: 14px;
}

.emailContent {
  border: 2px solid #000000;
  border-radius: 10px;
  margin: 2% 10%;
  height: 100%;
  overflow-y: scroll;
  padding: 5px;
}
</style>
