<template>
  <CreateEmployeeModal
    :employeeNumber="selectedEmployeeId"
    @featchEmployeeList="fetchData"
  />
  <Header />
  <div class="content-Container">
    <Nav />
    <div class="containerChild">
      <div class="FormatTable" v-if="showUnclaimedList">
        <div class="row headerOfTable d-flex">
          <div class="col-md-12 d-flex align-content-center">
            <div class="col-md-6 d-flex align-content-center" style="margin-left: 1%">
              <div class="col-md-2 mt-2 text-nowrap">
                <h5 class="mainHeading">Employees</h5>
              </div>
              <div class="col-md-2">
                <img
                  src="../../assets/icons/addRows.png"
                  alt=""
                  class="btn"
                  data-bs-toggle="modal"
                  data-bs-target="#CreateEmployee"
                  data-bs-whatever="@mdo"
                  id="addicon"
                  @click="openCreateEmployeeModal(null)"
                />
              </div>
            </div>
            <div class="col-md-6 d-flex align-items-center">
              <div class="col-md-3 ImportExport" style="visibility: hidden">
                <div class="row">
                  <div class="col-md-4 tableTextEvenRow">Import</div>
                  <div class="col-md-3 tableTextEvenRow">
                    <img src="../../assets/icons/import.png" alt="" id="ImportIcon" />
                  </div>
                </div>
              </div>
              <div class="col-md-3 ImportExport">
                <div class="row">
                  <div class="col-md-4 tableTextEvenRow">Export</div>
                  <div class="col-md-3 tableTextEvenRow">
                    <img src="../../assets/icons/export.png" alt="" id="ImportIcon" />
                  </div>
                </div>
              </div>

              <div class="col-md-1" style="visibility: hidden">
                <img
                  src="../../assets/icons/archive.png"
                  alt=""
                  id="ArchiveIcon"
                  @click="handleArchiveClick"
                />
              </div>
              <div class="col-md-4 searchboxOfTable">
                <div class="search-box">
                  <img
                    src="../../assets/icons/search.png"
                    alt="Search Icon"
                    id="SerchIconOfTable"
                  />
                  <input
                    type="text"
                    name="search"
                    id=""
                    v-model="searchString"
                    @input="searchLeads"
                  />
                </div>
                <span>
                  <img
                    src="../../assets/icons/filter.png"
                    alt="Search Icon"
                    id="FilterIconOfTable"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="table-wrapper">
          <table class="tableFormat">
            <caption class="d-none"></caption>
            <thead>
              <tr>
                <th class="tableHeaderHeading text-center">Employee Name</th>
                <th class="tableHeaderHeading text-center">Employee ID</th>
                <th class="tableHeaderHeading text-left">Email ID</th>
                <th class="tableHeaderHeading text-center">Phone No</th>
                <th class="tableHeaderHeading text-left">Employee Roles</th>
                <th class="tableHeaderHeading text-left">Hire date</th>
                <th class="tableHeaderHeading text-right">&nbsp; Status</th>

                <th class="tableHeaderHeading text-right" v-if="$isManager('Admin')">
                  &nbsp; &nbsp; &nbsp; Action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, index) in displayedData" :key="index" class="bodyText">
                <td style="cursor: pointer" @click="goToEmployeeProfile(data)">
                  <span style="margin-right: 20px">
                    <img
                      v-if="data.signedUrl"
                      :src="data.signedUrl"
                      style="width: 30px; height: 30px; border-radius: 50%"
                      alt=""
                    />
                    <img
                      v-else
                      class="profile-photo"
                      src="../../assets/icons/profile.png"
                      alt="Profile Photo"
                      style="width: 30px; height: 30px; border-radius: 50%"
                    />
                  </span>
                  {{ data.firstName }}&nbsp;{{ data.lastName }}
                </td>
                <td class="text-center">{{ data.employeeNumber }}</td>
                <td class="text-left">{{ data.employeeEmailId }}</td>
                <td class="text-center">{{ data.mobileNumber }}</td>
                <td class="text-left">
                  <div
                    v-for="role in data.EmployeeRoles"
                    :key="role.id"
                    class="team-role-item"
                  >
                    {{ role.team }}&nbsp; [{{ role.role }}]
                  </div>
                </td>

                <td class="text-right">{{ formatDate(data.dateOfHire) }}</td>
                <td class="text-left">
                  <span>
                    <img
                      v-if="data.status === 'Active'"
                      src="../../assets/icons/completed.png"
                      alt=""
                      style="width: 15px; height: 15px; margin-bottom: 5px"
                    />
                    <img
                      v-else
                      src="../../assets/icons/onHold.png"
                      alt=""
                      style="width: 15px; height: 15px; margin-bottom: 5px"
                    />
                  </span>
                  <span
                    class="ms-2 status"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {{ data.status }}
                  </span>
                  <div
                    v-if="$isManager('Admin')"
                    class="dropdown-menu statusdropdown"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <button
                      class="dropdown-item"
                      @click="changeStatus(data.id, 'Active')"
                    >
                      Active
                    </button>
                    <button
                      class="dropdown-item"
                      @click="changeStatus(data.id, 'Inactive')"
                    >
                      Inactive
                    </button>
                  </div>
                </td>

                <td v-if="$isManager('Admin')">
                  <div class="ActionImage">
                    <span
                      data-bs-toggle="modal"
                      data-bs-target="#CreateEmployee"
                      @click="openCreateEmployeeModal(data.id)"
                    >
                      <img
                        src="../../assets/icons/yellowEdit.png"
                        data-toggle="tooltip"
                        data-placement="top-start"
                        alt="Edit"
                        title="Edit"
                      />
                    </span>
                    <span @click="confirmDelete(data, index)">
                      <img
                        src="../../assets/icons/redDelete.png"
                        data-toggle="tooltip"
                        data-placement="top-start"
                        title="Delete"
                        alt="Delete"
                      />
                    </span>
                    <span>
                      <img
                        src="../../assets/icons/invite.png"
                        @click="ReInviteEmployee(data.employeeEmailId)"
                        data-toggle="tooltip"
                        data-placement="left"
                        title="ReInvite"
                        alt="ReInvite"
                      />
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="paginationOfTable">
          <div class="pageControlsTable">
            <img
              src="../../assets/icons/blueLeftarrow.png"
              @click="goToPreviousPage"
              :disabled="!hasPreviousPage"
              alt="Prev"
            />
            <div class="page-numbers">
              <span
                v-for="pageNumber in pagination"
                :key="pageNumber"
                :class="{
                  'page-number': true,
                  'current-page': pageNumber === currentPage,
                }"
                @click="goToPage(pageNumber)"
                >{{ pageNumber }}</span
              >
            </div>

            <img
              src="../../assets/icons/blueRightarrow.png"
              @click="goToNextPage"
              :disabled="!hasNextPage"
              alt="Next"
            />
          </div>

          <div class="entries-info">
            <span class="entries-text">Entries: {{ displayedData.length }} Rows</span>
          </div>
        </div>
      </div>
    </div>
    <div class="modal d-block mt-4" v-if="showModal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Confirm Deletion</h5>

            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="showModal = false"
            ></button>
          </div>
          <div class="modal-body">Are you sure you want to delete this employee?</div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="showModal = false">
              Cancel
            </button>
            <button type="button" class="btn btn-danger" @click="deleteEmployee">
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreateEmployeeModal from "../Modal/CreateEmployeeModal.vue";
import Header from "../Header.vue";
import Nav from "../Nav.vue";
import axios from "axios";
let pageFlag = true;
export default {
  name: "EmployeeList",
  components: {
    Header,
    Nav,
    CreateEmployeeModal,
  },
  data() {
    return {
      totalCount: null,
      totalArchiveCount: null,
      EmployeeProfile: null,
      searchString: "",
      pageSize: 50,
      currentPage: 1,
      showUnclaimedList: true,
      error: null,
      TableData: [],
      ArchiveData: [],
      selectedTeamId: null,
      showModal: false,
      employeeToDelete: null,
      selectedEmployeeId: null,
    };
  },
  computed: {
    hasPreviousPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < Math.ceil(this.totalCount / this.pageSize);
    },
    hasArchivePreviousPage() {
      return this.currentPage > 1;
    },
    hasArchiveNextPage() {
      return this.currentPage < Math.ceil(this.totalArchiveCount / this.pageSize);
    },

    pagination() {
      const pageCount = Math.ceil(this.totalCount / this.pageSize);
      const maxVisiblePages = 5;
      let pages = [];

      if (pageCount <= maxVisiblePages) {
        pages = Array.from({ length: pageCount }, (_, index) => index + 1);
      } else {
        const startPage = Math.max(2, this.currentPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(
          pageCount - 1,
          this.currentPage + Math.floor(maxVisiblePages / 2)
        );

        pages = [1];

        if (startPage > 2) {
          pages.push("...");
        }

        pages.push(
          ...Array.from(
            { length: endPage - startPage + 1 },
            (_, index) => startPage + index
          )
        );

        if (endPage < pageCount - 1) {
          pages.push("...");
        }

        pages.push(pageCount);
      }

      return pages;
    },

    paginationArchive() {
      const pageCount = Math.ceil(this.totalArchiveCount / this.pageSize);
      const maxVisiblePages = 5;
      let pages = [];

      if (pageCount <= maxVisiblePages) {
        pages = Array.from({ length: pageCount }, (_, index) => index + 1);
      } else {
        const startPage = Math.max(2, this.currentPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(
          pageCount - 1,
          this.currentPage + Math.floor(maxVisiblePages / 2)
        );

        pages = [1];

        if (startPage > 2) {
          pages.push("...");
        }

        pages.push(
          ...Array.from(
            { length: endPage - startPage + 1 },
            (_, index) => startPage + index
          )
        );

        if (endPage < pageCount - 1) {
          pages.push("...");
        }

        pages.push(pageCount);
      }

      return pages;
    },
    displayedData() {
      return this.TableData;
    },
    displayedArchiveData() {
      return this.ArchiveData;
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async ReInviteEmployee(employeeEmailId) {
      try {
        await axios.post(`${this.$apiEndPoint}/employee/mailInvitation`, {
          employeeEmailId: employeeEmailId, // Pass the employee's email as part of the request body
        });
        this.$toast("Invitation sent successfully:", "success");
      } catch (error) {
        this.$handleError(error);
      }
    },
    goToEmployeeProfile(employee) {
      const encodedId = btoa(employee.id.toString());
      this.$router.push({
        name: "EmployeeProfile",
        params: {
          employeeId: encodedId,
        },
      });
    },
    async changeStatus(EmployeeId, status) {
      try {
        await axios.put(`${this.$apiEndPoint}/employee/changeStatus/${EmployeeId}`, {
          status,
        });
        // Assuming you have a method to fetch source after status change
        this.fetchData();
      } catch (error) {
        this.$handleError(error);
      }
    },
    openCreateEmployeeModal(employeeId) {
      this.selectedEmployeeId = employeeId;
    },

    /*  findEmployeeRole(employeeRoles, type) {
    const role = employeeRoles.find(role => role.team && type === 'team');
    const team = employeeRoles.find(role => role.role && type === 'role');
    return type === 'team' ? (role ? role.team : '') : (team ? team.role : '');
  }, */
    getAllTeams(employeeRoles) {
      if (!employeeRoles || employeeRoles.length === 0) return "";
      return employeeRoles.map((role) => role.team).join(", ");
    },
    getAllRoles(employeeRoles) {
      if (!employeeRoles || employeeRoles.length === 0) return "";
      return employeeRoles.map((role) => role.role).join(", ");
    },
    goToPreviousPage() {
      if (this.hasPreviousPage) {
        this.changePage(-1); // Go to the previous page
      }
    },
    goToNextPage() {
      if (this.hasNextPage) {
        this.changePage(1); // Go to the next page
      }
    },
    goToArchivePreviousPage() {
      if (this.hasArchivePreviousPage) {
        this.changePage(-1); // Go to the previous page
      }
    },
    goToArchiveNextPage() {
      if (this.hasArchiveNextPage) {
        this.changePage(1); // Go to the next page
      }
    },
    async fetchData() {
      let searchData = {
        searchString: this.searchString,
      };

      const hasSearchData = searchData.searchString;

      if (hasSearchData && pageFlag === true) {
        this.currentPage = 1;
        pageFlag = false;
      }
      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/employee/allEmployees/${this.currentPage}`,
          searchData
        );
        this.totalCount = response.data.count;
        this.TableData = response.data.rows;
      } catch (error) {
        this.$handleError(error);
      }
    },
    DeleteLead(data) {
      axios
        .delete(`${this.$apiEndPoint}/estimates/deleteEstimates`, {
          data: { id: [data.id] },
        })
        // Pass an array with a single ID to match the API's expected format
        .then(() => {
          this.fetchData();
          this.fetchArchiveData();
          this.leadDropdownStates[data.id] = false; // Hide dropdown after action
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    async searchLeads() {
      pageFlag = true;
      this.fetchData();
    },

    confirmDelete(employee) {
      this.employeeToDelete = employee;

      this.showModal = true; // Show the modal
    },
    deleteEmployee() {
      if (this.employeeToDelete) {
        this.showModal = false; // Show the modal
        const employeeId = this.employeeToDelete.id;

        // Find the index of the employee to delete in displayedData
        const index = this.displayedData.findIndex((emp) => emp.id === employeeId);
        if (index !== -1) {
          // Remove the employee from the displayedData array
          this.displayedData.splice(index, 1);
          axios
            .delete(`${this.$apiEndPoint}/employee/${employeeId}`)
            .then(() => {
              this.$toast("Employee deleted successfully", "success");
              this.fetchData(); // Refresh data after deletion
              this.showModal = false; // Hide the modal
            })
            .catch((error) => {
              this.$handleError(error);
            });
        } else {
          this.$toast("Employee not found in displayedData", "error");
        }
      }
    },

    //footer pagination
    goToPage(pageNumber) {
      this.selectAll = false;
      this.currentPage = pageNumber;
      this.fetchData();

      this.selectedCustomers = [];
    },
    changePage(delta) {
      this.selectAll = false;
      this.lastPages = this.currentPage;
      this.currentPage += delta;
      this.fetchData();

      this.selectedCustomers = [];
    },
    formatDate(dateString) {
      if (!dateString) return "";
      const isYMDFormat = dateString.includes("-");
      if (isYMDFormat) {
        const [year, month, day] = dateString.split("-");
        return `${day}-${month}-${year}`; // Format as dd-mm-yyyy
      } else {
        // Handle other formats (e.g., dd/mm/yyyy, mm/dd/yyyy)
        // Try to parse the date string to a Date object
        const parsedDate = new Date(dateString);
        if (isNaN(parsedDate.getTime())) {
          return dateString;
        } else {
          // Format the parsed date as dd-mm-yyyy
          const day = parsedDate.getDate().toString().padStart(2, "0");
          const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
          const year = parsedDate.getFullYear();
          return `${day}-${month}-${year}`;
        }
      }
    },
  },
};
</script>

<style scoped>
.tableHeaderHeading {
  font-size: 14px;
}
.ActionImage img {
  padding: 5px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.ActionImage img:hover {
  background-color: #ccc;
  border-radius: 50%;
}

/*
.team-role-container {
  display: flex;
flex-direction: column;
}

.team-role-badge {
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 0.9rem;
}
*/
.status:hover {
  outline: 1px solid #ccc;
  padding: 1px;
  cursor: pointer;
}
/* Style for modal content */
.modal-dialog {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.FormatTable {
  width: 100%;
  height: 100%;
}

.headerOfTable {
  width: 96%;
  padding: 0.5% 1%;
}

#addicon {
  width: 50px;
  height: 40px;
  margin: 5px 0 0 15px;
  outline: none;
}

#ImportIcon {
  width: 100%;
}

#ArchiveIcon {
  width: 30%;
}

#SerchIconOfTable {
  width: 6%;
  margin-right: 5%;
}

.search-box {
  border-bottom: 2px solid #000;
  padding-bottom: 3px;
  display: flex;
  align-items: center;
}

.searchboxOfTable {
  width: 100%;
  display: flex;
  align-items: center;
}

#FilterIconOfTable {
  width: 20%;
  height: 5%;
  margin-left: 10%;
}

input[type="text"] {
  border: none;
  outline: none;
  font-size: 16px;
  width: 100%;
}

.table-wrapper {
  max-height: 85%;
  min-height: 85%;
  overflow-y: auto;
  margin: 0% 1%;
}

.table-wrapper::-webkit-scrollbar {
  display: none;
}

/* Table styling */
.tableFormat {
  white-space: nowrap;
  max-height: 100%;
  width: 100%;
  overflow: scroll;
}

.tableFormat thead,
.tableFormat tbody {
  white-space: nowrap;
}

/* Sticky header */

.tableFormat thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

/* Scrollable body */
.tableFormat tbody {
  overflow: scroll;
}
/*
.tableFormat tbody  tr td{
 border: 1px solid #ccc;
}*/

.table-wrapper .tableFormat tbody tr:not(:first-child):nth-child(even) {
  background-color: #f8f8f8;
}

.dropdown-menu {
  z-index: 9999;
}

.tableFormat tbody tr td {
  padding: 0px 8px;
  height: auto;
}
.tableFormat thead tr th {
  padding: 5px 8px;
  height: auto;
  background-color: #bcbfc6;
}

.tableFormat tbody tr {
  height: 92px;
}

#blackThreeDots {
  width: 20px;
  height: 20px;
  margin-left: 30%;
  padding-bottom: 10%;
  position: relative;
  cursor: pointer;
}

#checkBox2 {
  width: 16px;
  height: 17px;
}

.paginationOfTable {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  position: fixed;
  bottom: 2%;
  padding: 0.5% 0;
  z-index: 999;

  border-radius: 0 0 15px 15px;
  background-color: #ffffff;
  width: calc(100% - 74px);
}

.entries-info {
  position: fixed;
  right: 2%;
  white-space: nowrap;
  font-size: 1rem;
}

.entries-info span {
  background-color: #f8f8f8;
  padding: 1%;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.9);
  /* Adjust the values as needed */
}

.pageControlsTable {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pageControlsTable img {
  margin: 0 5px;
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.pageControlsTable button:hover {
  background-color: #eee;
}

.page-numbers span {
  margin: 5px;
  cursor: pointer;

  /* Adjust color for page numbers */
}

.page-number {
  cursor: pointer;
  margin-right: 5px;
  font-size: 18px;

  color: #868686;
  font-weight: 500;
}

.current-page {
  font-size: 18px;

  color: #000000;
  font-weight: bolder;
}

.BoardHeader {
  display: flex;
  justify-content: space-between;
  /* Ensure items are spaced apart */
  align-items: center;
  /* Center items vertically */
  white-space: nowrap;
  /* Prevent wrapping */
}

.bolderHeadings {
  font-size: 16px;

  color: #000000;
  font-weight: bolder;
}

.tabledropdownEdits {
  position: absolute;
  display: inline-block;
  background-color: #ffffff;
  width: 150px;
  height: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin: 10px 20px;
  z-index: 9000;
  top: 60%;
}

.tabledropdownEdits p {
  padding: 4px 8px;
  text-decoration: none;
  display: flex;
  cursor: pointer;
  color: #000000;
}

.SigleLeadThings {
  position: absolute;
  display: inline-block;
  background-color: #ffffff;
  width: 150px;
  height: 240px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin: 10px 20px;
  z-index: 1000;
  top: 60%;
}

.SigleLeadThings p {
  padding: 4px 8px;
  text-decoration: none;
  display: flex;
  cursor: pointer;
  color: #000000;
}
.table-wrapper tbody tr td {
  /*border: 1px solid #edeae9;*/
}

@media (max-width: 768px) {
  #addicon {
    width: 25px;
    height: 25px;
    margin-left: 10px;
  }

  .table-wrapper {
    max-height: 90%;
    /* Adjust as needed */
    overflow-y: auto;
  }

  .headerOfTable {
    width: 100%;
    overflow: scroll;
  }

  #ArchiveIcon {
    width: 30%;
  }

  .ImportExport {
    display: none;
  }
  .paginationOfTable {
    bottom: 0.5%;
  }
}
</style>
