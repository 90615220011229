<template>
  <div
    class="modal fade my-4"
    id="CreateEmployee"
    tabindex="-1"
    aria-labelledby="CreateEmployeeLabel"
    aria-hidden="true"
  >
    <div class="createLeadForm">
      <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <!-- Change modal-dialog class to modal-lg for 50% width -->
        <div class="modal-content">
          <div class="modal-header" style="background-color: #b1c5df">
            <h5 class="modal-title">New Employee</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <!-- Profile photo upload -->
            <div class="ProfilePhoto">
              <label
                for="profilePhoto"
                class="form-label position-absolute end-0"
                style="z-index: 99999; margin-top: 45%"
              >
                <img
                  src="../../assets/icons/yellowEdit.png"
                  alt="Edit Profile"
                  class="ms-2"
                  style="width: 20px; cursor: pointer"
                />
              </label>
              <!-- Display the uploaded profile image if available -->
              <img
                v-if="profileImage"
                :src="profileImage"
                alt="Profile Photo"
                class="uploaded-photo"
              />

              <input
                type="file"
                id="profilePhoto"
                @change="handleProfilePhotoChange"
                accept="image/*"
              />
            </div>

            <!-- General Information form -->
            <h6 class="mt-2 active-SubHeading">General Information</h6>
            <form>
              <div class="row">
                <!-- General Information fields -->
                <!-- Employee ID -->
                <div class="mb-3 col-md-4">
                  <label for="employeeId" class="form-label">Employee ID</label>
                  <input
                    v-model="employeeData.employeeNumber"
                    type="text"
                    class="form-control"
                    id="employeeId"
                    placeholder="EMP####"
                    readonly
                  />
                </div>
                <!-- First Name -->
                <div class="mb-3 col-md-4">
                  <label for="firstName" class="form-label">First Name</label>
                  <input
                    v-model="employeeData.firstName"
                    type="text"
                    class="form-control"
                    id="firstName"
                    placeholder="First Name"
                    v-capitalize-first-letter
                  />
                </div>
                <!-- Last Name -->
                <div class="mb-3 col-md-4">
                  <label for="lastName" class="form-label">Last Name</label>
                  <input
                    v-model="employeeData.lastName"
                    type="text"
                    class="form-control"
                    id="lastName"
                    placeholder="Last Name"
                    v-capitalize-first-letter
                  />
                </div>
                <!-- Phone Number -->
                <div class="mb-3 col-md-4">
                  <label for="phoneNumber" class="form-label">Mobile Number</label>
                  <input
                    v-model="employeeData.mobileNumber"
                    type="text"
                    class="form-control"
                    id="phoneNumber"
                    placeholder="Mobile Number"
                    pattern="[0-9]{10}"
                  />
                  <small
                    v-if="
                      employeeData.mobileNumber &&
                      !isValidMobileNumber(employeeData.mobileNumber)
                    "
                    class="text-danger"
                    >Please enter a valid 10-digit mobile number.</small
                  >
                </div>
                <!-- Email Address -->

                <div class="mb-3 col-md-4">
                  <label for="emailId" class="form-label">Email (Official)</label>
                  <input
                    v-model="employeeData.employeeEmailId"
                    type="text"
                    class="form-control"
                    id="companyMaildId"
                    placeholder="Email Id"
                  />
                  <small
                    v-if="
                      employeeData.employeeEmailId &&
                      !isValidEmail(employeeData.employeeEmailId)
                    "
                    class="text-danger"
                    >Please enter a valid email address.</small
                  >
                </div>
                <!-- Date of Birth -->
                <div class="mb-3 col-md-4">
                  <label for="dateOfBirth" class="form-label">Date of Birth</label>
                  <input
                    v-model="employeeData.dob"
                    type="date"
                    class="form-control"
                    id="dateOfBirth"
                  />
                </div>
                <!-- Govt Id -->
                <div class="mb-3 col-md-4">
                  <label for="govtId" class="form-label">Govt Id</label>
                  <input
                    v-model="employeeData.govtIdNo"
                    type="text"
                    class="form-control"
                    id="govtId"
                    placeholder="Govt Id"
                  />
                </div>
                <!-- Gender -->
                <div class="mb-3 col-md-4">
                  <label for="gender" class="form-label">Gender</label>
                  <select
                    class="form-control inputBg form-select"
                    v-model="employeeData.gender"
                    placeholder="Select"
                  >
                    <option value="" selected>Select</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <!-- Skill -->
                <div class="mb-3 col-md-4">
                  <label for="skill" class="form-label">Skill</label>
                  <input
                    v-model="employeeData.skills"
                    type="text"
                    class="form-control"
                    id="skill"
                    placeholder="Skill"
                  />
                </div>
                <!-- Local Address -->
                <div class="mb-3 col-md-6">
                  <label for="localAddress" class="form-label">Local Address</label>
                  <textarea
                    v-model="residentialAddress.address"
                    class="form-control"
                    id="localAddress"
                    cols="30"
                    rows="4"
                    placeholder="Local Address"
                  ></textarea>
                </div>
                <!-- Permanent Address -->
                <div class="mb-3 col-md-6">
                  <label for="permanentAddress" class="form-label"
                    >Permanent Address</label
                  >
                  <textarea
                    v-model="permanentAddress.address"
                    class="form-control"
                    id="permanentAddress"
                    rows="4"
                    placeholder="Permanent Address"
                  ></textarea>
                </div>
              </div>
            </form>
            <!-- Bank Information form -->
            <h6 class="mt-2 active-SubHeading">Employee Information</h6>
            <form>
              <div class="row">
                <!-- Date Of Hire and Officially Mail ID -->
                <div class="col-md-4">
                  <div class="mb-3">
                    <label for="dateOfHire" class="form-label">Date Of Hire</label>
                    <input
                      type="date"
                      class="form-control"
                      id="dateOfHire"
                      v-model="employeeData.dateOfHire"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="mb-3">
                    <label for="department" class="form-label">
                      Department
                      <img
                        src="../../assets/icons/downArrow.png"
                        alt=""
                        style="width: 14px; height: 8px; cursor: pointer"
                        @click="toggleDropdown"
                      />
                    </label>
                    <transition name="fade">
                      <div
                        v-if="isDropdownOpen"
                        class="modal-overlay"
                        @click="toggleDropdown"
                      ></div>
                    </transition>

                    <div v-show="isDropdownOpen" class="dropdown-menu">
                      <!-- Loop through departments -->
                      <div v-for="dept in departments" :key="dept.name">
                        <div
                          class="form-check"
                          style="background-color: #b1c5df; color: white"
                        >
                          <!-- Checkbox for department -->
                          <input
                            type="checkbox"
                            class="form-check-input p-0 mt-1"
                            :id="'departmentCheckbox_' + dept.name"
                            v-model="selectedDepartments"
                            :value="dept.name"
                          />
                          <label
                            class="form-check-label"
                            :for="'departmentCheckbox_' + dept.name"
                          >
                            {{ dept.name }}
                          </label>
                        </div>
                        <!-- Display roles for the selected department -->
                        <div v-if="isSelectedDepartment(dept)">
                          <ul>
                            <li v-for="role in dept.roles" :key="role">
                              <!-- Radio buttons for roles -->
                              <input
                                type="radio"
                                class="form-check-input mt-1"
                                :id="'roleRadio_' + dept.name + '_' + role"
                                :name="'roleRadio_' + dept.name"
                                v-model="selectedRoles[dept.name]"
                                :value="role"
                              />
                              <label
                                :for="'roleRadio_' + dept.name + '_' + role"
                                class="ms-2"
                                >{{ role }}</label
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div
                      class="flex-column-container ms-2"
                      style="background-color: #f8f8f8"
                      v-if="selectedDepartments.length > 0"
                    >
                      <div v-for="(dept, index) in selectedDepartments" :key="index">
                        <span>{{ dept }}</span>
                      </div>
                    </div>
                    <div
                      class="flex-column-container ms-2"
                      style="background-color: #f8f8f8"
                      v-else
                    >
                      <div
                        v-for="role in employeeData.employeeRoles"
                        :key="role.id"
                        class="team-role-item"
                      >
                        {{ role.team }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Display Selected Employee Roles -->
                <div class="col-md-4">
                  <div class="mb-3">
                    <label for="employeeRoles" class="form-label">Designations</label>
                    <div
                      class="flex-column-container ms-2"
                      style="background-color: #f8f8f8"
                      v-if="selectedDepartments.length > 0"
                    >
                      <div v-for="(role, index) in selectedEmployeeRoles" :key="index">
                        <span>{{ role }}</span>
                      </div>
                    </div>
                    <div
                      class="flex-column-container ms-2"
                      style="background-color: #f8f8f8"
                      v-else
                    >
                      <div
                        v-for="role in employeeData.employeeRoles"
                        :key="role.id"
                        class="team-role-item"
                      >
                        {{ role.role }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <h6 class="mt-2 active-SubHeading">Bank Information</h6>
            <form>
              <div class="row">
                <div class="mb-3 col-md-4">
                  <label for="bankName">Beneficiary Name</label>
                  <input
                    type="text"
                    id="Beneficiary"
                    name="Beneficiary"
                    placeholder="Beneficiary"
                    class="form-control"
                    v-model="bankDetails.beneficiaryName"
                  />
                </div>
                <div class="mb-3 col-md-4">
                  <label for="bankName">Bank Name</label>
                  <input
                    type="text"
                    id="bankName"
                    name="bankName"
                    class="form-control"
                    placeholder="Bank Name"
                    v-model="bankDetails.bankName"
                  />
                </div>

                <div class="mb-3 col-md-4">
                  <label for="accountNumber">Account Number:</label>
                  <input
                    type="tele"
                    id="accountNumber"
                    name="accountNumber"
                    class="form-control"
                    placeholder="Account Number"
                    v-model="bankDetails.accountNumber"
                  />
                </div>

                <div class="mb-3 col-md-4">
                  <label for="pan">PAN Number:</label>
                  <input
                    type="text"
                    name="panNumber"
                    id="panNumber"
                    class="form-control"
                    placeholder="Pan Number"
                    v-model="employeeData.PANno"
                  />
                </div>

                <div class="mb-3 col-md-4">
                  <label for="ifsc">IFSC Code:</label>
                  <input
                    type="text"
                    name="ifsc"
                    id="ifsc"
                    class="form-control"
                    placeholder="IFSC"
                    v-model="bankDetails.IFSC"
                  />
                </div>

                <div class="mb-3 col-md-4">
                  <label for="branch">Branch Address:</label>
                  <input
                    type="search"
                    name="branch"
                    id="branch"
                    class="form-control"
                    placeholder="Branch Address"
                    v-model="bankDetails.branchAddress"
                  />
                </div>
              </div>
            </form>

            <h6 class="mt-2 active-SubHeading">Emergency contact Information</h6>
            <form>
              <div class="row">
                <div class="mb-3 col-md-4">
                  <label for="Name"> Name</label>
                  <input
                    type="text"
                    id="Name"
                    name="Name"
                    class="form-control"
                    placeholder="Name"
                    v-model="employeeData.emergencyContactName"
                  />
                </div>
                <div class="mb-3 col-md-4">
                  <label for="bankName">Phone Number</label>
                  <input
                    type="text"
                    id="Number"
                    name="Number"
                    class="form-control"
                    placeholder="Mobile Number"
                    v-model="employeeData.emergencyContactNumber"
                  />
                  <small
                    v-if="
                      employeeData.emergencyContactNumber &&
                      !isValidMobileNumber(employeeData.emergencyContactNumber)
                    "
                    class="text-danger"
                    >Please enter a valid 10-digit mobile number.</small
                  >
                </div>

                <div class="mb-3 col-md-4">
                  <label for="emergencyContactAddress">Address:</label>
                  <textarea
                    class="form-control"
                    id="emergencyContactAddress"
                    rows="4"
                    placeholder="Address"
                    v-model="employeeData.emergencyContactAddress"
                  ></textarea>
                </div>
              </div>
            </form>
            <!--                        fixed-footer -->
            <div class="">
              <div class="d-flex justify-content-center">
                <div class="text-center">
                  <button
                    type="submit"
                    v-if="this.employeeData.id"
                    class="browse"
                    @click="UpdateEmployeeData"
                    data-bs-dismiss="modal"
                  >
                    Update
                  </button>
                  <button
                    type="submit"
                    v-else
                    :disabled="!employeeData.employeeEmailId"
                    class="btn btn-sm btn-primary"
                    @click="submitEmployeeData"
                    data-bs-dismiss="modal"
                  >
                    Submit
                  </button>
                  <button type="button" class="cancelButton" data-bs-dismiss="modal">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    employeeNumber: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      profileImage: null,
      employeeData: {
        id: null,
        employeeNumber: "",
        firstName: "",
        lastName: "",
        mobileNumber: "",
        personalEmailId: "",
        PANno: "",
        dob: null,
        govtIdNo: "",
        gender: "",
        skills: "",
        dateOfHire: null,
        employeeEmailId: "",
        emergencyContactName: "",
        emergencyContactNumber: "",
        emergencyContactAddress: "",
        employeeRoles: [],
      },
      residentialAddress: {
        id: null,
        address: "",
        entityId: null,
        entityName: "Employee",
        addressType: "Residential", // Or use a variable if it varies
      },
      permanentAddress: {
        id: null,
        address: "",
        entityId: null,
        entityName: "Employee",
        addressType: "Permanent", // Or use a variable if it varies
      },
      bankDetails: {
        beneficiaryName: "",
        accountName: "",
        accountNumber: "",
        bankName: " ",
        IFSC: "",
        branchAddress: " ",
        entityId: null,
        entityName: "Employee",
        id: null,
      },
      departments: [
        // { name: "Marketing", roles: ["Manager", "Executive"] },
        { name: "Sales", roles: ["Manager", "Executive"] },
        { name: "Designing", roles: ["Manager", "Executive"] },
        { name: "Onsite", roles: ["Manager", "Executive"] },
        { name: "Procurement", roles: ["Manager", "Executive"] },
        { name: "Inventory", roles: ["Manager", "Executive"] },
        { name: "Accounts", roles: ["Manager", "Executive"] },
        { name: "HR", roles: ["Manager", "Executive"] },
        { name: "Admin", roles: ["Manager", "Executive"] },
      ],
      isDropdownOpen: false,
      selectedDepartments: [],
      selectedRoles: {},
      SelectedEmployeeId: this.employeeNumber,
    };
  },
  mounted() {
    this.employeeData.id = this.employeeNumber;
    if (this.employeeNumber) {
      this.fetchEmployeeData(this.employeeNumber);
    }
 
  },
  watch: {
    employeeNumber(newValue) {
      if (newValue !== null) {
        this.SelectedEmployeeId = newValue;
        this.fetchEmployeeData(this.SelectedEmployeeId);
        this.featchEmployeeProfile(this.SelectedEmployeeId);
      } else {
        this.employeeData.id = newValue;
        this.employeeData = {};
        this.selectedDepartments = [];
        this.bankDetails = {};
        this.residentialAddress = {};
        this.permanentAddress = {};
      }
    },
  },

  computed: {
    selectedDepartmentText() {
      return this.selectedDepartments.join(", ");
    },
    selectedDesignation() {
      let selectedRoles = [];

      this.selectedDepartments.forEach((deptName) => {
        if (this.selectedRoles[deptName]) {
          selectedRoles.push(this.selectedRoles[deptName]);
        }
      });

      return selectedRoles.join(", ");
    },
    selectedEmployeeRoles() {
      return this.selectedDepartments
        .map((deptName) => this.selectedRoles[deptName] || [])
        .flat();
    },
  },
  methods: {
    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    isValidMobileNumber(number) {
      const mobileRegex = /^\d{10}$/;
      return mobileRegex.test(number);
    },

    async fetchEmployeeAddresses(employeeId) {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/address/Employee/${employeeId}`
        );

        const addresses = response.data;
        const residentialAddressdata = addresses.find(
          (addr) => addr.addressType === "Residential"
        );
        const permanentAddressdata = addresses.find(
          (addr) => addr.addressType === "Permanent"
        );

        if (residentialAddressdata) {
          this.residentialAddress = {
            address: residentialAddressdata.address,
            id: residentialAddressdata.id,
          };
        }

        if (permanentAddressdata) {
          this.permanentAddress = {
            address: permanentAddressdata.address,
            id: permanentAddressdata.address,
          };
        }
      } catch (error) {
        this.$handleError(error);
      }
    },

    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },

    async createEmployeeAddresses() {
      try {
        this.residentialAddress.entityId = this.employeeData.id;
        this.permanentAddress.entityId = this.employeeData.id;

        // Post both addresses simultaneously
        await Promise.all([
          axios.post(`${this.$apiEndPoint}/address/`, this.residentialAddress),
          axios.post(`${this.$apiEndPoint}/address/`, this.permanentAddress),
        ]);
      } catch (error) {
        this.$handleError(error);
      }
    },

    async updateEmployeeAddresses() {
      try {
        // Execute both API calls concurrently using Promise.all
        await Promise.all([
          axios.put(
            `${this.$apiEndPoint}/address/${this.employeeNumber}/Employee/Residential`,
            this.residentialAddress
          ),
          axios.put(
            `${this.$apiEndPoint}/address/${this.employeeNumber}/Employee/Permanent`,
            this.permanentAddress
          ),
        ]);
      } catch (error) {
        this.$handleError(error);
      }
    },

    fetchEmployeeData(employeeId) {

      axios
        .get(`${this.$apiEndPoint}/employee/${employeeId}`)
        .then((response) => {
          this.employeeData = response.data;
          // Populate selectedDepartments and selectedRoles
          this.populateSelectedDepartmentsAndRoles();
          this.fetchEmployeeAddresses(response.data.id);
          this.featchEmployeeBankDetails(response.data.id);
          this.featchEmployeeProfile(this.employeeNumber);
          // Assign retrieved employee details to employeeData
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    async UpdateEmployeeData() {
      try {
        const roleIdsToRemove = this.populateRemoveRoles();
        // Prepare the payload for updating employee data
        const payload = {
          firstName: this.employeeData.firstName,
          lastName: this.employeeData.lastName,
          mobileNumber: this.employeeData.mobileNumber,
          personalEmailId: this.employeeData.personalEmailId,
          dob: this.employeeData.dob,
          gender: this.employeeData.gender,
          govtIdNo: this.employeeData.govtIdNo,
          skills: this.employeeData.skills,
          dateOfHire: this.employeeData.dateOfHire,
          employeeEmailId: this.employeeData.employeeEmailId,
          PANno: this.employeeData.PANno,
          emergencyContactName: this.employeeData.emergencyContactName,
          emergencyContactNumber: this.employeeData.emergencyContactNumber,
          emergencyContactAddress: this.employeeData.emergencyContactAddress,
          removeRoles: roleIdsToRemove,
          newRoles: this.generateNewRoles(), // Generate newRoles based on selected teams and roles
        };

       await axios.put(
          `${this.$apiEndPoint}/employee/${this.employeeData.id}`,
          payload
        );
   
        let employeeAddresses;
        try {
          employeeAddresses = await axios.get(
            `${this.$apiEndPoint}/address/Employee/${this.employeeData.id}`
          );
          await this.updateEmployeeAddresses();
        } catch (error) {
          if (error.response && error.response.status === 400) {
            this.$handleError(error);
            await this.createEmployeeAddresses();
          } else {
            this.$handleError(error);

            employeeAddresses = { data: [] }; // Set to an empty array
          }
        }

        // Check if employee addresses exist
        if (employeeAddresses.data.length === 0) {
          await this.createEmployeeAddresses();
        } else {
        
          if (
            employeeAddresses.data[0].address !== this.residentialAddress.address ||
            employeeAddresses.data[1].address !== this.permanentAddress.address
          ) {
   
            await this.updateEmployeeAddresses(); // Call a method to update addresses   
          } 
        }
        if (this.bankDetails.id !== null) {
          await this.updateBankDetails();
        } else {
          await this.createBankDetails();
        }
        this.$emit("featchEmployeeList");

        location.reload();
      } catch (error) {
        this.$handleError(error);
        location.reload();
      }
    },

    async createBankDetails() {
      try {
        this.bankDetails.entityId = this.employeeData.id;
     await axios.post(
          `${this.$apiEndPoint}/bankDetails`,
          this.bankDetails
        );

      } catch (error) {
        this.$handleError(error);
      }
    },
    async updateBankDetails() {
      try {
        const bankDetailsPayload = {
          beneficiaryName: this.bankDetails.beneficiaryName,
          accountName: this.bankDetails.accountName,
          accountNumber: this.bankDetails.accountNumber,
          bankName: this.bankDetails.bankName,
          IFSC: this.bankDetails.IFSC,
          branchAddress: this.bankDetails.branchAddress,
        };
       await axios.put(
          `${this.$apiEndPoint}/bankDetails/${this.employeeData.id}/Employee`,
          bankDetailsPayload
        );
       
      } catch (error) {
        this.$handleError(error);
      }
    },

    generateNewRoles() {
      const newRoles = [];

      // Iterate over selectedDepartments to generate newRoles
      this.selectedDepartments.forEach((deptName) => {
        const role = this.selectedRoles[deptName];

        // Check if the selected role is not already in employeeData.employeeRoles
        const isRoleAlreadyPresent = this.employeeData.employeeRoles.some(
          (existingRole) => existingRole.team === deptName && existingRole.role === role
        );

        if (role && !isRoleAlreadyPresent) {
          // Add the selected team and role to newRoles array
          newRoles.push({ team: deptName, role: role });
        }
      });

      return newRoles;
    },

    populateRemoveRoles() {
      // Check if employeeData or employeeRoles is undefined or null
      if (!this.employeeData?.employeeRoles?.length) {
        return []; // Return an empty array if no roles are present
      }

      const rolesToRemove = this.employeeData.employeeRoles.filter((existingRole) => {
        if (!existingRole?.team || !existingRole?.role) {
          return false; // Skip this role if the required properties are not present
        }

        const isRoleSelected = this.selectedDepartments.some(
          (deptName) =>
            deptName === existingRole.team &&
            this.selectedRoles[deptName] === existingRole.role
        );

        return !isRoleSelected;
      });

      const roleIdsToRemove = rolesToRemove.map((role) => role.id);
      return roleIdsToRemove;
    },

    populateSelectedDepartmentsAndRoles() {
      // Reset selectedDepartments and selectedRoles
      this.selectedDepartments = [];
      this.selectedRoles = {};

      // Loop through employeeRoles and populate selections
      this.employeeData.employeeRoles.forEach((employeeRole) => {
        const departmentName = employeeRole.team;
        const role = employeeRole.role;

        // Find the corresponding department in your departments array
        const department = this.departments.find(
          (dept) => dept.name.toLowerCase() === departmentName.toLowerCase()
        );

        if (department) {
          // Add department to selectedDepartments
          this.selectedDepartments.push(department.name);

          // Set role for this department in selectedRoles
          // Ensure the role exists within the department's roles
          if (department.roles.includes(role)) {
            this.selectedRoles[department.name] = role;
          }
        }
      });
    },

    isSelectedDepartment(dept) {
      return this.selectedDepartments.includes(dept.name);
    },
    submitEmployeeData() {
      try {
        this.employeeData.department = this.selectedDepartments.join(", ");
        this.employeeData.employeeRoles = [];

        this.selectedDepartments.forEach((deptName) => {
          const role = this.selectedRoles[deptName];
          this.employeeData.employeeRoles.push({ team: deptName, role: role });
        });

        // Make API request to send employeeData
        axios
          .post(`${this.$apiEndPoint}/employee/`, this.employeeData)
          .then((response) => {
            this.employeeData = response.data.employeeDetails;
            this.$toast("Employee Created successfully", "success");

            this.employeeData.employeeNumber = response.data.employeeNumber;
            this.handleFileUpload(response.data.employeeDetails.id);
            this.residentialAddress.entityId = response.data.employeeDetails.id;
            this.permanentAddress.entityId = response.data.employeeDetails.id;
            this.bankDetails.entityId = response.data.employeeDetails.id;
            // Post the residential address data
            axios.post(`${this.$apiEndPoint}/bankDetails/`, this.bankDetails);
            // Post the residential address data
            axios.post(`${this.$apiEndPoint}/address/`, this.residentialAddress);

            // Post the permanent address data
            axios.post(`${this.$apiEndPoint}/address/`, this.permanentAddress);

            this.$emit("featchEmployeeList");
          })
          .catch((error) => {
            this.$handleError(error);
          });
      } catch (error) {
        this.$handleError(error);
        this.$emit("featchEmployeeList");
        this.featchEmployeeList();
      }
    },
    featchEmployeeProfile(employeeId) {
      const PayLoad = {
        entityId: employeeId,
        fileCategory: "profile",
        moduleName: "company",
      };

      // Make API request to fetch employee profile photo URL
      axios
        .post(`${this.$apiEndPoint}/company/getFileSignedURL/`, PayLoad)
        .then((response) => {
          this.profileImage = Object.values(response.data)[0];
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    featchEmployeeBankDetails(employeeId) {
      // Make API request to fetch employee details
      axios
        .get(`${this.$apiEndPoint}/bankDetails/byEntityId/${employeeId}/Employee/`)
        .then((response) => {
          this.bankDetails = response.data;
          this.bankDetails.id = response.data.id;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    handleProfilePhotoChange(event) {
      const file = event.target.files[0];

      if (file) {
        // Get the selected file
        const reader = new FileReader();
        reader.onload = (e) => {
          // Update the profileImage with the selected file's data URL
          this.profileImage = e.target.result;
        };
        if (this.SelectedEmployeeId) {
          // Read the selected file as a URL using FileReader

          // Make API call to get signed URL for file upload
          axios
            .post(`${this.$apiEndPoint}/company/putFileSignedURL`, {
              fileName: file.name,
              fileSize: file.size,
              contentType: file.type,
              fileCategory: "profile",
              moduleName: "company",
              entityId: this.SelectedEmployeeId,
              entityName: "profile",
            })
            .then((response) => {
              const signedUrl = response.data.s3URL;

              // Use XMLHttpRequest to upload the file to the signed URL
              const xhr = new XMLHttpRequest();
              xhr.open("PUT", signedUrl, true);
              xhr.setRequestHeader("Content-Type", file.type);

              xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                  if (xhr.status === 200) {
                  this.$toast("File uploaded successfully", "success");

                  // Handle successful upload
                } else {
                  this.$handleError(xhr.responseText);
                  // Handle upload error
                }
                }
              };

              // Send the file as binary data
              xhr.send(file);
            })
            .catch((error) => {
              this.$handleError(error);
            });
          // Convert the file to a data URL
        }
        reader.readAsDataURL(file);
      }
    },

    handleFileUpload(EmployeeId) {
      const file = document.getElementById("profilePhoto").files[0]; // Get the selected file
      if (file) {
        // Make API call to get signed URL for file upload
        axios
          .post(`${this.$apiEndPoint}/company/putFileSignedURL`, {
            fileName: file.name,
            fileSize: file.size,
            contentType: file.type,
            fileCategory: "profile",
            moduleName: "company",
            entityId: EmployeeId,
            entityName: "profile",
          })
          .then((response) => {
            const signedUrl = response.data.s3URL;

            // Use XMLHttpRequest to upload the file to the signed URL
            const xhr = new XMLHttpRequest();
            xhr.open("PUT", signedUrl, true);
            xhr.setRequestHeader("Content-Type", file.type);

            xhr.onreadystatechange = () => {
              if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                  this.$toast("File uploaded successfully", "success");
                  // Handle successful upload
                } else {
                  this.$handleError(xhr.responseText); 
                  // Handle upload error
                }
              }
            };

            // Send the file as binary data
            xhr.send(file);
          })
          .catch((error) => {
            this.$handleError(error);
          });
      }
    },

    // Other component methods...
  },
};
</script>

<style scoped>
/* Your existing styles */
.dropdown-menu {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  padding: 5px;
  max-height: 150px;
  overflow-y: auto;
  z-index: 1000;
  display: block;
}

.form-check {
  margin-bottom: 5px;
}

.ProfilePhoto {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #b1c5df;
  position: relative;
  /* To position the input and label relative to the container */
}

.uploaded-photo {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  /* Position the image absolutely within the container */
  top: 0;
  left: 0;
}

/* Additional styles for the uploaded photo */
#profilePhoto {
  display: none;
  /* Hide the file input */
}

label[for="profilePhoto"] {
  cursor: pointer;
  /* Change cursor to pointer on label */
  color: blue;
  /* Change color to indicate clickable */
  text-decoration: underline;
  /* Add underline to indicate clickable */
  position: absolute;
  /* Position the label absolutely within the container */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Center the label horizontally and vertically */
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  /* Remove default outline */
  box-shadow: none;
  /* Add a custom focus shadow */
}

label {
  font-weight: 500;
  color: black;
  font-size: 14px;
}

input,
textarea,
select {
  border: 1px solid #ccc;
  outline: none !important;
  font-size: 14px;
}

.createLeadForm {
  min-width: 100%;
  margin-left: 24%;
  font-size: 16px;

  color: #000000;

  height: 100%;
  overflow-y: scroll;
}

.fixed-footer {
  position: fixed;
  bottom: 10px;
  border-radius: 5px;
  width: 52%;
  right: 10px;
  background-color: #ffffff;
  /* White background color */
  padding: 5px 0;
  /* Adjust padding as needed */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  /* Optional: Add shadow for separation */
}

.subHeadingsOfLeads h5:nth-child(2) {
  margin: 0 10%;
}

.sectionOther img {
  width: 2%;
}

@media (max-width: 767px) {
  .createLeadForm {
    max-width: 100%;
    min-width: 100%;
    /* Full width on mobile */
    margin-left: 0;
    /* Reset margin for mobile */
  }

  .subHeadingsOfLeads h5:nth-child(2) {
    margin: 0 3%;
  }

  .fixed-footer {
    width: 95%;
    bottom: 0px;
  }
}
</style>