<template>
  <div
    class="modal fade"
    id="dateRangeModal"
    tabindex="-1"
    aria-labelledby="dateRangeModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" style="font-size: small" id="dateRangeModalLabel">
            Report Data
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body" style="font-size: small">
          <div class="row mb-3">
            <div class="col-md-6">
              <label for="from-month" class="form-label">From Month</label>
              <select
                class="form-select scrollable-dropdown"
                v-model="fromMonth"
                id="from-month"
                style="font-size: small; padding-bottom: 4px"
              >
                <option
                  style="font-size: small"
                  v-for="(month, index) in months"
                  :key="index"
                  :value="index + 1"
                >
                  {{ month }}
                </option>
              </select>
            </div>
            <div class="col-md-6">
              <label for="from-year" class="form-label">From Year</label>
              <select
                class="form-select scrollable-dropdown"
                v-model="fromYear"
                id="from-year"
                style="font-size: small; padding-bottom: 4px"
              >
                <option v-for="year in years" :key="year" :value="year">
                  {{ year }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-6">
              <label for="to-month" class="form-label">To Month</label>
              <select
                class="form-select scrollable-dropdown"
                v-model="toMonth"
                id="to-month"
                style="font-size: small; padding-bottom: 4px"
              >
                <option
                  style="font-size: small"
                  v-for="(month, index) in months"
                  :key="index"
                  :value="index + 1"
                >
                  {{ month }}
                </option>
              </select>
            </div>
            <div class="col-md-6">
              <label for="to-year" class="form-label">To Year</label>
              <select
                class="form-select scrollable-dropdown"
                v-model="toYear"
                id="to-year"
                style="font-size: small; padding-bottom: 4px"
              >
                <option
                  style="font-size: small"
                  v-for="year in years"
                  :key="year"
                  :value="year"
                >
                  {{ year }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="applyDateRange"
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DateRangeModal",
  data() {
    return {
      fromMonth: "4",
      fromYear: "",
      toMonth: "3",
      toYear: "",
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      years: Array.from({ length: 20 }, (_, i) => 2010 + i),
    };
  },
  methods: {
    applyDateRange() {
      this.$emit("applyDateRange", {
        fromMonth: this.fromMonth,
        fromYear: this.fromYear,
        toMonth: this.toMonth,
        toYear: this.toYear,
      });
    },
  },
};
</script>

<style scoped>
.scrollable-dropdown {
  min-height: 10px;
  max-height: 30px;
  overflow-y: auto;
}

.modal-footer {
  height: 55px;
}

.modal-footer button {
  height: 35px;
  padding: 5px;
}
</style>
