<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="ContractorDetails"
      aria-labelledby="ContractorDetails"
      aria-hidden="true"
      ref="ContractorDetailsModal"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg custom-modal">
        <div class="modal-content">
          <div class="modal-header text-left w-100">
            <h5 class="modal-title w-100" id="ContractorDetails">Contractor Details</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form>
              <div class="container-fluid">
                <p class="row redSubHeading">Primary Contact Details</p>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label" id="contractorNumber"
                    >Contractor Number:</label
                  >
                  <div class="col-sm-8" id="contractorNumber">
                    <p class="form-control-plaintext">{{ form.contractorNumber }}</p>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label" id="name">Name:</label>
                  <div class="col-sm-8" id="name">
                    <p class="form-control-plaintext">{{ form.name }}</p>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label" id="contactNumber"
                    >Contact Number:</label
                  >
                  <div class="col-sm-8" id="contactNumber">
                    <p class="form-control-plaintext">{{ form.contactNo }}</p>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label" id="email">Email:</label>
                  <div class="col-sm-8" id="email">
                    <p class="form-control-plaintext">{{ form.email }}</p>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label" id="address">Address:</label>
                  <div class="col-sm-8" id="address">
                    <p class="form-control-plaintext">{{ form.address }}</p>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label" id="city">City:</label>
                  <div class="col-sm-8" id="city">
                    <p class="form-control-plaintext">{{ form.city }}</p>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label" id="state">State:</label>
                  <div class="col-sm-8" id="state">
                    <p class="form-control-plaintext">{{ form.state }}</p>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label" id="pinCode">Pin Code:</label>
                  <div class="col-sm-8" id="pinCode">
                    <p class="form-control-plaintext">{{ form.pinCode }}</p>
                  </div>
                </div>
                <p class="row redSubHeading">Secondary Contact Details</p>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label" id="secondaryContactName">
                    Name:</label
                  >
                  <div class="col-sm-8" id="secondaryContactName">
                    <p class="form-control-plaintext">{{ form.secondaryContactName }}</p>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label" id="secondaryContactEmail">
                    Email:</label
                  >
                  <div class="col-sm-8" id="secondaryContactEmail">
                    <p class="form-control-plaintext">{{ form.secondaryContactEmail }}</p>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label" id="secondaryContactNumber"
                    >Contact No:</label
                  >
                  <div class="col-sm-8" id="secondaryContactNumber">
                    <p class="form-control-plaintext">
                      {{ form.secondaryContactNumber }}
                    </p>
                  </div>
                </div>
                <p class="redSubHeading mt-2">Bank Details</p>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label" id="beneficiaryName">
                    Beneficiary Name:</label
                  >
                  <div class="col-sm-8" id="beneficiaryName">
                    <p class="form-control-plaintext">
                      {{ form["bankDetails.beneficiaryName"] }}
                    </p>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label" id="accountNumber"
                    >Account Number:</label
                  >
                  <div class="col-sm-8" id="accountNumber">
                    <p class="form-control-plaintext">
                      {{ form["bankDetails.accountNumber"] }}
                    </p>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label" id="bankName">Bank Name:</label>
                  <div class="col-sm-8" id="bankName">
                    <p class="form-control-plaintext">
                      {{ form["bankDetails.bankName"] }}
                    </p>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label" id="IFSCCode">IFSC Code:</label>
                  <div class="col-sm-8" id="IFSCCode">
                    <p class="form-control-plaintext">{{ form["bankDetails.IFSC"] }}</p>
                  </div>
                </div>
              </div>
              <div class="modal-footer d-flex justify-content-center">
                <button
                  type="submit"
                  class="btn btn-secondary btn-sm"
                  data-bs-dismiss="modal"
                  @click="updateContractorDetails"
                  style="letter-spacing: 2px"
                >
                  Edit
                  <img
                    src="../../assets/icons/yellowEdit.png"
                    style="width: 15px; height: 15px"
                    alt="Edit"
                  />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "OnsiteContractorDetailsModal",
  props: {
    contractorId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        contractorNumber: "",
        name: "",
        contactNo: "",
        email: "",
        address: "",
        city: "",
        state: "",
        pinCode: "",
        secondaryContactName: "",
        secondaryContactEmail: "",
        secondaryContactNumber: "",
        bankDetails: {
          beneficiaryName: "",
          accountNumber: "",
          bankName: "",
          IFSC: "",
        },
        id: null,
      },
    };
  },
  mounted() {},
  watch: {
    contractorId(newValue) {
      this.localQuotationId = newValue;
      if (newValue !== null) {
        this.fetchContractorById();
      }
    },
  },
  methods: {
    async fetchContractorById() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/onSiteContractor/byId/${this.localQuotationId}`
        );
        this.form = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async updateContractorDetails() {
      this.$router.push({
        name: "UpdateContractor",
        params: { contractorId: this.contractorId },
      });
    },
  },
};
</script>
<style scoped>
.modal-dialog.custom-modal {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  margin: 0;
  width: 30%;
}

.modal-content {
  border-radius: 15px;
  height: 100%;
}

.modal-body {
  max-height: calc(100vh - 20px);
  overflow-y: auto;
  font-weight: 400;
  padding: 4px;
}

.col-form-label {
  white-space: nowrap;
  font-size: 14px !important;
}

.form-control-plaintext {
  white-space: nowrap;
  flex: 1;
  font-size: 14px !important;
}

.redSubHeading {
  color: #ff6b00;
  font-size: 16px;
  margin-left: 3px;
  white-space: nowrap;
  border-bottom: 1px solid #000000;
  border-width: 70%;
}

/* Media query for mobile view */
@media (max-width: 768px) {
  .modal-dialog.custom-modal {
    width: 100%;
  }
}
</style>
