<template>
  <div>
    <Header />
    <div class="content-container">
      <Nav />
      <div class="containerChild">
        <div class="purchase-request" v-if="pettyCash">
          <div class="mb-2">
            <div class="d-flex justify-content-between">
              <h5 class="PettyCashHeader">Petty Cash</h5>
              <div class="close MOBILE">
                <!-- <img
                  src="../../assets/icons/search.png"
                  alt=""
                  @click="goBack"
                  style="width: 20px; height: 20px; cursor: pointer"
                /> -->
                <button
                class="btn btn-sm btn-light me-4 "
                @click="openApprovalModal()"
                style=" font-size:12px;"
              >
                <img
                  src="../../assets/icons/calandar.png"
                  class="mb-1"
                  data-toggle="tooltip"
                  data-placement="top-start"
                  style="width: 18px; height: 18px"
                  alt="Calendar Icon"
                />
                Report -
                <span v-if="fromDate || toDate">
                  {{ this.$formatDateDMY(fromDate) }} to
                  {{ this.$formatDateDMY(toDate) }}
                </span>
                <span v-else>from past 60 days.</span>
              </button>
              </div>
            </div>
          </div>

          <div class="PettyCashPageheader">
            <div>
              <button
                @click="AddonSitePettyCash('null')"
                class="btn btn-sm btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#AddonSitePettyCash"
              >
                + Add Income/Expense
              </button>
              <button class="btn btn-sm btn-secondary" @click="goToProjectExpenseSheet()">
                Project Expense Sheet
              </button>
              <button
                class="btn btn-sm btn-light align-content-center hide-on-mobile "
                @click="openApprovalModal()"
              >
                <img
                  src="../../assets/icons/calandar.png"
                  class="mb-1"
                  data-toggle="tooltip"
                  data-placement="top-start"
                  style="width: 18px; height: 18px"
                  alt="Calendar Icon"
                />
                Report -
                <span v-if="fromDate || toDate">
                  {{ this.$formatDateDMY(fromDate) }} to
                  {{ this.$formatDateDMY(toDate) }}
                </span>
                <span v-else>from past 60 days.</span>
              </button>
            </div>
            <div class="text-nowrap d-flex Balance" style="margin-right: 20px">
              <span class="text-nowrap d-flex">Available Balance : </span>
              <span
                v-if="PettyCash.balance"
                style="
                  border: 1px solid #000;
                  font-size: 14px;
                  padding: 3px 15px;
                  border-radius: 5px;
                  margin: 0 5px;
                  letter-spacing: 1px;
                "
                class="balancetext"
              >
                {{ $formatToIndianCurrency(PettyCash.balance) }}
              </span>
            </div>
          </div>

          <!-- <transition name="fade">
            <div v-if="showModal" class="modal-overlay" @click="openApprovalModal"></div>
          </transition> -->
          <transition name="slide">
            <!-- Modal -->
            <div class="modal show d-block" v-if="showModal" style="z-index: 99999">
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <!-- Modal Header -->
                  <div class="modal-header">
                    <h5 class="modal-title" id="pettyCashReportModalLabel">
                      Report Data
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      @click="showModal = false"
                    ></button>
                  </div>

                  <!-- Modal Body -->
                  <div class="modal-body">
                    <!-- Form with @submit.prevent to stop reload -->
                    <form @submit.prevent="submitReport(fromDate, toDate)">
                      <!-- From Date Field -->
                      <div class="mb-3">
                        <label for="fromDate" class="form-label">From Date</label>
                        <input
                          type="date"
                          v-model="fromDate"
                          class="form-control"
                          id="fromDate"
                        />
                      </div>

                      <!-- To Date Field -->
                      <div class="mb-3">
                        <label for="toDate" class="form-label">To Date</label>
                        <input
                          type="date"
                          v-model="toDate"
                          class="form-control"
                          id="toDate"
                        />
                      </div>

                      <!-- Buttons -->
                      <div class="d-flex justify-content-between">
                        <!-- Submit Button -->
                        <button type="submit" class="btn btn-sm btn-primary">
                          Submit
                        </button>

                        <!-- Reset Button -->
                        <button
                          type="button"
                          class="btn btn-sm btn-secondary"
                          @click="ResetReport"
                        >
                          Reset
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </transition>
          <div class="PettyCashTable WEB">
            <table>
              <caption class="d-none">
                Table
              </caption>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Project Name</th>
                  <th>Account</th>
                  <th>Detail</th>
                  <th class="text-center">Cash In</th>
                  <th class="text-center">Cash Out</th>
                  <th class="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in getIncomeAndExpense" :key="index">
                  <td>{{ formatDate(row.date) }}</td>
                  <td class="text-nowrap text-left">{{ row["projects.projectName"] }}</td>
                  <td>{{ row.account }}</td>
                  <td>
                    {{ row.description }}
                  </td>
                  <td class="text-right">{{ $formatToIndianCurrency(row.cashIn) }}</td>
                  <td class="text-right">{{ $formatToIndianCurrency(row.cashOut) }}</td>

                  <td class="text-center">
                    <div class="ActionImage">
                      <span
                        @click="
                          $viewFile(
                            row['awsFiles.awsFileName'],
                            row['awsFiles.fileName'],
                            'onSitePettyCash',
                            'onSite'
                          )
                        "
                      >
                        <img
                          src="../../assets/icons/view.png"
                          data-toggle="tooltip"
                          data-placement="top-start"
                          title="View"
                          alt=""
                        />
                      </span>
                      <span
                        @click="AddonSitePettyCash(row.id)"
                        data-bs-toggle="modal"
                        data-bs-target="#AddonSitePettyCash"
                      >
                        <img
                          src="../../assets/icons/yellowEdit.png"
                          data-toggle="tooltip"
                          data-placement="top-start"
                          title="Edit"
                          alt=""
                        />
                      </span>
                      <span @click="DeleteRow(index, row.id)">
                        <img
                          src="../../assets/icons/redDelete.png"
                          data-toggle="tooltip"
                          data-placement="top-start"
                          title="Delete"
                          alt=""
                        />
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot style="font-size: 16px">
                <tr style="font-weight: bolder; background-color: #bcbfc6">
                  <td>Total Value</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-end">
                    {{ $formatToIndianCurrency(PettyCash.totalIncome) }}
                  </td>
                  <td class="text-end">
                    {{ $formatToIndianCurrency(PettyCash.totalExpense) }}
                  </td>
                  <td></td>

                  <!-- <td class="text-end">
                    {{ $formatToIndianCurrency(purchase.purchaseInvoice) }}
                  </td>
                  <td class="text-end">
                    {{ $formatToIndianCurrency(purchase.debitNote) }}
                  </td> -->
                </tr>
              </tfoot>
            </table>
          </div>
          <div class="PettyCashTable MOBILE">
            <table>
              <caption class="d-none">
                Table
              </caption>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Project Name</th>
                  <th class="text-center">Amount</th>
                  <th class="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in getIncomeAndExpense" :key="index">
                  <td class="ellipsis">{{ formatDate(row.date) }}</td>
                  <td class="text-nowrap text-left ellipsis">{{ row["projects.projectName"] }}</td>
                  <!-- <td class="text-nowrap text-left">{{ row["projects.projectName"] }}</td>
                  -->
                  <td class="text-right ellipsis" :style="{ color: row.cashIn ? 'green' : 'red' }">
                     {{ row.cashIn ? '+' : '-' }}{{ $formatToIndianCurrency(row.cashIn || row.cashOut) }}
                    </td>

                  <td class="text-center ">
                    <div class="d-flex align-content-center justify-content-center text-nowrap ">
   
      <img
        class="ms-3"
        src="../../assets/icons/3blackDots.png"
        alt=""
        style="width: 17px; height: 17px"
        id="dropdownMenuButton"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      />

                <div class="dropdown-menu dropdown-menu-center ActionImage" aria-labelledby="dropdownMenuButton">
                      <span>
                        <img
                          src="../../assets/icons/info.png"
                          alt=""
                          srcset=""
                          data-toggle="tooltip"
                          data-placement="top-start"
                          title="Info"
                          data-bs-target="#tableDetailModal"
                          data-bs-toggle="modal"
                          @click="showDetails(row)"
                        />
                      </span>
                      <span
                        @click="
                          $viewFile(
                            row['awsFiles.awsFileName'],
                            row['awsFiles.fileName'],
                            'onSitePettyCash',
                            'onSite'
                          )
                        "
                      >
                        <img
                          src="../../assets/icons/view.png"
                          data-toggle="tooltip"
                          data-placement="top-start"
                          title="View"
                          alt=""
                        />
                      </span>
                      <span
                        @click="AddonSitePettyCash(row.id)"
                        data-bs-toggle="modal"
                        data-bs-target="#AddonSitePettyCash"
                      >
                        <img
                          src="../../assets/icons/yellowEdit.png"
                          data-toggle="tooltip"
                          data-placement="top-start"
                          title="Edit"
                          alt=""
                        />
                      </span>
                      <span @click="DeleteRow(index, row.id)">
                        <img
                          src="../../assets/icons/redDelete.png"
                          data-toggle="tooltip"
                          data-placement="top-start"
                          title="Delete"
                          alt=""
                        />
                      </span>
                    </div>
    </div>

</td>
                </tr>
              </tbody>
              <!-- <tfoot style="font-size: 14px">
                <tr style="font-weight: bolder; background-color: #bcbfc6">
                  <td>Total Value</td>

                  <td class="text-end">
                    {{ $formatToIndianCurrency(PettyCash.totalIncome) }}
                  </td>
                  <td class="text-end">
                    {{ $formatToIndianCurrency(PettyCash.totalExpense) }}
                  </td>
                  <td></td>

                   <td class="text-end">
                    {{ $formatToIndianCurrency(purchase.purchaseInvoice) }}
                  </td>
                  <td class="text-end">
                    {{ $formatToIndianCurrency(purchase.debitNote) }}
                  </td> 
                </tr>
              </tfoot> -->
            </table>
          </div>
        </div>
        <div class="purchase-request" v-else>
          <div class="mb-2 p-1">
            <div v-if="PettyCashByAllProjects">
              <div class="d-flex justify-content-between">
                <h5 class="text-decoration-underline">Project Expense Sheet</h5>
                <div class="close">
                  <button
                    type="button"
                    class="btn-close"
                    @click="goToProjectExpenseSheet()"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
              <div class="table-resonsive DesignProjectTimeLine WEB">
                <table class="files-table mt-3">
                  <caption class="d-none">
                    Table
                  </caption>
                  <thead>
                    <tr>
                      <th>Sl/No</th>
                      <th>Project Name</th>
                      <th class="text-end">Expense Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, index) in getAllProjects" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td
                        class="text-nowrap text-left"
                        @click="
                          gotoProjectInfo(row.projectId, row['projects.projectName'])
                        "
                      >
                        {{ row["projects.projectName"] }}
                      </td>
                      <td class="text-end">
                        {{ $formatToIndianCurrency(row.totalExpense) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="table-resonsive DesignProjectTimeLine MOBILE">
                <table class="files-table mt-3">
                  <caption class="d-none">
                    Table
                  </caption>
                  <thead>
                    <tr>
                      <th>Project Name</th>
                      <th class="text-end">Expense Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, index) in getAllProjects" :key="index">
                      <!-- <td>{{ index + 1 }}</td> -->
                      <td
                        class="text-nowrap text-left"
                        @click="
                          gotoProjectInfo(row.projectId, row['projects.projectName'])
                        "
                      >
                        {{ row["projects.projectName"] }}
                      </td>
                      <td class="text-end">
                        {{ $formatToIndianCurrency(row.totalExpense) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-else>
              <div class="d-flex justify-content-between">
                <h5 class="ms-3 text-decoration-underline">Project Expense Sheet</h5>
                <div class="ActionImage">
                  <img
                    src="../../assets/icons/print.png"
                    data-toggle="tooltip"
                    data-placement="top-start"
                    title="print"
                    class="mb-2"
                    @click="downloadPDF"
                    alt=""
                  />
                  <button
                    type="button"
                    class="btn-close"
                    @click="this.PettyCashByAllProjects = true"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
              <section ref="printContent" class="WEB">
                <div
                  class="d-flex justify-content-end"
                  style="margin-right: 20px"
                  v-if="DateFormate"
                >
                  Date:{{ currentDate }}
                </div>
                <div
                  class="mt-1 d-flex justify-content-between"
                  style="margin-left: 14px; font-weight: 500"
                >
                  <section>
                    ProjectName: <strong>{{ this.ProjectName }} </strong>
                  </section>
                  <section style="margin-right: 20px">
                    Total amount:
                    <strong>
                      {{
                        $formatToIndianCurrency(this.getPettyCashByProject.totalExpense)
                      }}
                    </strong>
                  </section>
                </div>

                <div class="table-resonsive DesignProjectTimeLine p-2">
                  <table class="files-table mt-3">
                    <caption class="d-none">
                      Table
                    </caption>
                    <thead>
                      <tr>
                        <th>Sl/No</th>
                        <th>Date</th>
                        <th>Detail</th>
                        <th class="text-right">Expense</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(row, index) in getPettyCashByProject.getProjects"
                        :key="index"
                      >
                        <td>{{ index + 1 }}</td>
                        <td>{{ formatDate(row.date) }}</td>

                        <td>{{ row.description }}</td>
                        <td class="text-right">
                          {{ $formatToIndianCurrency(row.cashOut) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
              <section ref="printContent" class="MOBILE">
                <div
                  class="d-flex justify-content-end"
                  style="margin-right: 10px; font-size: 12px"
                  v-if="DateFormate"
                >
                  Date:{{ currentDate }}
                </div>
                <div
                  class="mt-1 d-flex justify-content-between"
                  style="margin-left: 14px; font-weight: 500; font-size: 12px"
                >
                  <section>
                    ProjectName : <br />
                    <strong>{{ this.ProjectName }} </strong>
                  </section>
                  <section style="margin-right: 20px">
                    Total amount : <br />
                    <strong>
                      {{
                        $formatToIndianCurrency(this.getPettyCashByProject.totalExpense)
                      }}
                    </strong>
                  </section>
                </div>

                <div class="table-resonsive DesignProjectTimeLine p-2">
                  <table class="files-table mt-3">
                    <caption class="d-none">
                      Table
                    </caption>
                    <thead>
                      <tr>
                        <th>Sl/No</th>
                        <th>Date</th>
                        <th>Detail</th>
                        <th class="text-right">Expense</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(row, index) in getPettyCashByProject.getProjects"
                        :key="index"
                      >
                        <td>{{ index + 1 }}</td>
                        <td>{{ formatDate(row.date) }}</td>

                        <td>{{ row.description }}</td>
                        <td class="text-right">
                          {{ $formatToIndianCurrency(row.cashOut) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <AddOnSitePettyCashModal :pettyCashId="PettyCashId" @fetchData="featchPettyCash" />
  <PettyCashReportModal />
  <TableDetailsModal :details="details" />
</template>

<script>
import PettyCashReportModal from "../Modal/PettyCashReportModal.vue"; // Import the ReportModal component
import html2pdf from "html2pdf.js";
import axios from "axios";
import AddOnSitePettyCashModal from "../Modal/AddOnSitePettyCashModal.vue";
import Header from "../Header.vue";
import Nav from "../Nav.vue";
import TableDetailsModal from "../Modal/TableDetailsModal.vue";
export default {
  name: "PettyCashOnsite",
  components: {
    Header,
    Nav,
    AddOnSitePettyCashModal,
    PettyCashReportModal,
    TableDetailsModal,
  },
  data() {
    return {
      details: [],
      searchString: "",
      fromDate: "",
      toDate: "",
      showModal: false,
      DateFormate: false,
      pettyCash: true,
      PettyCash: {},
      PettyCashByAllProjects: true,
      getIncomeAndExpense: [],
      PettyCashId: null,
      getAllProjects: [],
      getPettyCashByProject: {},
      currentDate: this.formatCurrentDate(),
    };
  },
  mounted() {
    this.featchPettyCash();
  },
  methods: {
    showDetails(rowData) {
      // Initialize the details array with always-present fields
      this.details = [
        { label: "Date", value: this.$formatDateDMY(rowData.date) },
        { label: "Project Name", value: rowData["projects.projectName"] },
        { label: "Account", value: rowData.account },
        { label: "Detail", value: rowData.description },
      ];

      // Conditionally add the "Cash In" field
      if (rowData.cashIn) {
        this.details.push({
          label: "Cash In",
          value: this.$formatToIndianCurrency(rowData.cashIn),
        });
      }
      if (rowData.cashOut) {
        this.details.push({
          label: "Cash Out",
          value: this.$formatToIndianCurrency(rowData.cashOut),
        });
      }
    },

    submitReport(fromDate, toDate) {
      this.fromDate = fromDate;
      this.toDate = toDate;
      // const reportData = {
      //   searchString: this.searchString,
      //   fromDate: this.fromDate,
      //   toDate: this.toDate,
      // };
  
      this.featchPettyCash();
      this.showModal = false;
    },
    ResetReport() {
      this.fromDate = null;
      this.toDate = null;
  
      this.featchPettyCash();
      this.showModal = false;
    },
    openApprovalModal() {
      this.showModal = !this.showModal;
    },
    async featchPettyCash() {
      const reportData = {
        searchString: this.searchString,
        fromDate: this.fromDate,
        toDate: this.toDate,
      };

      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/pettyCash/getTransaction`,
          reportData
        );
        this.PettyCash = response.data;
        this.getIncomeAndExpense = response.data.getIncomeAndExpense;
      } catch (error) {
        this.$handleError(error);
      }
    },
    formatCurrentDate() {
      const date = new Date();
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear().toString();
      return `${day}/${month}/${year}`;
    },
    downloadPDF() {
      this.DateFormate = true;
      setTimeout(() => {
        const contentToPrint = this.$refs.printContent;
        const fileName = `ProjectExpenceSheet_${this.ProjectName}.pdf`;
        html2pdf(contentToPrint, {
          filename: fileName,
          margin: [10, 10, 10, 10], // top, left, bottom, right
          html2canvas: { scale: 2 },
          jsPDF: { format: "a4", orientation: "portrait" },
        });
      }, 0);
    },
    gotoProjectInfo(projectId, projectName) {
      this.PettyCashByAllProjects = !this.PettyCashByAllProjects;
      this.ProjectName = projectName;
      this.getPettyCashByProjectId(projectId);
    },

    async DeleteRow(Index, Id) {
      this.getIncomeAndExpense.splice(Index, 1); // Removes 1 item at the specified index

      try {
        // Await the axios delete request
        await axios.delete(`${this.$apiEndPoint}/pettyCash/${Id}`);

        // Fetch the updated list of petty cash records after successful deletion
        this.featchPettyCash();
      } catch (error) {
        // Handle the error
        this.$handleError(error);
      }
    },

    async getPettyCashByProjectId(projectId) {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/pettyCash/byProjectId/${projectId}`
        );
        this.getPettyCashByProject = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },

    async featchgetAllProjectsPettyCash() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/pettyCash/getAllProjects`);
        this.getAllProjects = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    goToProjectExpenseSheet() {
      this.pettyCash = !this.pettyCash;
      this.PettyCashByAllProjects = true;
      this.featchgetAllProjectsPettyCash();
    },

    AddonSitePettyCash(PettyCashIds) {
      this.PettyCashId = PettyCashIds;
    },
  },
};
</script>

<style scoped>
.purchase-request {
  padding: 10px 5px;
  height: 100%;
  width: 100%;
}

.files-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.files-table th,
.files-table td {
  text-align: left;
  padding: 6px;
  border-bottom: 1px solid #ddd;
}

.files-table thead {
  position: sticky;
  top: 0;
  background-color: #bcbfc6;
  z-index: 1;
}

.files-table th {
  background-color: #bcbfc6;
}

.files-table tr:hover {
  background-color: #f5f5f5;
}

.files-table th:nth-child(1),
.files-table td:nth-child(1) {
  text-align: left;
}

.files-table th:nth-child(5),
.files-table td:nth-child(5),
.files-table th:nth-child(6),
.files-table td:nth-child(6),
.files-table th:nth-child(7),
.files-table td:nth-child(7) {
  text-align: right;
}

.table-resonsive {
  white-space: nowrap;
  width: 100%;

  overflow-x: scroll;
}

.text-right {
  text-align: right !important;
}

.table-resonsive {
  overflow-x: scroll;
}

.d-flex.align-items-center {
  display: block !important;
}

.d-flex.align-items-center label,
.d-flex.align-items-center .form-control {
  width: 100% !important;
}

.DesignProjectTimeLine th,
.DesignProjectEstimate td {
  padding: 4px 8px;
  text-align: left;
  font-size: 2;
  white-space: nowrap;
}

.DesignProjectTimeLine td {
  padding: 4px 8px;
  text-align: left;
  font-size: 14px;
}

.DesignProjectTimeLine th:first-child,
.DesignProjectEstimate th:first-child {
  border-radius: 5px 0 0 5px;
}

.DesignProjectTimeLine th:last-child,
.DesignProjectEstimate th:last-child {
  border-radius: 0px 5px 5px 0px;
}

.DesignProjectTimeLine thead,
.DesignProjectEstimate thead {
  background-color: #bcbfc6;
  border-radius: 10px;
  top: 0;
  position: sticky;
  color: #333;
  font-size: 14px;
}

.DesignProjectTimeLine tbody {
  overflow-y: auto;
}

.DesignProjectTimeLine tbody td input {
  padding: 1% 1%;
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  text-align: left;
}

.active-view {
  background-color: #007bff;
  color: white;
}

.DesignProjectTimeLineWrapper .d-flex button {
  margin-right: 10px;
  font-size: 12px;
}

.DesignProjectTimeLineWrapper .d-flex button.active-view {
  background-color: purple;
  color: white;
}

.ActionImage img {
  padding: 5px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  white-space: nowrap;
}

.ActionImage img:hover {
  background-color: #ccc;
  border-radius: 50%;
}

.PettyCashTable {
  margin-top: 1%;
  max-height: 83%; /* Adjust the height based on your requirements */
  overflow-y: auto;
}

.PettyCashTable table {
  width: 98%;
  height: 100%;
  margin: 0 1%;
  border-collapse: collapse;
}

.PettyCashTable thead,
.PettyCashTable tfoot {
  background-color: #f9f9f9;
  position: sticky;
  z-index: 1;
}

.PettyCashTable thead {
  top: 0;
}

.PettyCashTable tfoot {
  bottom: 0;
}

.PettyCashTable th,
.PettyCashTable td {
  font-size: 12px;
  border: 1px solid #ddd;
  padding: 7px 8px;
  text-align: left;
  background-color: #ffffff;
}
.PettyCashTable th {
  background-color: #bcbfc6;
}
.PettyCashTable th span {
  font-size: 12px;
}
.PettyCashPageheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1%;
}

.PettyCashPageheader button:not(:first-child) {
  margin: 5px 15px; /* Add some spacing between buttons */
}
.PettyCashHeader {
  margin: 5px 15px;
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .ellipsis
  {
    max-width: 100px !important;
  }
  .hide-on-mobile {
    display: none !important;
  }
  
  .PettyCashPageheader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    flex-direction: column;
    align-items: flex-start;
    padding: 0% 0%;
  }

  /* Stack buttons in rows with 2 buttons per row */
  .PettyCashPageheader div:first-child {
    display: flex;
    flex-direction: row;

    gap: 10px;
    justify-content: flex-start;
  }

  .Balance {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 10px;
    justify-content: flex-start;
    margin: 10px 5px;
  }
  .ActionImage img {
    padding: 5px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    white-space: nowrap;
  }
  .balancetext {
    display: flex;
    justify-content: end;
    margin-left: 30px !important;
  }
  /* Ensure buttons take up appropriate width for mobile */
  .PettyCashPageheader button {
    width: 50%;
    margin-bottom: 10px;
    text-wrap: nowrap;
    font-size: 12px;
  }

  .purchase-request {
    height: 100%;
    width: 100%;
  }

  .selectMonthAndYear select {
    margin-top: 10px !important;
    margin-left: 6px;
    padding: 0;
    font-size: 13px;
  }
  .PettyCashTable {
    margin-top: 1%;
    height: 80%; /* Adjust the height based on your requirements */
    overflow-y: auto;
    font-size: 14px !important;
  }
  .PettyCashTable td:last-child {
    text-wrap: nowrap;
  }
  .PettyCashPageheader button {
    margin: 5px 5px; /* Add some spacing between buttons */
  }
  .PettyCashHeader {
    margin: 5px 5px;
    text-decoration: underline;
  }
}
</style>
