<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="CreateScopeOfWorkModal"
      tabindex="-1"
      aria-labelledby="CreateScopeOfWorkModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header text-center w-100">
            <h5 class="modal-title w-100" id="CreateScopeOfWorkModalLabel">
              Add Activity
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="submitForm"> <!-- Prevent form default behavior -->
              <div class="mb-3 row" v-if="!timePage">
                <label for="header" class="col-sm-3 col-form-label">Header</label>
                <div class="col-sm-9" >
                  <input
                    v-model="form.header"
                    type="text"
                    class="form-control"
                    id="header"
                    placeholder="Enter header"
                  />
                </div>
              </div>

              <div class="mb-3 row">
                <label for="activity" class="col-sm-3 col-form-label">Activity</label>
                <div class="col-sm-9"  v-if="!timePage">
                  <input
                    v-model="form.activity"
                    type="text"
                    class="form-control"
                    id="activity"
                    placeholder="Enter activity name"
                  />

                </div>
                <div class="col-sm-9" v-else>
                  <span  class="form-control">
                    {{ form.activity }}
                  </span>
               
                </div>
              </div>

              <div class="mb-3 row" >
                <label for="plannedStartDate" class="col-sm-3 col-form-label"
                  >Planned Start Date</label
                >
                <div class="col-sm-9" v-if="!timePage">
                  <input
                    v-model="form.plannedStartDate"
                    type="date"
                    class="form-control"
                    id="plannedStartDate"
                  />
                </div>
                <div class="col-sm-9" v-else>
                  <span  class="form-control">
                    {{ form.plannedStartDate }}
                  </span>
               
                </div>
              </div>

              <div class="mb-3 row">
                <label for="plannedEndDate" class="col-sm-3 col-form-label"
                  >Planned End Date</label
                >
                <div class="col-sm-9" v-if="!timePage">
                  <input
                    v-model="form.plannedEndDate"
                    type="date"
                    class="form-control"
                    id="plannedEndDate"
                  />
                </div>
                <div class="col-sm-9" v-else>
                  <span  class="form-control" >
                  {{ form.plannedEndDate }}
                </span>
                 </div>
              </div>

              <div class="mb-3 row" v-if="!timePage">
                <label for="plannedDuration" class="col-sm-3 col-form-label"
                  >Duration (days)</label
                >
                <div class="col-sm-9">
                  <input
                    v-model="form.plannedDuration"
                    type="number"
                    class="form-control"
                    id="plannedDuration"
                    placeholder="Enter plannedDuration"
                  />
                </div>
              </div>

              <div class="mb-3 row">
                <label for="assignee" class="col-sm-3 col-form-label">Assignee</label>
                <div class="col-sm-9">
                  <select
                    v-model="form.assignTo"
                    id="assignTo"
                    class="form-control form-select"
                  >
                    <option value="" disabled>Select assignee</option>
                    <option
                      v-for="employee in employees"
                      :key="employee.employeeId"
                      :value="employee.employeeId"
                    >
                      {{ employee["employees.firstName"] }}
                      {{ employee["employees.lastName"] }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="mb-3 row" v-if="timePage">
                <label for="plannedStartDate" class="col-sm-3 col-form-label"
                  >Actual Start Date</label
                >
                <div class="col-sm-9">
                  <input
                    v-model="form.actualStartDate"
                    type="date"
                    class="form-control"
                    id="plannedStartDate"
                  />
                </div>
              </div>

              <div class="mb-3 row" v-if="timePage">
                <label for="plannedEndDate" class="col-sm-3 col-form-label"
                  >Actual End Date</label
                >
                <div class="col-sm-9">
                  <input
                    v-model="form.actualEndDate"
                    type="date"
                    class="form-control"
                    id="plannedEndDate"
                  />
                </div>
              </div>

              <div class="modal-footer d-flex justify-content-center">
                <button

  type="submit"
  class="btn btn-primary btn-sm"
  data-bs-dismiss="modal"
>
  {{ form.id === null ? 'Submit' : 'Update' }}
</button>

              
                <button
           
                  type="button"
                  class="btn btn-danger btn-sm"
                  @click="DeleteTimeLine(form.id)"
                  v-if="form.id"
                  data-bs-dismiss="modal"
                >
                  Delete
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CreateScopeOfWorkModal",
  props: {
    projectId: {
      type: String,
      required: true,
    },
    onSiteProjectId: {
      type: String,
      required: true,
    },
    ScopeOfWorkParentId:
    {
      type: String,
      required:true
    },
    ScopeOfWorkRowId:
    {
      type: String,
      required:true
    },
    timePage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
     
      form: {
        id:this.ScopeOfWorkRowId,
        parentId:this.ScopeOfWorkParentId,
        projectId: this.projectId,
        activity: "",
        plannedStartDate: null,
        plannedEndDate:null,
        plannedDuration: null,
        assignTo:null,
        actualStartDate:null,
        actualEndDate:null,
        header: "",
      },
      employees: [],
    };
  },

  mounted() {
    this.fetchEmployees();
  },
  watch: {
    timePage(newval)
    {
console.log(newval);
    },
    projectId(newVal) {
      console.log(newVal);
      this.fetchEmployees();
    },
    ScopeOfWorkParentId(newVal)
    {
      if(newVal !== null)
    {
      console.log(newVal);
      this.form.parentId= newVal;
   this.fetchByParentId();
    }
    else
    {
      this.resetForm()
    }

    },
    ScopeOfWorkRowId(newVal)
    {
      console.log(newVal);
      console.log(this.timePage)
      if(newVal !== null)
    {
      this.form.id= newVal;
   this.fetchByRowId();
    }
    else
    {
      this.resetForm()
    }
    }
  },

  methods: {
    async DeleteTimeLine(timeLineid) {
   

      try {
        await axios.delete(`${this.$apiEndPoint}/onSiteScopeOfWork/${timeLineid}`);
        this.$emit("fetchscopeOfWork");
      } catch (error) {
        this.$handleError(error);
      }
    },
    fetchEmployees() {
      axios
        .get(`${this.$apiEndPoint}/onSiteSupervisors/${this.onSiteProjectId}`)
        .then((response) => {
          this.employees = response.data.filter((item) => item !== null);
          this.mapAssignedEmployee();
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    fetchByRowId()
    {
      axios
        .get(`${this.$apiEndPoint}/onSiteScopeOfWork/getById/${this.ScopeOfWorkRowId}`)
        .then((response) => {
          this.form = response.data;
          
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    fetchByParentId()
    {
      axios
        .get(`${this.$apiEndPoint}/onSiteScopeOfWork/getById/${this.ScopeOfWorkParentId}`)
        .then((response) => {
          this.form.header = response.data.header;
          
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    submitForm() {
  this.form.projectId = this.projectId; // Set projectId for both create and update scenarios
  console.log(this.form);
  console.log(this.form.header);

  const requestData = {
    header: this.form.header,
    Rows: [
      {
        parentId: this.form.parentId,
        projectId: this.form.projectId,
        activity: this.form.activity,
        plannedStartDate: this.form.plannedStartDate,
        plannedEndDate: this.form.plannedEndDate,
        plannedDuration: this.form.plannedDuration,
        assignTo: this.form.assignTo,
        actualStartDate:this.form.actualStartDate,
        actualEndDate:this.form.actualEndDate,
      },
    ],
  };

  const requestMethod = this.form.id ? "put" : "post";
  const requestUrl = this.form.id
    ? `${this.$apiEndPoint}/onSiteScopeOfWork/${this.form.id}`
    : `${this.$apiEndPoint}/onSiteScopeOfWork/`;

  // Adjust requestData for the "update" case
  if (this.form.id) {
    delete requestData.Rows; // `Rows` is not used in the PUT method
    Object.assign(requestData, {
      parentId: this.form.parentId,
      projectId: this.form.projectId,
      activity: this.form.activity,
      plannedStartDate: this.form.plannedStartDate,
      plannedEndDate: this.form.plannedEndDate,
      plannedDuration: this.form.plannedDuration,
      assignTo: this.form.assignTo,
      actualStartDate:this.form.actualStartDate,
      actualEndDate:this.form.actualEndDate,
    });
  }

  axios[requestMethod](requestUrl, requestData)
    .then(() => {
      const action = this.form.id ? "Updated" : "Created";
      this.$toast(`Scope of Work ${action} successfully.`, "success");
      this.$emit("fetchscopeOfWork");
      this.resetForm(); // Reset the form after submission
    })
    .catch((error) => {
      this.$handleError(error);
    });
},

   

    resetForm() {
      this.form = {
        id:this.ScopeOfWorkRowId,
        parentId:this.ScopeOfWorkParentId,
        projectId: this.projectId,
        activity: "",
        plannedStartDate:null,
        plannedEndDate: null,
        plannedDuration: null,
        assignTo: null,
        actualStartDate:null,
        actualEndDate:null,
   
      };
    },

    mapAssignedEmployee() {
      if (this.form.assignTo) {
        const assignedEmployee = this.employees.find(
          (emp) => emp.employeeId === this.form.assignTo
        );
        if (assignedEmployee) {
          this.form.assignTo = assignedEmployee.employeeId;
        }
      }
    },
  },
};
</script>


<style scoped>
input:focus,
textarea:focus,
select:focus {
  outline: none;
  /* Remove default outline */
  box-shadow: none;
  /* Add a custom focus shadow */
}
label,
input {
  font-size: 13px;
}

.employee-dropdown {
  position: absolute;
  display: block;
  background-color: #fff;
  border: 1px solid #ccc;
  max-height: 200px;
  border-radius: 5px;
  overflow-y: auto;
  z-index: 1000;
  margin-left: 28%;
  /* Ensure the dropdown appears above other elements */
  width: calc(100% - 28%); /* Adjust width to match the input */
  color: #000000;
  top: 20%; /* Position the dropdown below the input */
  left: 0; /* Align the left edge of the dropdown with the left edge of the input */
}

.employee-dropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.employee-dropdown ul li {
  padding: 8px 10px;
  cursor: pointer;
}

.employee-dropdown ul li:hover {
  background-color: #f0f0f0;
}
</style>
