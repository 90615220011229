<template>
  <div class="EmployeesignupGroup">
    <div class="row d-flex align-items-center">
      <div class="col-md-7 col-sm-7 col-lg-7">
        <div class="loginImg d-flex justify-content-center">
          <img src="../assets/icons/loginImage.png" alt="" />
        </div>
      </div>
      <div class="col-md-5 col-sm-5 col-lg-5">
        <div class="login align-items-center">
          <div class="logo d-flex justify-content-center">
            <h1>ARIVU</h1>
          </div>
          <div class="Employeesignup">
            <span class="input-wrapper mt-3">
              <label for="">&nbsp;Password&nbsp;</label>
              <input type="password" v-model="password" />
            </span>
            <span class="input-wrapper mt-5">
              <label for="">&nbsp;Confirm Password&nbsp;</label>
              <input type="password" v-model="confirmPassword" />
            </span>
            <p v-if="passwordMismatch" class="text-danger">Passwords do not match.</p>
            <button id="EmployeeSignButton" @click="changePassword()">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "UpdatePassword",
  data() {
    return {
      password: "",
      confirmPassword: "",
      passwordReset: {},
      email: "",
      passwordMismatch: false,
    };
  },
  methods: {
    async validateUUID() {
      const response = await axios.get(
        `${this.$apiEndPoint}/users/validateUUID/${this.UUID}`
      );

      if (response.data === null) {
        alert("Password reset link expired, Send Mail Again");
        this.$router.push({ name: "Login" });
      }
      this.passwordReset = response.data;
    },
    async changePassword() {
      if (this.password !== this.confirmPassword) {
        this.passwordMismatch = true;
        return;
      }
      this.passwordMismatch = false;

      const result = await axios.put(
        `${this.$apiEndPoint}/users/updatePassword/${this.UUID}`,
        {
          email: this.passwordReset.email,
          password: this.password,
        }
      );

      alert("Password Changed Successfully");
      if (result.data) {
        this.$router.push({ name: "Login" });
      }
    },
  },
  mounted() {
    this.UUID = this.$route.params.UUID;
    this.validateUUID();
  },
};
</script>

<style scoped>
.loginImg img {
  max-width: 80%;
  max-height: 80%;
}

.logo h1 {
  margin-bottom: 8%;
}

.radio-group {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
}

.radio-group label {
  margin-right: 20px;
  margin-bottom: 20px;
}

.EmployeesignupGroup {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
}

#EmployeeSignupHeading {
  margin-top: 5%;
  font-size: 2rem;
}

.Employeesignup {
  display: flex;
  margin-top: 2%;
  flex-direction: column;
}

.Employeesignup p {
  color: #fff;
}

#EmployeeSignButton {
  padding: 5px;
  background-color: #4884f7;
  color: #fff;
  font-size: 1rem;
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;
  margin: 10% 30%;
  margin-top: 10%;
}

.signUp {
  margin-top: -5%;
}

.forgetPassword {
  margin-top: -40%;
}

.EmployeeSignUpFooter a {
  color: #4884f7;
  cursor: pointer;
}

.input-wrapper {
  min-width: 400px;
  height: 40px;
  position: relative;
}

/* .input-wrapper p {
  margin-left: 55%;
  margin-top: 2%;
} */

.input-wrapper input {
  padding: 5% 2%;
  height: 100%;
  width: 100%;
  z-index: 99;
  border: 1.5px solid black;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease, background-color 0.3s ease;
  background-color: #ffffff;
  font-weight: bolder;
  /* Set input text color */
  font-size: 1rem;
}

.forgetButton img {
  padding: 18%;
  width: 130%;
  height: 198%;
  background-color: #a2bff6;
  border: 1.5px solid #000000;
  border-left: none;
  border-radius: 0 5px 5px 0;
  z-index: 1;
  cursor: pointer;
}

.input-wrapper label {
  white-space: nowrap;
  text-align: left;
  /* Adjusted to align left */
  position: absolute;
  margin-top: -13px;
  /* Adjusted to overlap with input when empty */
  left: 10px;
  pointer-events: none;
  z-index: 1;
  font-size: 1.1rem;
  transition: all 0.4s ease;
  color: #000000;
  font-weight: 500;
  background-color: #ffffff;
}

.input-wrapper input:focus {
  border-color: #4884f7;
}

.input-wrapper input:focus ~ label,
.input-wrapper input:not(:placeholder-shown) ~ label {
  margin-top: -20px;
  /* Adjusted to move label up */
  left: 10px;
  font-size: 16px;
  background-color: #084f5b;
}

.input-wrapper input:not(:focus):not(:placeholder-shown) ~ label {
  font-size: 1.1rem;
  margin-top: -10px;
  /* Adjusted to overlap with input when empty */
  left: 10px;
  background-color: #084f5b;
}

@media (max-width: 768px) {
  .login {
    margin-top: -30%;
  }

  .loginImg img {
    display: none;
  }

  .EmployeesignupGroup h2 {
    margin-bottom: 10%;
  }

  #EmployeeSignupHeading {
    margin-top: -40%;
    font-size: 1.5rem;
  }

  .input-wrapper {
    min-width: 320px;
    height: 40px;
    position: relative;
  }

  .forgetButton img {
    padding: 18%;
    width: 130%;
    height: 165%;
    background-color: #a2bff6;
    border: 1.5px solid #000000;
    border-left: none;
    border-radius: 0 5px 5px 0;
    z-index: 1;
    cursor: pointer;
  }
}
</style>
