<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="AddContractor"
      aria-labelledby="AddContractorLabel"
      aria-hidden="true"
      ref="addContractorModal"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header text-center w-100">
            <h5 class="modal-title w-100" id="AddContractorLabel">Add New Category</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="submitForm">
              <div class="mb-3 row">
                <label for="workDescription" class="col-sm-4 col-form-label"
                  >Category Name</label
                >
                <div class="col-sm-8">
                  <select
                    class="form-control"
                    id="category"
                    v-model="form.onSiteCategoryId"
                    @change="featchContractor(form.onSiteCategoryId)"
                  >
                    <option
                      v-for="category in Categories"
                      :key="category.id"
                      :value="category.id"
                    >
                      {{ category.categoryName }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="mb-3 row">
                <label for="workDescription" class="col-sm-4 col-form-label"
                  >Contractor Name</label
                >
                <div class="col-sm-8">
                  <select
                    class="form-control"
                    id="category"
                    v-model="form.onSiteContractorId"
                    @change="showContractorId()"
                  >
                    <option
                      v-for="contractor in Contractors"
                      :key="contractor.id"
                      :value="contractor.id"
                    >
                      {{ contractor.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="mb-3 row">
                <label for="ContractorNumber" class="col-sm-4 col-form-label"
                  >Contractor ID
                </label>
                <div class="col-sm-8">
                  <input
                    v-model="this.ContractorNumber"
                    type="text"
                    class="form-control"
                    id="ContractorNumber"
                    readOnly
                  />
                </div>
              </div>

              <div class="mb-3 row">
                <label
                  for="AddContractor"
                  class="col-sm-4 col-form-label"
                  style="visibility: hidden"
                >
                </label>
                <div class="col-sm-8">
                  <button
                    id="AddContractor"
                    class="btn btn-sm"
                    style="background-color: purple; color: white"
                    @click="goToCreateContractorPage()"
                    data-bs-dismiss="modal"
                  >
                    + Create New Contractor
                  </button>
                </div>
              </div>
              <div class="modal-footer d-flex justify-content-center">
                <button
                  v-if="form.id === null"
                  type="submit"
                  class="btn btn-primary btn-sm"
                  data-bs-dismiss="modal"
                  @click="AddContractor"
                >
                  Submit
                </button>

                <button
                  type="button"
                  class="btn btn-danger btn-sm"
                  @click="DeleteTimeLine"
                  v-if="form.id !== null"
                  data-bs-dismiss="modal"
                >
                  Delete
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "AddContractorModal",
  props: {
    projectId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      ContractorNumber: "",
      Contractors: [],
      Categories: [],
      localQuotationId: null,
      onSiteQuotation: null,
      form: {
        id: null,

        onSiteCategoryId: "",
        onSiteContractorId: "",
        onSiteProjectId: this.projectId,
      },
    };
  },
  mounted() {
    this.featchCategories();
  },
  watch: {
    projectId(newValue) {
      this.localQuotationId = newValue;
     
    },
  },
  methods: {
    async goToCreateContractorPage() {
      this.$router.push({
        name: "CreateContractor",
      });
    },

    async showContractorId() {
      const selectedContractor = this.Contractors.find(
        (contractor) => contractor.id === this.form.onSiteContractorId
      );
      if (selectedContractor) {
        this.ContractorNumber = selectedContractor.contractorNumber;
      } else {
        this.ContractorNumber = "";
      }
    },
    async featchContractor(categoryId) {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/OnSiteContractor/byCategoryId/${categoryId}`
        );
        this.Contractors = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async featchCategories() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/OnSiteCategory/`);
        this.Categories = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async AddContractor() {
      try {
       await axios.post(
          `${this.$apiEndPoint}/onSiteCategoryContractor/`,
          this.form
        );
    
        this.$emit("featchData");
        this.featchData();
      } catch (error) {
        this.$handleError(error);
      }
    },
    async UpdateActivity() {
      try {
        await axios.put(
          `${this.$apiEndPoint}/OnSiteQuotation/${this.localQuotationId}`,
          this.form
        );
        this.$emit("fetchTimeLineData");
      } catch (error) {
        this.$handleError(error);
      }
    },
    async DeleteTimeLine() {
      try {
        await axios.delete(
          `${this.$apiEndPoint}/OnSiteQuotation/${this.localQuotationId}`
        );
        this.$emit("fetchTimeLineData");
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>

<style scoped>
input:focus,
textarea:focus,
select:focus {
  outline: none;
  /* Remove default outline */
  box-shadow: none;
  /* Add a custom focus shadow */
}
label,
input {
  font-size: 13px;
}

.employee-dropdown {
  position: absolute;
  display: block;
  background-color: #fff;
  border: 1px solid #ccc;
  max-height: 200px;
  border-radius: 5px;
  overflow-y: auto;
  z-index: 1000;
  margin-left: 28%;
  /* Ensure the dropdown appears above other elements */
  width: calc(100% - 28%); /* Adjust width to match the input */
  color: #000000;
  top: 20%; /* Position the dropdown below the input */
  left: 0; /* Align the left edge of the dropdown with the left edge of the input */
}

.employee-dropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.employee-dropdown ul li {
  padding: 8px 10px;
  cursor: pointer;
}

.employee-dropdown ul li:hover {
  background-color: #f0f0f0;
}
</style>
