import { logout } from './authService';

export function handleGlobalError(error, toast) {
  let errorMessage;

  if (error.response) {
    const { status, data } = error.response;

    if (status === 404) {
      // Do nothing for 404 errors
      return;
    } else if (status === 500) {
      errorMessage = 'Something went wrong, please try again later.';
    } else if (data && data.message) {
      errorMessage = data.message;
    }

    if (status === 401 && data?.message === 'User is not Authorized!') {
      // Handle 401 Unauthorized without showing a toast
      logout();
      return; // Ensure the function exits here to avoid further execution
    }
  } else if (error.message) {
    errorMessage = error.message;
  }

  // Show the toast if there's an error message
  if (errorMessage) {
    toast(errorMessage, 'error');
  }
}
