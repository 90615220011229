<template>
  <div
    class="modal fade position-absolute"
    id="CommentModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="CommentModal"
    aria-hidden="true"
  >
    <div class="modal-dialog custom-modal-width modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header d-flex justify-content-between">
          <h5 class="modal-title">Comments</h5>
          <button
            type="button"
            class="btn-close text-primary"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body comment-body">
          <div v-if="comments.length">
            <div v-for="comment in comments" :key="comment.id" class="parent-comment">
              <div class="comment-header">
                <div class="comment-avatar-container">
                  <span
                    class="comment-avatar"
                    :style="{
                      backgroundColor: getColor(comment['commentedBy.fullName']),
                    }"
                  >
                    {{ comment["commentedBy.initials"] }}
                  </span>
                </div>
                <div class="comment-information">
                  <div class="comment-info">
                    <strong class="comment-author">{{
                      comment["commentedBy.fullName"]
                    }}</strong>
                    <small class="comment-date">{{
                      formatDate(comment.created_at)
                    }}</small>
                  </div>
                  <div class="comment-text">{{ comment.comment }}</div>
                </div>
              </div>

              <!-- Child comments section -->
              <div
                v-if="comment.childRows && comment.childRows.length"
                class="child-comments"
              >
                <div
                  v-for="child in childCommentsToShow(comment.id, comment.childRows)"
                  :key="child.id"
                  class="child-comment"
                >
                  <div class="comment-header">
                    <div class="comment-avatar-container">
                      <span
                        class="comment-avatar"
                        :style="{
                          backgroundColor: getColor(child['commentedBy.fullName']),
                        }"
                      >
                        {{ child["commentedBy.initials"] }}
                      </span>
                    </div>
                    <div class="comment-information">
                      <div class="comment-info">
                        <strong class="comment-author">{{
                          child["commentedBy.fullName"]
                        }}</strong>
                        <small class="comment-date">{{
                          formatDate(child.created_at)
                        }}</small>
                      </div>
                      <div class="comment-text">{{ child.comment }}</div>
                    </div>
                  </div>
                </div>

                <!-- View More / View Less toggle -->
                <span
                  v-if="comment.childRows.length > 1"
                  class="view-toggle text-muted"
                  @click="toggleChildComments(comment.id)"
                >
                  {{
                    childCommentsLimit[comment.id] === 1
                      ? `View ${comment.childRows.length - 1} more replies`
                      : "Hide replies"
                  }}
                </span>
              </div>

              <!-- Reply section -->
              <div class="reply-section">
                <span
                  class="text-muted btn btn-sm btn-outline-light"
                  style="cursor: pointer; font-size: 12px; margin-left: 25px"
                  @click="toggleReplyBox(comment.id)"
                >
                  <img src="../../assets/icons/back.png" alt="Reply" class="reply-icon" />
                  Reply
                </span>
                <div v-if="showReplyBox[comment.id]" class="reply-input mt-2">
                  <textarea
                    class="form-control"
                    placeholder="Write a reply..."
                    v-model="replyTexts[comment.id]"
                  ></textarea>
                  <button
                    class="btn btn-primary mt-1 small-btn text-end"
                    @click="addReply(comment.id)"
                  >
                    Reply
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <p>No comments available.</p>
          </div>
        </div>
        <div class="modal-footer">
          <textarea
            class="form-control"
            placeholder="Write a comment here..."
            v-model="newComment"
          ></textarea>
          <button class="btn btn-success mt-2 small-btn" @click="addComment">Send</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    ProjectInfoBoxId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      comments: [],
      newComment: "",
      replyTexts: {},
      showReplyBox: {},
      childCommentsLimit: {}, // Limit for child comments per parent comment
    };
  },
  watch: {
    ProjectInfoBoxId: {
      immediate: true, // Fetch comments on initial load
      handler(newId) {
        if (newId) {
          this.fetchComments(newId);
        }
      },
    },
  },
  methods: {
    getColor(name) {
      const colors = [
        "#FF5733",
        "#33FF57",
        "#3357FF",
        "#FF33A1",
        "#F0A500",
        "#9400D3",
        "#20B2AA",
        "#FF6347",
        "#8A2BE2",
        "#5F9EA0",
      ];
      const hash = [...name].reduce((acc, char) => acc + char.charCodeAt(0), 0);
      return colors[hash % colors.length];
    },
    // getColor(fullName) {
    //   
    //   return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    // },
    fetchComments(infoBoxAreaId) {
      axios
        .get(`${this.$apiEndPoint}/pibComment/${infoBoxAreaId}`)
        .then((response) => {
          this.comments = response.data;
          this.initializeChildLimits();
        })
        .catch((error) => this.$handleError(error));
    },
    initializeChildLimits() {
      this.comments.forEach((comment) => {
        this.childCommentsLimit[comment.id] = 1; // Set initial limit to 2
      });
    },
    addComment() {
      if (!this.newComment) return;
      const payload = {
        infoBoxAreaId: this.ProjectInfoBoxId,
        parentPibCommentId: null,
        comment: this.newComment,
        entityId: this.$LocalUser.user.employeeId,
        entityName: "Employee",
      };
      axios
        .post(`${this.$apiEndPoint}/pibComment`, payload)
        .then(() => {
          this.fetchComments(this.ProjectInfoBoxId);
          this.newComment = "";
        })
        .catch((error) => this.$handleError(error)); 
    },
    addReply(parentCommentId) {
      if (!this.replyTexts[parentCommentId]) return;
      const payload = {
        infoBoxAreaId: this.ProjectInfoBoxId,
        parentPibCommentId: parentCommentId,
        comment: this.replyTexts[parentCommentId],
        entityId: this.$LocalUser.user.employeeId,
        entityName: "Employee",
      };
      axios
        .post(`${this.$apiEndPoint}/pibComment`, payload)
        .then(() => {
          this.fetchComments(this.ProjectInfoBoxId);
          this.replyTexts[parentCommentId] = "";
          this.showReplyBox[parentCommentId] = false;
        })
        .catch((error) => this.$handleError(error)); 
    },
    toggleReplyBox(commentId) {
      this.showReplyBox[commentId] = !this.showReplyBox[commentId];
    },

    toggleChildComments(parentCommentId) {
      const currentLimit = this.childCommentsLimit[parentCommentId];
      this.childCommentsLimit[parentCommentId] = currentLimit === 1 ? undefined : 1; // Toggle between 2 and all
    },
    childCommentsToShow(parentCommentId, childRows) {
      const limit = this.childCommentsLimit[parentCommentId] || childRows.length;
      return childRows.slice(0, limit);
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const now = new Date();
      const diff = now - date;

      const seconds = Math.floor(diff / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      const months = Math.floor(days / 30);
      const years = Math.floor(months / 12);

      if (seconds < 60) return "just now";
      if (minutes < 60) return this.pluralize(minutes, "m");
      if (hours < 24) return this.pluralize(hours, "h");
      if (days < 30) return this.pluralize(days, "d");
      if (months < 12) return this.pluralize(months, "month");

      return this.pluralize(years, "year");
    },
    pluralize(value, unit) {
      return `${value}${unit}${value > 1 ? "" : ""}`;
    },
    // formatDate(dateString) {
    //   const date = new Date(dateString);
    //   const now = new Date();
    //   const diff = now - date;

    //   const seconds = Math.floor(diff / 1000);
    //   const minutes = Math.floor(seconds / 60);
    //   const hours = Math.floor(minutes / 60);
    //   const days = Math.floor(hours / 24);
    //   const months = Math.floor(days / 30);
    //   const years = Math.floor(months / 12);

    //   if (seconds < 60) return `${seconds}s`; // Show in seconds up to 59 seconds
    //   if (minutes < 60) return this.pluralize(minutes, "m");
    //   if (hours < 24) return this.pluralize(hours, "h");
    //   if (days < 30) return this.pluralize(days, "d");
    //   if (months < 12) return this.pluralize(months, "month");

    //   return this.pluralize(years, "year");
    // },
    // pluralize(value, unit) {
    //   return `${value}${unit}${value > 1 ? "" : ""} `;
    // },
  },
};
</script>

<style scoped>
.comment-body {
  max-height: 300px;
  overflow-y: auto;
}

.parent-comment {
  font-size: 12px; /* Smaller font size */
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.child-comments {
  margin-left: 20px;
  margin-top: 10px;
}

.child-comment {
  margin-bottom: 5px;
  font-size: 11px; /* Smaller font size for child comments */
}

.reply-section {
  margin-top: 10px;
  font-size: 11px; /* Smaller font size */
}

.reply-icon {
  width: 14px;
  height: 14px;
}

.reply-input-box {
  font-size: 10px; /* Smaller font size */
}

.view-more {
  cursor: pointer;
  color: #007bff;
  font-size: 11px;
}

.small-btn {
  font-size: 11px;
  padding: 5px 10px;
}

.view-toggle {
  cursor: pointer;
  font-size: 12px;
}

.comment-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 12px;
  text-align: center;
  line-height: 30px;
  color: #fff;
}

.comment-text {
  margin-top: 5px;
  font-size: 12px;
  line-height: 1.4;
  margin-bottom: 5px;
}

.comment-header {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between the avatar and the info block */
}

.comment-info {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.comment-info strong {
  font-size: 10px;
  font-weight: bold;
}

.comment-info small {
  font-size: 10px;
  color: #bbb; /* Lighter color for the date */
  margin-left: 10px;
}

.comment-information {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers the content vertically */
}

.comment-avatar-container {
  display: flex;
  align-items: center;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  box-shadow: none;
}

.text-primary {
  color: #007bff;
}

.text-muted {
  color: #6c757d;
}
textarea {
  font-size: 14px;
}
</style>
