<template>
  <div>
    <Header />
    <div class="content-Container">
      <Nav />
      <div class="containerChild position-fixed">
        <div class="container-fluid d-flex justify-content-between mt-2">
          <h4 class="mainHeadingUnderline">Design Projects</h4>
          <div class="d-flex" style="margin-right: 1.4%" v-if="$isManager('Designing')">
            <p class="mt-1">View :</p>
            <select
              style="
                margin-left: 3px;
                font-size: 12px;
                height: 30px;
                width: 150px;
                cursor: pointer;
                :focus {
                  outline: none;
                  box-shadow: none;
                }
              "
              class="form-select"
              v-model="selectedEmployeeId"
              @change="setSelectedEmployee"
            >
              <option value="All" selected>Team</option>
              <option
                v-for="employee in employees"
                :key="employee.employeeId"
                :value="employee.employeeId"
              >
                {{ employee["employees.firstName"] }}
                {{ employee["employees.lastName"] }}
              </option>
            </select>
          </div>
        </div>
        <div class="scrollable-container">
          <!-- Iterate over each stage and display data -->
          <div
            class="col-6 d-flex flex-column mt-2 ms-2"
            style="width: 23%"
            v-for="(stage, index) in stages"
            :key="index"
          >
            <div
              class="p-2 z-3 position-sticky z-2 top-0 d-flex flex-row align-content-center"
              :style="{
                borderBottom: '3px solid ' + titleColors[index],
                background: '#f8f8f8',
                zIndex: 10 - index,
              }"
            >
              <!-- // boxShadow: '0 0 5px rgba(0, 0, 0, 0.8)' -->
              <span
                class="dot"
                :style="{ backgroundColor: titleDotColors[index] }"
              ></span>
              <span>{{ stage.title }} ({{ stage.items.length }})</span>
            </div>
            <div
              class="card card-container mt-2"
              :id="'stage-' + index"
              @dragover.prevent
              @drop="dropItem(index)"
            >
              <div
                class="draggable-item"
                v-for="(item, itemIndex) in stage.items"
                :key="itemIndex"
                draggable="true"
                @dragstart="dragStart(item, stage, itemIndex)"
              >
                <p class="card-title p-2" @click="navigateToProjectProfile(item)">
                  <span style="cursor: pointer; font-weight: 600">{{
                    item.projectName
                  }}</span>
                  <br />
                  <span style="cursor: pointer; font-size: 12px">{{
                    item["customers.name"]
                  }}</span>
                  <br />
                  <span style="cursor: pointer; font-size: 12px">{{
                    item["customers.contactNo"]
                  }}</span>
                  <br />
                  <span style="cursor: pointer; font-size: 12px">{{
                    item["customers.email"]
                  }}</span>
                  <br />
                  <span style="cursor: pointer; font-size: 12px">{{
                    item["addresses.address"]
                  }}</span>
                  <br />
                  <span style="font-size: 12px" class="text-muted"
                    >Designer &nbsp; : {{ item["employees.firstName"] }}
                    {{ item["employees.lastName"] }}</span
                  >
                  <br />
                  <span style="font-size: 12px" class="text-muted"
                    >Start Date : {{ item.designStartDate }}</span
                  >
                  <br />
                  <span
                    v-if="stage.title === 'Sent For Execution'"
                    style="font-size: 12px"
                    class="text-muted"
                    >End Date : {{ item.designEndDate }}</span
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Header from "../Header.vue";
import Nav from "../Nav.vue";

export default {
  name: "DesignProjectListBoard",
  components: {
    Header,
    Nav,
  },
  data() {
    return {
      selectedEmployeeId: this.$LocalUser.user.employeeId,
      employees: [],
      drag: true,
      stages: [
        { title: "Discovery Session", items: [] },
        { title: "3d Design", items: [] },
        { title: "Material Selection", items: [] },
        { title: "2d Drafting", items: [] },
        { title: "Sign Off Document", items: [] },
        { title: "Sent For Execution", items: [] },
      ],
      titleColors: [
        "rgba(216, 174, 23, 0.25)",
        "rgba(131, 216, 23, 0.25)",
        "rgba(34, 79, 73, 0.25)",
        "rgba(96, 23, 216, 0.25)",
        "rgba(174, 27, 210, 0.25)",
        "rgba(23, 135, 216, 0.25)",
      ],
      titleDotColors: ["#D8ae17", "#83D817", "#224f49", "#6017D8", "#AE1BD2", "#1787D8"],
      draggedItem: null,
      sourceStage: null,
      sourceItemIndex: null,
    };
  },
  mounted() {
    this.selectedEmployeeId = this.$LocalUser.user.employeeId;
    this.fetchAllStageData();
    this.fetchEmployees();
  },

  methods: {
    setSelectedEmployee() {
      this.fetchAllStageData();
    },
    fetchEmployees() {
      axios
        .get(`${this.$apiEndPoint}/employee/myTeam/Designing`)
        .then((response) => {
          const filteredData = response.data.filter((item) => item !== null);
          // Filter out employees with null first name or last name
          this.employees = filteredData;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    async fetchAllStageData() {
      const promises = this.stages.map((stage) => this.fetchStageData(stage));
      await Promise.all(promises);
    },
    async fetchStageData(stage) {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/designProjects/designStage/${encodeURIComponent(
            stage.title
          )}/${this.selectedEmployeeId}`
        );
        stage.items = response.data;
        this.drag = true;
      } catch (error) {
        this.$handleError(error);
      }
    },
    navigateToProjectProfile(data) {
      // Navigate to ProjectProfile page with project ID as parameter
      this.$router.push({
        name: "DesignProjectProfile",
        params: {
          Id: data.id,
          projectName: data.projectName,
        },
      });
    },
    dragStart(item, sourceStage, sourceItemIndex) {
      if (this.drag === true) {
        this.draggedItem = item;
        this.sourceStage = sourceStage;
        this.sourceItemIndex = sourceItemIndex;
      }
    },
    dropItem(targetStageIndex) {
      if (this.drag === true) {
        // Move the item to the target stage
        const targetStage = this.stages[targetStageIndex];
        if (this.sourceStage !== targetStage) {
          this.sourceStage.items.splice(this.sourceItemIndex, 1);
          targetStage.items.push(this.draggedItem);
          // Make an API call to update the status
          this.drag = false;
          this.updateStatus(targetStage, this.draggedItem.id); // Pass the item id
        }
      }
    },
    async updateStatus(stage, itemId) {
      try {
        // Make an API call to update the status
        await axios.put(`${this.$apiEndPoint}/designProjects/status/${itemId}`, {
          designStage: stage.title,
        });
        this.fetchStageData(stage); // Fetch updated data for the stage
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>

<style scoped>
/* Style for horizontal scrolling container */

.card-title {
  border-radius: 5px;
  margin: 10px;
  background-color: #eaeaea;
  padding: 5px;
}

.DesignStageContainer {
  height: 100%;
  position: relative;
}

.card-container {
  height: 100%;
  max-height: 100%;
  overflow: auto;
}

.card-title {
  cursor: grab;
}

.stage-title-container {
  display: flex;
  align-items: center;
  padding: 5px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 6px;
}
.scrollable-container {
  max-width: 99%;
  margin: 0% 1%;
  padding: 0 0.5%;
  height: 92%;
  overflow-x: auto;
  z-index: 99;
  display: flex;

  /* Enable horizontal scrolling */

  /* Disable vertical scrolling */
}

@media (max-width: 767px) {
  .scrollable-container {
    overflow-x: scroll;
    width: 120%;
    position: fixed;
    height: 80%;
  }

  .container-fluid.row .col-6 {
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap; /* Prevent wrapping of cards */
    flex-wrap: nowrap; /* Prevent flex items from wrapping */
    display: flex;
    flex-direction: row;
  }

  .col-6 {
    flex: 0 0 auto; /* Fix width of each card */
    width: 100%; /* Allow each card to take full width */
  }
  .DesignStageContainer {
    display: flex;

    overflow-x: scroll;
  }
}
</style>
