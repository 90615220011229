<template>
  <!-- Custom Modal -->
  <div class="modal fade custom-modal" id="taskModal" aria-labelledby="taskModalLabel">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- Body Section -->
        <div class="modal-body">
          <form @submit.prevent="submitForm" class="border-0">
            <!-- Header Section -->
            <div class="modal-header d-flex align-items-center" style="padding: 5px">
              <div class="col-md-5">
                <input
                  ref="titleInput"
                  class="modal-title"
                  style="border: none; outline: none; background-color: none"
                  v-model="localTask.title"
                  placeholder="Write Task Title"
                  id="taskModalLabel"
                  :class="{ 'is-invalid': titleError }"
                />
                <div v-if="titleError" class="text-danger" style="font-size: 0.875rem">
                  Please enter a title for the subtask.
                </div>

                <small
                  style="background-color: none; font-size: small; cursor: pointer"
                  v-if="localTask.parentTaskId"
                  @click="fetchSubTaskDetails(localTask.parentTaskId)"
                >
                  {{ parentTaskTitle ? truncateText(parentTaskTitle, 35) : " " }}
                </small>
              </div>

              <div class="col-md-7 d-flex justify-content-end">
                <!-- Mark as Completed Button -->
                <button
                  v-if="
                    localTask.id &&
                    !isImageActive &&
                    localTask.status === 'Pending' &&
                    (this.localTask.createdById === this.$LocalUser.user.id ||
                      this.localTask.assignedTo === this.$LocalUser.user.id)
                  "
                  type="button"
                  class="btn btn-sm ml-auto btn-outline-success"
                  data-bs-dismiss="modal"
                  style="font-size: 12px"
                  @click="markAsCompleted"
                >
                  Mark as Completed
                </button>

                <!-- Task Completed Button -->
                <button
                  v-if="
                    localTask.id &&
                    !isImageActive &&
                    localTask.status === 'Completed' &&
                    (this.localTask.createdById === this.$LocalUser.user.id ||
                      this.localTask.assignedTo === this.$LocalUser.user.id)
                  "
                  type="button"
                  class="btn btn-sm ml-auto btn-success text-white"
                  data-bs-dismiss="modal"
                  style="font-size: 12px"
                >
                  Task Completed
                </button>
              </div>

              <div class="col-md-1 d-flex justify-content-end">
                <img
                  src="../../assets/icons/back.png"
                  class="closeImg"
                  data-bs-toggle="modal"
                  data-bs-target="#taskModal"
                  aria-label="Close"
                  alt=""
                />
              </div>
            </div>

            <hr style="color: black; margin-top: 0%" />

            <!-- Task Description Input -->
            <div class="d-flex align-items-center mb-3">
              <div class="col-md-4">
                <label
                  for="taskDescription"
                  class="form-label"
                  style="font-size: 0.875rem"
                >
                  Task Description:
                </label>
              </div>
              <div class="col-md-8">
                <textarea
                  id="taskDescription"
                  v-model="localTask.description"
                  class="form-control"
                  style="font-size: 0.875rem; width: 100%"
                  placeholder="Enter task description"
                ></textarea>
              </div>
            </div>

            <div class="d-flex align-items-center mb-3">
              <div class="col-md-4">
                <label for="taskDate" class="form-label" style="font-size: 0.875rem">
                  Date:
                </label>
              </div>
              <div class="col-md-8">
                <input
                  ref="dateInput"
                  type="date"
                  id="taskDate"
                  v-model="localTask.date"
                  class="form-control"
                  style="font-size: 0.875rem; width: 100%"
                  :class="{ 'is-invalid': dateError }"
                />
                <div v-if="dateError" class="text-danger" style="font-size: 0.875rem">
                  Please select a date for the subtask.
                </div>
              </div>
            </div>

            <!-- Priority Dropdown -->
            <div class="d-flex align-items-center mb-3">
              <div class="col-md-4">
                <label for="priority" class="form-label" style="font-size: 0.875rem">
                  Priority:
                </label>
              </div>
              <div class="col-md-8">
                <select
                  id="priority"
                  v-model="localTask.priority"
                  class="form-select"
                  style="font-size: 0.875rem"
                >
                  <option value="Low">Low</option>
                  <option value="Medium">Medium</option>
                  <option value="High">High</option>
                </select>
              </div>
            </div>

            <div class="d-flex align-items-center mb-3">
              <div class="col-md-4">
                <label for="assignee" class="form-label" style="font-size: 0.875rem">
                  Assignee:
                </label>
              </div>
              <div class="col-md-8">
                <select
                  id="assignee"
                  v-model="localTask.assignedTo"
                  class="form-select"
                  style="font-size: 0.875rem"
                >
                  <option
                    v-for="assignee in assignees"
                    :key="assignee.employeeUserId"
                    :value="assignee.employeeUserId"
                  >
                    {{ assignee.firstName }} {{ assignee.lastName }}
                  </option>
                </select>
              </div>
            </div>

            <!-- File Upload -->
            <div class="d-flex align-items-center mb-3">
              <div class="col-md-4">
                <label for="attachment" class="form-label" style="font-size: 0.875rem">
                  Attachment:
                </label>
              </div>
              <div class="col-md-8">
                <input
                  type="file"
                  id="attachment"
                  class="form-control"
                  style="font-size: 0.875rem; width: 100%; cursor: pointer"
                  ref="taskAttachment"
                  @change="handleFileUpload"
                />
                <span
                  v-if="localTask.awsFileId"
                  style="font-size: 0.875rem; width: 100%; cursor: pointer"
                  @click="
                    $viewFile(
                      localTask['awsFiles.awsFileName'],
                      localTask['awsFiles.fileName'],
                      'taskAttachment',
                      'Task'
                    )
                  "
                  >{{ localTask["awsFiles.fileName"] }}</span
                >
              </div>
            </div>

            <br />

            <!-- Save/Update Button -->
            <div class="d-flex justify-content-center gap-5 mb-3">
              <!-- Update Button -->
              <button
                v-if="
                  localTask.id &&
                  (this.localTask.createdById === this.$LocalUser.user.id ||
                    this.localTask.assignedTo === this.$LocalUser.user.id) &&
                  !isImageActive &&
                  localTask.status !== 'Completed'
                "
                type="button"
                class="btn btn-primary me-2"
                style="width: 20%; height: 30px; padding: 2px"
                @click="submitTask"
              >
                Update
              </button>

              <!-- Save Button -->
              <button
                v-else-if="
                  !localTask.id && !isImageActive && localTask.status !== 'Completed'
                "
                type="button"
                class="btn btn-primary me-2"
                style="width: 20%; height: 30px; padding: 2px"
                @click="submitTask"
              >
                Save
              </button>

              <button
                v-if="isImageActive"
                type="button"
                class="btn btn-primary me-2"
                style="width: fit-content; font-size: 13px"
                @click="addSubTask"
              >
                Add Subtask
              </button>

              <!-- Delete / Cancel Button -->
              <!-- Delete Button -->
              <button
                v-if="
                  localTask.id &&
                  !isImageActive &&
                  (this.localTask.createdById === this.$LocalUser.user.id ||
                    this.localTask.assignedTo === this.$LocalUser.user.id)
                "
                type="button"
                class="btn btn-danger me-2"
                style="width: 20%; height: 30px; padding: 2px"
                @click="deleteTask"
              >
                Delete
              </button>

              <!-- Cancel Button -->
              <button
                v-else-if="localTask.id == null"
                type="button"
                class="btn btn-secondary me-2"
                style="width: 20%; height: 30px; padding: 2px"
                @click="closeModal"
              >
                Cancel
              </button>
            </div>

            <hr style="color: black; margin-top: 0%" />

            <div class="d-flex justify-content-between mb-3">
              <!-- Button for Subtask -->
              <button
                v-if="localTask.id"
                type="button"
                @click="toggleDisplay('subtasks')"
                :class="['btn', 'btn-custom']"
                :style="{ backgroundColor: isButtonActive ? 'pink' : 'transparent' }"
              >
                <template v-if="localTask.status === 'Pending'">
                  Add SubTask
                  <!-- Plus Icon, only shown when status is 'Pending' -->
                  <img
                    v-if="
                      this.localTask.createdById === this.$LocalUser.user.id ||
                      this.localTask.assignedTo === this.$LocalUser.user.id
                    "
                    @click.stop="startAddingSubTask"
                    src="../../assets/icons/plusIconSales.png"
                    :style="{
                      backgroundColor: isImageActive ? 'grey' : 'rgba(0, 0, 0, 0.6)',
                      padding: '3px',
                      borderRadius: '3px',
                    }"
                    class="plus-icon"
                    alt="Add SubTask"
                  />
                </template>
                <template v-else> View SubTask </template>
              </button>

              <!-- Button for Activity (disabled when isImageActive is true) -->
              <button
                v-if="localTask.id"
                type="button"
                @click="toggleDisplay('activities')"
                :class="['btn', 'btn-custom']"
                :style="{ backgroundColor: isActivityActive ? 'pink' : 'transparent' }"
                :disabled="isImageActive"
              >
                Activity
                <img
                  src="../../assets/icons/activityIcon.png"
                  alt="Activity"
                  style="width: 15px; height: 15px; margin-bottom: 1.6%"
                />
              </button>
            </div>

            <!-- sub Task card contents -->

            <div class="row col-md-12" v-if="showSubTasks && localTask.id">
              <div
                v-for="subTask in subtasks"
                :key="subTask.id"
                @click="fetchSubTaskDetails(subTask.id)"
                class="col-md-6 task-card"
              >
                <!-- Subtask Title -->
                <div class="row d-flex align-items-center">
                  <div class="col-1 d-flex align-items-center justify-content-center">
                    <img
                      v-if="subTask.status == 'Completed'"
                      class="tick-icon"
                      src="../../assets/icons/completedTask.png"
                      alt="Green Tick"
                    />
                    <img
                      v-else
                      class="tick-icon"
                      src="../../assets/icons/T.png"
                      alt="Grey Tick"
                    />
                  </div>
                  <div
                    class="col-10"
                    style="font-size: 10px; text-overflow: ellipsis; overflow: hidden"
                  >
                    <p class="task-title mt-1">
                      {{ subTask.title ? truncateText(subTask.title, 25) : "" }}
                    </p>
                  </div>
                </div>

                <!-- Subtask Description -->
                <div class="row d-flex align-items-center">
                  <div class="col-1"></div>
                  <div
                    class="col-10"
                    style="font-size: 6px; text-overflow: ellipsis; overflow: hidden"
                  >
                    <p class="task-description">
                      {{
                        subTask.description
                          ? truncateText(subTask.description, 25)
                          : "No Description"
                      }}
                    </p>
                  </div>
                </div>

                <!-- Subtask Assignee -->
                <div class="row d-flex align-items-center" style="margin-top: 7px">
                  <div class="col-10 d-flex align-items-center">
                    <img
                      class="profile-icon"
                      src="../../assets/icons/profile.png"
                      alt="Profile"
                    />
                    <label class="task-label">Assignee:</label>
                    <span class="task-assignee">
                      {{ subTask["users.firstName"] }} {{ subTask["users.lastName"] }}
                    </span>
                  </div>
                </div>

                <!-- Subtask Due Date and Priority -->
                <div class="row col-md-12 d-flex align-items-center" style="height: 28px">
                  <div class="col-md-1">
                    <img
                      class="profile-icon mb-1"
                      src="../../assets/icons/calandar.png"
                      alt="Calendar"
                    />
                  </div>
                  <div class="col-md-8 d-flex align-items-start">
                    <label style="font-size: 12px">Due Date:</label>
                    <span
                      style="
                        font-size: 10px;
                        font-weight: bold;
                        margin-left: 10px;
                        margin-top: 2px;
                      "
                    >
                      {{ $formatDateDMY(subTask.date) }}
                    </span>
                  </div>
                  <div class="col-1">
                    <button
                      v-if="subTask.priority"
                      :class="getPriorityClass(subTask.priority)"
                      type="button"
                      class="priority-btn mb-2 ml-auto"
                      style="
                        font-size: 12px;
                        padding: 4px 6px;
                        width: 60px;
                        line-height: 1;
                        margin-left: 0px;
                      "
                    >
                      {{ subTask.priority }}
                    </button>
                  </div>
                </div>

                <hr style="margin: 1% 0%" />

                <!-- Attachments, Subtasks, and Collaborators -->
                <div class="row" style="margin-top: 5px">
                  <div class="col-4 d-flex align-items-center">
                    <img
                      v-if="subTask.awsFileId"
                      src="../../assets/icons/attachment.png"
                      alt="taskAttachment"
                      class="attachment-icon"
                    />
                    <!-- <span class="icon-text">2</span> -->
                    <img
                      v-if="subTask.subtaskCnt > 0"
                      src="../../assets/icons/subtask.png"
                      class="subtask-icon"
                    />
                    <span v-if="subTask.subtaskCnt > 0" class="icon-text">{{
                      subTask.subtaskCnt
                    }}</span>
                  </div>

                  <!-- Collaborators -->
                  <div class="col-md-8 mt-1 d-flex justify-content-end">
                    <div
                      v-if="subTask.subTaskCollabs && subTask.subTaskCollabs.length > 0"
                      class="collaborator-container"
                    >
                      <span
                        v-for="(collab, index) in subTask.subTaskCollabs"
                        :key="index"
                        class="collaborator-initials"
                        :style="{ zIndex: index }"
                      >
                        {{ collab.initials }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row col-md-12" v-if="showActivities && localTask.id">
              <div v-for="activity in activities" :key="activity.id" class="col-md-12">
                <div class="row d-flex align-items-center">
                  <div class="col-1 d-flex align-items-center justify-content-center">
                    <img
                      class="profile-icon"
                      src="../../assets/icons/profile.png"
                      alt="Profile"
                    />
                  </div>
                  <div
                    class="col-10"
                    style="font-size: 10px; text-overflow: ellipsis; overflow: hidden"
                  >
                    <p class="task-title mt-1">{{ activity.comment }}</p>
                  </div>
                </div>

                <div class="row d-flex align-items-center">
                  <div class="col-1"></div>
                  <div
                    class="col-10"
                    style="font-size: 6px; text-overflow: ellipsis; overflow: hidden"
                  >
                    <p class="task-description">
                      Date: {{ $formatDateDMY(activity.created_at) }} Time:
                      {{ formatTo12Hour(activity.updated_at) }}
                    </p>

                    <!-- <p class="task-description">
                      Time: {{ formatTo12Hour(activity.updated_at) }}
                    </p> -->
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    selectedTaskId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      localTask: {
        id: null,
        title: "",
        description: "",
        assignedTo: null,
        date: null,
        priority: "",
        parentTaskId: null, // Set the parentId to the selectedTaskId prop
        status: null,
        parentTaskTitle: null,
        awsFileId: null,
        fileName: null,
        createdById: null,
      },
      selectedUserId: null,
      activeButton: null,
      originalTaskData: null,
      isButtonActive: true, // Make the button active by default
      isImageActive: false,
      isActivityActive: false,
      isDisplayingSubTasks: false,
      showSubTasks: true,
      showActivities: false,
      parentTaskTitle: "",
      awsFileName: "",
      assignees: [],
      subtasks: [],
      activities: [],
      searchString: "",
    };
  },
  mounted() {
    this.fetchAssignees();

    // //by default when modal mounted its fetching default assignees
    if (this.selectedTaskId) {
      this.fetchAssignees();
      //here if any selectedId is present fething card details
      this.fetchTaskDetails();
    }
  },

  watch: {
    selectedTaskId(newValue) {
      if (newValue !== null) {
        this.fetchTaskDetails();
      } else {
        this.localTask.id = newValue;
        this.localTask = {};
        this.isImageActive = false;
      }
    },
  },

  methods: {
    formatTo12Hour(datetime) {
      const time = datetime.split(" ")[1]; // Extract time part
      const [hours, minutes, seconds] = time.split(":");
      const period = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12; // Convert 0 to 12 for midnight
      return `${formattedHours}:${minutes}:${seconds} ${period}`;
    },

    async submitTask() {
      // Validate title
      if (!this.localTask.title) {
        this.$refs.titleInput.focus();
        // this.$toast("Please enter a task title", "warning"); // Optional message
        return; // Stop function execution
      }

      // Validate date
      if (!this.localTask.date) {
        this.$refs.dateInput.focus();
        // this.$toast("Please select a date", "warning"); // Optional message
        return; // Stop function execution
      }

      // Proceed with task saving if validation passes
      try {
        if (this.localTask.id) {
          await this.submitForm(); // Update existing task
        } else {
          await this.submitForm(); // Create new task
        }
      } catch (error) {
        console.error("Error saving task:", error);
        this.$toast("Failed to save task", "error");
      }
    },

    handleFileUpload() {
      const file = this.$refs.taskAttachment.files[0];
      console.log(file);
      if (file) {
        axios
          .post(`${this.$apiEndPoint}/company/putFileSignedURL`, {
            fileName: file.name,
            fileSize: file.size,
            contentType: file.type,
            fileCategory: "taskAttachment",
            moduleName: "Task",
          })
          .then((response) => {
            const signedUrl = response.data.s3URL;
            this.localTask.awsFileId = response.data.id; // Update awsFileId
            this.localTask.fileName = file.name; // Update fileName
            console.log(this.localTask);
            console.log(response.data);
            const xhr = new XMLHttpRequest();
            xhr.open("PUT", signedUrl, true);
            xhr.setRequestHeader("Content-Type", file.type);

            xhr.onreadystatechange = () => {
              if (xhr.readyState === 4 && xhr.status === 200) {
                console.log("File uploaded successfully");
              } else if (xhr.readyState === 4) {
                console.error("Error uploading file to S3:", xhr.responseText);
              }
            };
            xhr.send(file);
          })
          .catch((error) => {
            this.$handleError(error);
          });
      }
    },

    resetSubTaskForm() {
      console.log(
        `Clearing subtask form fields for Parent Task ID: ${this.localTask.parentTaskId}`
      );

      // Store the current task title as the parentTaskTitle before clearing
      this.parentTaskTitle = this.localTask.title;
      console.log("Stored parentTaskTitle:", this.parentTaskTitle);

      this.isAddingSubTask = true;

      // Reset all fields except for parentTaskId
      this.localTask.title = "";
      this.localTask.description = "";
      this.localTask.assignedTo = null;
      this.localTask.date = null;
      this.localTask.priority = "";
      this.localTask.status = null;
      this.localTask.awsFileId = null;
      this.localTask.fileName = null;

      // Retain parentTaskId and parentTaskTitle
      this.localTask.parentTaskId = this.localTask.id; // Retain parent task ID

      // Ensure parentTaskTitle is retained, not overwritten
      console.log("Subtask form reset with parentTaskId and parentTaskTitle:");
      console.log("Parent Task ID:", this.localTask.parentTaskId);
      console.log("Parent Task Title:", this.parentTaskTitle);
    },

    startAddingSubTask() {
      if (!this.isImageActive) {
        // Store original task data before resetting the form
        this.originalTaskData = { ...this.localTask };
        console.log("original task data:", this.originalTaskData);
      }
      this.isImageActive = !this.isImageActive; // Show Save Subtask button
      this.resetSubTaskForm(); // Reset the subtask form
    },

    toggleDisplay(type) {
      this.activeButton = type;
      if (type === "subtasks") {
        this.showSubTasks = true;
        this.showActivities = false;
        this.isButtonActive = true;
        this.isImageActive = false;
        this.isActivityActive = false;
        // this.restoreOriginalTaskData();
      } else if (type === "activities") {
        this.showSubTasks = false;
        this.showActivities = true;
        this.isButtonActive = false;
        this.isActivityActive = true;
        this.isImageActive = false;
      }
    },

    displaySubTasks() {
      this.isDisplayingSubTasks = true;
      console.log("Fetched SubTAsks:", this.subtasks);
    },

    startAddingActivity() {
      this.isAddingActivity = true; // Show Save Subtask button
      console.log("Fetched Task Activity:", this.activities);
    },

    truncateText(text, length) {
      if (text && text.length > length) {
        return text.substring(0, length) + "...";
      }
      return text;
    },

    async addSubTask() {
      try {
        // Validate title field
        if (!this.localTask.title || this.localTask.title.trim() === "") {
          console.error("Title is required.");
          // alert("Please enter a title for the subtask.");
          this.$refs.titleInput.focus(); // Focus on the title input field for the user to correct it
          // this.$toast("Please enter task title", "warning"); // Optional message
          return;
        }

        // Validate date field
        if (!this.localTask.date) {
          console.error("Date is required.");
          // alert("Please select a date for the subtask.");
          this.$refs.dateInput.focus(); // Focus on the date input field for the user to correct it
          // this.$toast("Please enter task date", "warning"); // Optional message
          return;
        }

        // Prepare subtask data for the API
        const subtaskData = {
          title: this.localTask.title.trim(),
          parentTaskId: this.localTask.parentTaskId, // Assuming the parent task ID is available
          description: this.localTask.description || "",
          assignedTo: this.localTask.assignedTo || null,
          date: this.localTask.date,
          priority: this.localTask.priority,
          status: this.localTask.status || null,
          awsFileId: this.localTask.awsFileId || null,
        };

        // Make the API call to add the subtask
        const response = await axios.post(`${this.$apiEndPoint}/tasks`, subtaskData);
        const newSubtask = response.data;

        console.log("Subtask added successfully:", newSubtask);

        // Emit event to the parent component with the newly added subtask
        this.$emit("subtaskAdded", newSubtask);
        await this.fetchSubTaskDetails(this.localTask.parentTaskId);

        this.isImageActive = false; // Optionally reset the image active state or modal state
      } catch (error) {
        console.error(
          "Error adding subtask:",
          error.response ? error.response.data : error.message
        );
      }
    },

    deleteSubtask() {
      console.log(`Marking subtask with ID: ${this.localTask.id} as deleted`);

      // Set the deleted flag to true instead of deleting it
      this.localTask.deleted = true;

      // Emit an event to notify the parent component about the deletion
      this.$emit("subtask-deleted", this.localTask.id);
      this.closeModal(); // Optionally close the modal after marking as deleted
    },

    getPriorityClass(priority) {
      if (!priority) return ""; // Default case for missing or invalid priority
      priority = String(priority); // Ensure priority is a string
      switch (priority) {
        case "High":
          return "priority-high";
        case "Medium":
          return "priority-medium";
        case "Low":
          return "priority-low";
        default:
          return "priority-none"; // Optionally handle unexpected values
      }
    },

    getPriorityLabel(priority) {
      if (!priority) return "None"; // Default case for missing or invalid priority
      priority = String(priority); // Ensure priority is a string
      switch (priority) {
        case "1":
          return "High";
        case "2":
          return "Medium";
        case "3":
          return "Low";
        default:
          return "None"; // Handle unexpected values
      }
    },

    async markAsCompleted() {
      const previousStatus = this.localTask.status; // Store the previous status

      try {
        // Toggle the task status
        if (this.localTask.status === "Pending") {
          this.localTask.status = "Completed";
        } else if (this.localTask.status === "Completed") {
          this.localTask.status = "Pending";
        }

        // Update task on the server
        await axios.put(
          `${this.$apiEndPoint}/tasks/updateTask/${this.localTask.id}`,
          this.localTask
        );

        // Emit the task ID to the parent for UI update
        this.$emit("task-updated", this.localTask.id);
        // Optionally provide user feedback here (e.g., toast notification)
      } catch (error) {
        this.$handleError(error);
        this.localTask.status = previousStatus;
      }
    },

    async fetchSubTaskDetails(taskId) {
      console.log(`Fetching details for task ID: ${taskId}`); // Debug log
      try {
        const response = await axios.get(`${this.$apiEndPoint}/tasks/${taskId}`);
        const taskDetails = response.data;

        // Update localTask with the fetched task details
        this.localTask = {
          id: taskDetails.id,
          title: taskDetails.title,
          description: taskDetails.description,
          assignedTo: taskDetails.assignedTo,
          date: taskDetails.date,
          priority: taskDetails.priority,
          parentTaskId: taskDetails.parentTaskId,
          status: taskDetails.status,
          awsFileId: taskDetails.awsField,
          fileName: taskDetails.fileName,
        };

        // Set parentTaskTitle from the fetched data, if available
        this.parentTaskTitle = taskDetails.parentTaskTitle || ""; // Default to empty string if undefined
        this.isImageActive = false;

        // Update subtasks (if relevant)
        this.subtasks = taskDetails.subtasks || [];
        console.log("Fetched Task Details:", taskDetails);

        await this.fetchActivity(taskId);
      } catch (error) {
        console.error("Error fetching task details:", error);
      }
    },

    async fetchActivity(taskId) {
      console.log(`Fetched activity of ID: ${taskId}`);
      try {
        const response = await axios.get(`${this.$apiEndPoint}/tasks/comments/${taskId}`);
        this.activities = response.data; // Store the fetched comments in the activity array
        console.log("Activity fetched:", this.activity); // Optional: log the fetched data
      } catch (error) {
        console.error("Error fetching activity:", error);
      }
    },

    async fetchTaskDetails() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/tasks/${this.selectedTaskId}`
        );

        // Assign the main task details to localTask
        this.localTask = {
          ...response.data,
          // Set parentTaskId to selectedTaskId
        };

        // Fetch the sub-tasks if they exist in the response
        if (response.data.subtasks) {
          this.subtasks = response.data.subtasks; // Assign the subtasks to the array
        } else {
          this.subtasks = []; // Set an empty array if no subtasks exist
        }

        this.parentTaskTitle = response.data.parentTaskTitle; // Set the parent task title
        await this.fetchActivity(this.selectedTaskId);
        this.isImageActive = false;
        console.log("Fetched Task:", this.localTask);
        console.log("Fetched Subtasks:", this.subtasks);
        console.log("fetched activity:", this.activities);
      } catch (error) {
        this.$handleError(error);
      }
    },

    async deleteTask() {
      try {
        console.log(`Deleting task with ID: ${this.localTask.id}`);
        await axios.delete(`${this.$apiEndPoint}/tasks/${this.localTask.id}`);
        this.$emit("task-deleted", this.localTask.id);
        this.closeModal();
      } catch (error) {
        this.$handleError(error);
      }
    },

    async fetchAssignees() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/employee/getByRoles`);
        this.assignees = response.data.map((employee) => ({
          employeeId: employee.employeeId,
          employeeUserId: employee.employeeUserId,
          firstName: employee["employees.firstName"],
          lastName: employee["employees.lastName"],
        }));

        console.log("Employee data:", this.employees);
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    },

    async submitForm() {
      this.localTask;
      try {
        if (this.localTask.id) {
          const response = await axios.put(
            `${this.$apiEndPoint}/tasks/updateTask/${this.localTask.id}`,
            this.localTask
          );
          console.log(response.data);

          console.log("Emitting task ID:", this.localTask.id);
          // Emit the updated task ID to parent
          this.$emit("updateTaskById", this.localTask.id);
        } else {
          const response = await axios.post(
            `${this.$apiEndPoint}/tasks/`,
            this.localTask
          );

          console.log("Task created successfully:", response.data);

          this.$emit("submit-form", response.data);
        }

        this.resetForm();
        this.closeModal();
      } catch (error) {
        this.$handleError(error);
      }
    },

    resetForm() {
      this.localTask = {
        id: null,
        title: "",
        description: "",
        assignedTo: null,
        date: null,
        priority: "",
        parentTaskId: null,
        status: null,
        awsFileName: null,
      };

      // Reset file input field
      if (this.$refs.taskAttachment) {
        this.$refs.taskAttachment.value = null;
      }
    },

    closeModal() {
      const modalElement = document.getElementById("taskModal");
      if (modalElement) {
        modalElement.style.display = "none";
        modalElement.classList.remove("show");
        document.body.classList.remove("modal-open");
        const backdrop = document.querySelector(".modal-backdrop");
        if (backdrop) {
          backdrop.remove();
        }
        this.localTask.id = null;
      }
    },
  },
};
</script>

<style scoped>
.btn-custom:disabled {
  opacity: 0.5;
  /* Makes the button look disabled */
  cursor: not-allowed;
}

.collaborator-container {
  position: relative;
  display: flex;
  align-items: end;
}

.collaborator-initials {
  font-size: 8px;
  background-color: #4884f7;
  border-radius: 50%;
  color: #fcfcfc;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: -8px;
  /* Adjust this to control overlap */
  border: 2px solid #fff;
  /* Optional: adds a white border around each badge */
}

.btn-custom {
  border: 1px solid black;
  /* Remove border */
  padding: 8px;
  /* Add padding for better spacing */
  transition: background-color 0.3s ease, transform 0.3s ease;
  /* Smooth transition for background color and scale */
}

.btn-custom:hover {
  background-color: #ff7cb2;
  border: 1px solid black;
  transform: scale(1.05);
  /* Slightly enlarge the button on hover */
  color: rgb(26, 25, 25);
  /* Change text color on hover */
}

.plus-icon {
  height: 15px;
  width: 15px;
  transition: background-color 0.3s ease;
}

.btn:disabled {
  background-color: #cccccc;
  /* Light grey */
  cursor: not-allowed;
}

.btn-custom {
  width: 25%;
  /* Both buttons will have equal width */
  height: 30px;
  font-size: 11px;
  font-weight: bolder;
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: rgb(114, 114, 114);
  gap: 8px;
  border-radius: 6px;
  transition: background-color 0.3s ease, transform 0.4s ease;
  text-transform: uppercase;
}

.btn-custom img {
  width: 20px;
  height: 19px;
}

.task-card button {
  border: none;
  color: white;
  font-size: 10px;
  padding: 6px 6px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.priority-low {
  background-color: lightpink;
}

.priority-medium {
  background-color: rgb(255, 106, 0);
}

.priority-high {
  background-color: rgb(228, 56, 56);
}

.btn-custom:active {
  transform: scale(0.98);
  /* Give a pressed effect */
}

.subtask-icon {
  width: 12px;
  height: 10px;
  margin-top: none;
  padding-left: 3px;
}

.attachment-icon {
  width: 12px;
  height: 10px;
  margin-bottom: none;
  padding-left: 3px;
}

.task-card:hover {
  background-color: rgb(255, 255, 255);
  /* Slightly darker grey on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  /* Elevate the shadow on hover */
  transform: translateY(-5px);
  /* Slight upward movement on hover */
}

.cards {
  width: 100%;
  padding: 10px 10px;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  /* Allows wrapping to the next line */
  background-color: #f8f8f8;
  justify-content: flex-start;
  align-items: stretch;
  /* Ensures all items stretch to the same height */
}

.task-card {
  background-color: white;
  border-radius: 10px;
  padding: 10px 15px 4px;
  margin-left: 8px;
  margin-top: 1%;
  margin-right: 0px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  flex-basis: calc(50% - 20px);
  /* Ensure 2 cards per row */
  box-sizing: border-box;
  /* Include padding and border in width */
}

.task-title {
  margin: 0;
  font-weight: bold;
  font-size: 12px;
  height: 20px;
}

.task-description {
  margin: 0;
  font-size: 10px;
  height: 20px;
}

.task-label {
  font-size: 12px;
  margin-left: 10px;
}

.task-assignee {
  font-size: 10px;
  font-weight: bold;
  margin-left: 10px;
}

.task-date {
  font-size: 10px;
  font-weight: bold;
  margin: 0 5px 0 0;
}

.profile-icon,
.attachment-icon,
.subtask-icon {
  width: 15px;
  height: 15px;
}

.tick-icon {
  width: 15px;
  height: 15px;
  margin-left: 13px;
  margin-top: 4px;
}

.icon-text {
  font-size: 10px;
  padding-left: 2px;
}

@media (min-width: 768px) {
  .task-card {
    flex-basis: calc(50% - 10px);
  }
}

@media (max-width: 768px) {
  .task-card {
    flex-basis: 100%;
  }
}

.modal-header {
  border: none;
}

.closeImg {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

/* Responsive Modal Width */
.custom-modal {
  width: 120%;
}

@media (max-width: 1200px) {
  .custom-modal {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .custom-modal {
    width: 90%;
  }
}

@media (max-width: 480px) {
  .custom-modal {
    width: 85%;
  }
}

/* Slide-in Transition */
.custom-modal .modal-dialog {
  position: fixed;
  top: 0;
  right: -50%;
  /* Start off-screen */
  height: 100%;
  transition: right 0.5s ease-in-out;
}

.custom-modal.show .modal-dialog {
  left: 1%;
  /* Slide in when modal is shown */
}

.custom-modal .modal-content {
  margin-top: 7%;
  height: 89.7%;
  overflow-y: auto;
  /* Enable scrolling */
  padding-left: 10px;
  padding-right: 30px;
  max-height: 90%;
  /* Prevent modal from taking up too much vertical space */
  word-wrap: break-word;
  /* Handle long text properly */
  width: 125%;
}

/* Custom Scrollbar Styling */
.custom-modal .modal-content {
  scrollbar-width: thin;
  scrollbar-color: none;
}

.custom-modal .modal-content::-webkit-scrollbar {
  width: 6px;
}

.custom-modal .modal-content::-webkit-scrollbar-thumb {
  background-color: #e83e8c;
  border-radius: 10px;
}

/* Form Layout Improvements */
.modal-body form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.modal-body form .d-flex {
  display: flex;
  align-items: center;
}

.modal-body input {
  width: 150%;
}

.modal-body select {
  width: 100%;
}

@media (max-width: 768px) {
  .modal-body form .d-flex {
    flex-direction: column;
    align-items: flex-start;
  }
}

/* Button Styling */
button.btn-primary {
  background-color: #007bff;
  border: none;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button.btn-primary:hover {
  background-color: #0056b3;
}

button:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Subtle Backdrop */
.modal-backdrop.show {
  opacity: 0.5;
  background-color: rgba(0, 0, 0, 0.5);
  display: block;
}
</style>
