<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="UpdatePIModal"
      tabindex="-1"
      aria-labelledby="UpdatePIModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header text-center w-100">
            <h5 class="modal-title w-100" id="UpdatePIModalLabel">
              Update Purchase Invoice
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="UpdatePurchaseInvoice">
              <!-- Project Name -->
              <div class="mb-3 row">
                <label for="reference" class="col-sm-4 col-form-label">
                  Project Name:
                </label>
                <div class="col-sm-8">
                  <div style="max-height: 150px !important; overflow-y: auto">
                    <select
                      class="form-select"
                      id="projectSelect"
                      v-model="form.projectId"
                      @change="fetchpurchaseOrder"
                    >
                      <option
                        v-for="Project in Projects"
                        :key="Project.projectId"
                        :value="Project.projectId"
                      >
                        {{ Project.projectName }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <!-- PO Number -->
              <div class="mb-3 row">
                <label for="reference" class="col-sm-4 col-form-label"> PO Number </label>
                <div class="col-sm-8">
                  <div style="max-height: 150px !important; overflow-y: auto">
                    <select
                      class="form-select no-border"
                      id="poSelect"
                      v-model="form.poId"
                    >
                      <option
                        v-for="PurchaseOrder in PurchaseOrders"
                        :key="PurchaseOrder.id"
                        :value="PurchaseOrder.id"
                      >
                      {{ PurchaseOrder.poNumber }}  - ( {{ this.$formatToIndianCurrency(PurchaseOrder.totalAmount) }})
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <!-- Invoice Date -->
              <div class="mb-3 row">
                <label for="documentNo" class="col-sm-4 col-form-label">
                  Invoice Date
                </label>
                <div class="col-sm-8">
                  <input
                    type="date"
                    class="form-control"
                    id="invoiceDate"
                    v-model="form.date"
                  />
                </div>
              </div>

              <!-- Document Number -->
              <div class="mb-3 row">
                <label for="documentNo" class="col-sm-4 col-form-label">
                  Document No.
                </label>
                <div class="col-sm-8">
                  <input
                    type="text"
                    class="form-control"
                    id="documentNo"
                    placeholder="Document No."
                    v-model="form.documentNumber"
                  />
                </div>
              </div>

              <!-- Invoice Amount -->
              <div class="mb-3 row">
                <label for="amount" class="col-sm-4 col-form-label">
                  Invoice Amount
                </label>
                <div class="col-sm-8">
                  <input
                    type="text"
                    class="form-control"
                    id="invoiceAmount"
                    placeholder="Amount"
                    v-model="form.amount"
                  />
                </div>
              </div>

              <!-- Upload File -->
              <div class="mb-3 row">
                <label for="uploadFile" class="col-sm-4 col-form-label">
                  Upload File
                </label>
                <div class="col-sm-8">
                  <input
                    type="file"
                    ref="onSitePurchaseInvoice"
                    class="form-control inputBg"
                    id="onSitePurchaseInvoice"
                    @change="handleFileUpload"
                  />
                  <!-- Show existing file if present -->
                  <div v-if="form.fileName" class="mt-2">
                    <strong
                      style="cursor: pointer; font-size: 13px"
                      @click="
                        $viewFile(
                          form['awsFiles.awsFileName'],
                          form['awsFiles.fileName'],
                          'purchaseInvoice',
                          'procurement'
                        )
                      "
                    >
                      {{ form.fileName }}
                    </strong>
                  </div>
                </div>
              </div>

              <!-- Save / Update Button -->
              <div class="modal-footer d-flex justify-content-center">
                <button type="submit" class="btn btn-success" data-bs-dismiss="modal">
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "UpdatePIModal",
  props: {
    SelectedPIId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      form: {
        projectId: null,
        id: null,
        poId: null,
        awsFileId: null,
        awsFiles: {}, // to store the file information
        documentNumber: "",
        amount: null,
        date: null,
      },
      PurchaseOrders: [],
      Projects: [],
    };
  },
  mounted() {
    this.fetchProjects();
  },
  watch: {
    SelectedPIId(newValue) {
      if (newValue !== null) {
        this.fetchProjects();
        this.fetchPIIdValue();
      }
    },
  },
  methods: {
    fetchProjects() {
      axios
        .get(`${this.$apiEndPoint}/purchaseRequest/getProjectToCreatePr/Procurement`)
        .then((response) => {
          this.Projects = response.data;
        })
        .catch(() => {});
    },
    async fetchPIIdValue() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/purchaseInvoice/getById/${this.SelectedPIId}`
        );
        const data = response.data;
        this.form = data;

        // Populate form fields
        this.form.projectId = data.projectId || null;
        this.form.id = data.id || null;
        this.form.poId = data.poId || null;
        this.form.documentNumber = data.documentNumber;
        this.form.amount = data.amount || null;
        this.form.date = data.date || null;
        this.form.awsFileId = data.awsFileId || null;

        this.form.fileName = data["awsFiles.fileName"];
        // Fetch purchase orders for the selected project
        if (this.form.projectId) {
          await this.fetchpurchaseOrder();
        }
      } catch (error) {
        this.$handleError(error);
      }
    },

    async fetchpurchaseOrder() {
      if (!this.form.projectId) return;

      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/purchaseOrder/getPoNumbers/${this.form.projectId}/Ordered`
        );
        this.PurchaseOrders = response.data;

        if (this.form.poId) {
          this.form.poId =
            this.PurchaseOrders.find((po) => po.id === this.form.poId)?.id || null;
        }
      } catch (error) {
        this.$handleError(error);
      }
    },

    async UpdatePurchaseInvoice() {
      try {
       await axios.put(
          `${this.$apiEndPoint}/purchaseInvoice/${this.SelectedPIId}`,
          this.form
        );
        this.$emit("fetchpurchaseInvoice");
        this.$toast("Purchase Invoice updated successfully", "success"); 
      } catch (error) {
        this.$handleError(error);
      }
    },

    handleFileUpload() {
      const file = this.$refs.onSitePurchaseInvoice.files[0];
      if (file) {
        axios
          .post(`${this.$apiEndPoint}/company/putFileSignedURL`, {
            fileName: file.name,
            fileSize: file.size,
            contentType: file.type,
            fileCategory: "purchaseInvoice",
            moduleName: "procurement",
          })
          .then((response) => {
            const signedUrl = response.data.s3URL;
            this.form.awsFileId = response.data.id; // Update awsFileId
            this.form.fileName = file.name; // Update fileName
            const xhr = new XMLHttpRequest();
            xhr.open("PUT", signedUrl, true);
            xhr.setRequestHeader("Content-Type", file.type);

            xhr.onreadystatechange = () => {
              if (xhr.readyState === 4 && xhr.status === 200) {
                this.$toast("File uploaded successfully", "success"); 
              } else if (xhr.readyState === 4) {
                this.$handleError("Error uploading file to S3:", xhr.responseText);  
              }
            };
            xhr.send(file);
          })
          .catch((error) => {
            this.$handleError(error);
          });
      }
    },
  },
};
</script>

<style scoped>
input:focus,
textarea:focus,
select:focus {
  outline: none;
  /* Remove default outline */
  box-shadow: none;
  /* Add a custom focus shadow */
}
label,
input,
select {
  font-size: 13px;
}

.employee-dropdown {
  position: absolute;
  display: block;
  background-color: #fff;
  border: 1px solid #ccc;
  max-height: 200px;
  border-radius: 5px;
  overflow-y: auto;
  z-index: 1000;
  margin-left: 28%;
  /* Ensure the dropdown appears above other elements */
  width: calc(100% - 28%); /* Adjust width to match the input */
  color: #000000;
  top: 20%; /* Position the dropdown below the input */
  left: 0; /* Align the left edge of the dropdown with the left edge of the input */
}

.employee-dropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.employee-dropdown ul li {
  padding: 8px 10px;
  cursor: pointer;
}

.employee-dropdown ul li:hover {
  background-color: #f0f0f0;
}
</style>
